<template>
  <div class="flex">
    <slot></slot>
  </div>
</template>

<script>
export default {
  name: 'Tab',
  setup () {
    return {
      
    };
  }
}
</script>

<style scoped>
  .flex{
    position: fixed;
    bottom: 0;
    left: 0;
    right: 0;
    box-shadow: 0 -2px 2px rgba(0,0,0,.04);
    background:#fff;
    z-index: 10;
  }
  .flex::before{
    content:'';
    position:absolute;
    top:-26px;
    left:calc(50% - 30px);
    width:60px;
    height:60px;
    border-radius:50%;
    background: #fff;
    filter:drop-shadow(0 -2px 2px rgba(0,0,0,.08));
    z-index: -1;
  }
</style>