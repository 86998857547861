<!-- 外来清单 -->
<template>
  <div class="bg">
    <Head>
      <template v-slot:left><div>
        <i class="in_block icon-xiangxia trans90" @click="go(-1)"></i>
        外来清单
      </div></template>
    </Head>
    <div class="progress flex">
      <span class="f14">进度</span>
      <van-progress :percentage="percentage" stroke-width="12" track-color="#F5F6FA" 
      color="linear-gradient(90deg, #3061F2 1%, #AAA0FA 100%)" pivot-text="√" />
      <span><span class="red">{{percentage}}</span>/100</span>
    </div>
    <div>
      <div class="flex-b-c mb-10">
        <div class="search">
          <van-field v-model="form.value2" clearable placeholder="请输入标题关键字" />
          <i class="icon-icon-"></i>
        </div>
        <div class="filter">
          <i class="icon-filter" @click="form.showPicker1 = true"></i>
          <van-popup v-model:show="form.showPicker1" round position="bottom">
            <van-cascader v-model="form.cascaderValue" :options="form.options" active-color="#3061F2"
                @close="form.showPicker1 = false" @finish="onFinish"/>
          </van-popup>
        </div>
      </div>
      <Point :message="pointMsg"></Point>
    </div>
    <ul>
      <li class="bg_fff" :class="item.picked?'on':''" v-for="(item, index) in list" :key="index">
        <div class="tit line2">{{item.tit}}</div>
        <div class="flex-b-c mt-10">
          <div class="num">{{item.num}}</div>
          <div v-if="!item.picked" class="addbtn" @click="checkThis(index)">
            <i class="icon-tianjia"></i>
          </div>
          <div v-else class="addbtn" @click="checkThis(index)">
            <i class="icon-jianqu"></i>
          </div>
        </div>
      </li>
    </ul>
    <div class="fixed flex-b-c">
      <van-badge v-if="content" :content="content" @click="toPickedList">
        <i class="icon-shu"></i>
      </van-badge>
      <i v-else class="icon-shu" @click="toPickedList"></i>
      <span>生成清单</span>
    </div>
  </div>
</template>

<script>
import { ref, onMounted, getCurrentInstance} from 'vue'
import { useRouter } from 'vue-router'
import { useStore } from 'vuex'
import Head from 'components/common/Head'
import Point from 'components/content/home/Point'

export default {
  name: 'ExternalList1',
  components:{
    Head,
    Point
  },
  setup(){
    const router = useRouter()
    const store = useStore()
    const {proxy} = getCurrentInstance()

    let pointMsg = ref('注：企业名称简写英文字母；如：河北证优科技有限公司，简称为ZYKJ。')

    let percentage = ref(30)
    let list = store.state.list
    let newPickedList = ref([])  //已经选择的组成的新列表
    let form = ref({
      value2: '',
      options:[],
      cascaderValue:'',
      showPicker1:false,
    })
    let content = ref(0)  //选择的个数
    
    onMounted(()=>{
      getPor()
      for(var i in list){
        if(list[i].picked){
          content.value += 1
          newPickedList.value.push(list[i])
        }
      }
    })
    function getPor(){
      proxy.$get("/external.json",{}).then((res)=>{
        // list.value = res.list
        form.value.options = res.shaixuan
      })
    }
    // 返回上一步
    function go(n){
      router.go(n)
    }
    // 筛选完成
    const onFinish = ({ selectedOptions }) => {
      form.value.showPicker1 = false;
      form.value.cascaderValue = selectedOptions.map((option) => option.text).join('/');
    }
    // 列表中选择某一项或者某几项
    function checkThis(index){
      if(list[index].picked == true){
        return
      } else {
        list[index].picked = true
        newPickedList.value.push(list[index])
        content.value++
      }
      console.log(newPickedList.value)
      store.commit('changeList', newPickedList.value)
      console.log(content.value)
    }
    // 跳转到已选择的列表页
    function toPickedList(){
      router.push('/home/externalList/externalPickedList')
    }
    return{
      pointMsg, percentage, list, newPickedList, form, content, 
      go, onFinish, checkThis, toPickedList, 
    }
  }
}
</script>


<style scoped>
  .bg{
    background: #fbfbfb;
  }
  
  :deep(.van-progress__pivot){
    height:12px;
    min-width: 12px;
    background:#fff !important;
    color:var(--color-text-blue);
    line-height:12px;
    box-shadow:1px 0 0px rgb(170, 160, 250);
  }
  .progress{
    padding: 0 50px;
    height: 40px;
    line-height: 40px;
    align-items: center;
    background:#fff;
    box-shadow: 0px 3px 4px 0px rgba(1,27,70,0.03);
    margin-top:44px;
  }
  .progress>span{
    width: 40px;
  }
  .progress>div{
    width: calc(100% - 40px);
  }
  .progress+div{
    margin:12px;
  }
  .search{
    width:calc(100% - 50px);
    position: relative;
  }
  input{
    background:#fff;
    border:1px solid var(--color-text-blue);
    border-radius:20px;
    height:30px;
    line-height: 30px;
    padding-left:15px;
    padding-right:50px;
    width: 100%;
    box-sizing: border-box;
  }
  :deep(.van-cell){
    line-height: 30px;
    padding:0 56px 0 15px;
    border: 1px solid var(--color-text-blue);
    border-radius: 20px;
  }
  .icon-icon-{
    font-size: 24px;
    position: absolute;
    right: 3px;
    top: 3px;
    width: 45px;
    height: 26px;
    background: blue;
    color: #fff;
    line-height: 26px;
    border-radius: 20px;
    text-align: center;
  }
  ul{
    margin-bottom:60px;
  }
  .bg_fff{
    margin: 12px;
    border-radius: 10px;
    padding: 12px;
    box-shadow: 0px 3px 4px 0px rgba(1,27,70,0.03);
  }
  .num{
    font-size:12px;
    color:#999;
  }
  .addbtn{
    background:var(--color-text-blue);
    color:#fff;
    border-radius:20px;
    padding:0 15px;
    height:28px;
    line-height: 28px;
    box-shadow: 0px 2px 10px 1px rgba(48,97,242,0.5);
  }
  .on .addbtn{
    background: #fff;
    color:var(--color-text-blue);

  }
  .fixed{
    background: #fff;
    height: 50px;
    padding-left:20px;
    padding-right:12px;
    bottom: 0;
    left: 0;
    right: 0;
    box-shadow: 0px -3px 4px 0px rgba(1,27,70,0.03);
  }
  .icon-shu{
    font-size: 20px;
  }
  .fixed span{
    background: var(--color-text-blue);
    color:#fff;
    height:40px;
    line-height: 40px;
    text-align: center;
    border-radius:20px;
    width:60%;
    margin-left:20%;
    bottom:20px; 
    z-index: 5;
  }
</style>