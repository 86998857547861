<!-- 多级联动 -->
<template>
  <div class="fill">
    <van-field readonly clickable v-model="result" placeholder="点击选择" @click="showPopup = true"/>
    <van-popup v-model:show="showPopup" round position="bottom">
      <van-cascader :options="columns" @finish="onFinish" @close="showPopup = false"/>
      <!--  :columns-field-names="customFieldName" -->
    </van-popup>
  </div>
</template>

<script>
import { ref, reactive, getCurrentInstance, 
  // watch 
} from 'vue'
// import { useRoute,useRouter } from 'vue-router'

export default {
  name: 'Area',
  props:{
    type:{
      type:String,
      default:'areas'
    },
    length:{
      type:Number,
      default:3
    },
    first_id:{
      type:Number,
      default:0
    },
    second_id:{
      type:Number,
      default:0
    },
    third_id:{
      type:Number,
      default:0
    },
    fourth_id:{
      type:Number,
      default:0
    },
    flag:{
      type:String,
      default:'normal'
    }
  },
  setup(props, context){
    // const {proxy} = getCurrentInstance()
    let axios = getCurrentInstance().appContext.config.globalProperties;
    let type = props.type;
    let flag = props.flag;
    let length = parseInt(props.length)
    let result = ref('')  //展示的值

    let showPopup = ref(false)
    let columns = ref([])  //选择时候的数组，接口取到的值需要调整，最后一项应该是没有children这个key
    let areaData = reactive({
      first_id:0,
      second_id:0,
      third_id:0,
      fourth_id:0
    })
   
    function initAreas(level=0){
      if(level>=length){
        context.emit('changeEvent', [areaData.first_id, areaData.second_id, areaData.third_id, areaData.fourth_id], flag)
        return false
      }
      let id=0
      let pid=0
      if(level==1){
        id = areaData.first_id
        pid = 0
      }else if(level==2){
        id=areaData.second_id
        pid=areaData.first_id
      }else if(level==3){
        id = areaData.third_id;
        pid = areaData.second_id;
      }else if(level==4){
        id = areaData.fourth_id;
        pid = areaData.third_id;
      }
      let new_arr = new Array()
      
      axios.$post('index/get_select', {
        type:type,
        dataType:'tree',
        id:id,
        pid:pid,
      }).then(function (response) {
        console.log(response)
        response.forEach(n=>{
          n.text = n.name
          n.value = n.id
          if(length==1){
            delete n['children']
          }else{
            n.children.forEach(m=>{
              m.text = m.name
              m.value = m.id
              if(length==2){
                delete m['children']
              }else{
                m.children.forEach(x=>{
                  x.text = x.name
                  x.value = x.id
                  if(length==3){
                    delete x['children']
                  }else{
                    x.children.forEach(y=>{
                      y.text = y.name
                      y.value = y.id
                      delete y['children']
                    })
                  }
                })
              }
            })
          }
          new_arr.push(n)
        })
        columns.value = new_arr

        // 拿到columns的数组以后，找出province_id等等对应的汉字text，然后拼接起来，作为初始值，进行展示
        var item1 = new_arr.filter(x => x.id==parseInt(props.first_id) )[0]
        result.value += item1.text
        if(length>1){
          var item2 = item1.children.filter(x => x.id==parseInt(props.second_id))[0]
          result.value += '/' + item2.text
          if(length>2){
            var item3 = item2.children.filter(x => x.id==parseInt(props.third_id))[0]
            result.value += '/' + item3.text
            if(length>3){
              var item4 = item3.children.filter(x => x.id==parseInt(props.fourth_id))[0]
              result.value += '/' + item4.text
            }
          }
        }

      }).catch(function (error) {
        console.log(error);
      });
    }
    initAreas();
    const onFinish = ({ selectedOptions }) => {
      result.value = selectedOptions.map((option) => option.text).join('/')
      // proxy.$forceUpdate()
      // 要把改变后的值的每一项对应的id，拿出来，传递给父组件
      areaData.first_id = selectedOptions[0].id
      if(length>1){
        areaData.second_id = selectedOptions[1].id
      }
      if(length>2){
        areaData.third_id = selectedOptions[2].id
      }
      if(length>3){
        areaData.fourth_id = selectedOptions[3].id
      }
      showPopup.value = false
      context.emit("changeEvent", [areaData.first_id, areaData.second_id, areaData.third_id, areaData.fourth_id],flag)
    }
    // watch(()=>new_arr,(newV,oldV)=>{
    //   debugger
    //   if(newV!==oldV){
    //     new_arr=newV
    //     console.log(new_arr)
    //   }
    // })
    
    return {
      result,
      showPopup, 
      columns, 
      areaData,
      initAreas, 
      onFinish,
    }
  }
}
</script>

<style scoped>
  .fill :deep(.van-cell){
    padding:0;
    border:none;
    font-size:16px;
  }
  .fill :deep(.van-cell:after){
    border:none;
  }
  :deep(.van-field__control){
    background: transparent;
    margin-top:10px;
    padding:0;
    /* border-bottom: 1px solid #EBEDF5; */
    font-size: 16px;
    line-height: 2;
    box-sizing: border-box;
  }
</style>