<template>
  <div class="popup" v-show="isShow" @click="closePopup">
    <div class="mask">
      <template v-if="src">
        <img :src="src" alt=""/>
      </template>
      <template v-else><slot name="content"></slot></template>
    </div>
  </div>
</template>

<script>
export default {
  name: 'Popup',
  props: {
    isShow:{
      type:Boolean
    },
    src:{
      type:String
    }
  },
  setup(props, context){
    function closePopup(){
      context.emit('closePopup', false)
    }

    return {
      closePopup, 
    }
  }
}
</script>

<style scoped>
  .popup{
    position:fixed;
    top: 0;
    left: 0;
    width: 100%;
    height:100%;
    background:rgba(0,0,0,.8);
    z-index:100;
  }
  .mask{
    display: flex;
    justify-content: center;
    align-items: center;
    overflow: hidden;
    width: 100%;
    height: 100%;
  }
  img{
    width: 100%;
  }
</style>
