<template>
  <div class="bg">
    <Head>
      <template v-slot:left><div>
        <i class="in_block icon-xiangxia trans90" @click="go(-1)"></i> 
        添加企业
      </div></template>
    </Head>
    <form action="">
      <div class="red f14">注：请慎重填写，保存后不可更改！</div>
      <div class="bg_fff">
        <div>
          <div class="f14 color_9">项目名称</div>
          <div>
            <input type="text" v-model="name" placeholder="请输入企业名称" />
          </div>
        </div>
        <div>
          <div class="f14 color_9">认证机构</div>
          <div class="f14 red">此处将影响多场所模版的内容，请确定认证机构后再进行选择，选择后将无法更改。</div>
          <div class="border">
            <Linkage :first_id="org_id" type="Organization" :length="1" @changeEvent="changeOrganization"></Linkage>
          </div>
        </div>
        <div>
          <div class="f14 color_9">认证项目</div>
          <div class="border">
            <Linkage :first_id="category_id" :second_id="cer_id" type="certificate_category" :length="2" @changeEvent="changeCer"></Linkage>
          </div>
        </div>
        <div>
          <div class="f14 color_9">认证类型</div>
          <div class="f14 red">此处将影响手册模版的内容，请确定认证范围后再进行选择，选择后将无法更改。</div>
          <div class="border">
            <!-- <van-field v-model="industry_id" is-link readonly placeholder="点击选择认证类型" @click="show = true" />
            <van-popup v-model:show="show" round position="bottom">
              <van-picker :columns="form.businessType" @confirm="changeIndustry" @cancel="show = false"/>
            </van-popup> -->
            <Linkage :first_id="industry_id" type="industry" :length="1" @changeEvent="changeIndustry"></Linkage>
          </div>
        </div>
      </div>
      <div class="text_center fixed" @click="finishAdd">完成</div>
    </form>
  </div>
</template>

<script>
import { ref, reactive, toRefs, getCurrentInstance } from 'vue'
import { useRouter } from 'vue-router'
import Head from 'components/common/Head.vue'
import Linkage from 'components/common/Linkage.vue'
import { Toast } from 'vant'

export default {
  name: 'Add',
  components:{
    Head,
    Linkage,
  },
  setup () {
    const {proxy} = getCurrentInstance()
    const router = useRouter()

    let defaultData = reactive({
      list:[],
    })
    let name = ref('')
    // 认证机构
    let org_id = ref(0);
    function changeOrganization(data){// eslint-disable-line no-unused-vars
      org_id.value = data[0]||0
    }
    // 认证项目
    let category_id = ref(0)
    let cer_id = ref(0)
    function changeCer(data){
      category_id = data[0]||0
      cer_id = data[1]||0
    }
    // 认证类型
    let industry_id = ref(0)
    function changeIndustry(data){
      industry_id = data[0]||0
    }
    // 完成，提交
    function finishAdd(){
      var project_name = name.value
      if(!project_name){
        Toast({message:'请输入项目名称'})
        return false
      }
      if( !cer_id ){
        Toast({message:'请选择认证项目'})
        return false
      }
      if(!industry_id){
        Toast({message:'请选择认证类型'})
        return false
      }
      proxy.$post('/project/add', {
        project_name: project_name,
        cer_id: cer_id,
        industry_id: industry_id,
      }).then(res=>{
        console.log(res)
        if(res.code==200||res.code==0){
          Toast.success(res.msg)
          getProjectList();
          router.push('/change')
        }else{
          Toast.fail(res.msg)
        }
      }).catch(err=>{
        console.log(err)
      })
    }
    // 后退
    function go(n){
      router.go(n)
    }
    function getProjectList(){
      proxy.$post('project/list',{

      }).then(res=>{
        console.log(res)
        defaultData.list = res.data
      }).catch(err=>{
        console.log(err)
      })
    }
    
    return {
      ...toRefs(defaultData),
      name, 
      org_id, 
      changeOrganization, 
      category_id, 
      cer_id, 
      industry_id, 
      changeCer, 
      changeIndustry, 
      finishAdd, 
      go, 
      getProjectList, 
    }
  }
}
</script>

<style scoped>
  .bg{
    background:#fbfbfb;
  }
  form{
    margin-left:12px;
    margin-right:12px;
  }
  form>div{
    margin-bottom:12px;
  }
  .bg_fff{
    border-radius: 10px;
    padding: 12px;
    box-shadow: 0px 3px 4px 0px rgba(1,27,70,0.03);
  }
  .bg_fff>div+div{
    margin-top: 20px;
  }
  .border{
    border-bottom:1px solid #ebedf5;
  }
  input{
    border:none;
    border-bottom: 1px solid #EBEDF5;
    width: 100%;
    font-size:16px;
    line-height: 2; 
    margin-top:10px;
  }
  .fixed{
    margin-left:calc(20% - 12px);
  }
</style>