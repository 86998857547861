<template>
  <div>
    <Head>
      <template #left><div>
        <i class="in_block icon-xiangxia trans90" @click="go(-1)"></i>
        供方信息
      </div></template>
    </Head>
    <form class="" action="">
      <div>
        <div class="f14 color_9">供方名称</div>
        <div class="border">
          <input type="text" v-model="supplier_form.supplier_name" placeholder="请输入供方名称"/>
        </div>
      </div>
      <!-- <div>
        <div class="f14 color_9">产品名称</div>
        <div class="border">
          <input type="text" v-model="supplier_form.product_name" placeholder="请输入产品名称"/>
        </div>
      </div> -->
      <div>
        <div class="f14 color_9">产品信息</div>
        <div class="">
          <!-- <template v-if="supplier_form.product_list.length"> -->
            <div class="citem" v-for="(ci,cIndex) in supplier_form.product_list" :key="cIndex">
            <div class="flex">
              <span class="f14 color_9">产品名称</span>
              <input type="text" v-model="ci.product_name" placeholder="请输入产品名称"/>
            </div>
            <div class="flex">
              <span class="f14 color_9">规格型号</span>
              <input type="text" v-model="ci.product_model" placeholder="请输入规格型号"/>
            </div>
            <div class="flex">
              <span class="f14 color_9">数量</span>
              <input type="text" v-model="ci.product_num" placeholder="请输入数量"/>
            </div>
            <div class="flex">
              <span class="f14 color_9">操作</span>
              <div class="">
                <i class="red icon-jianqu" @click="deleTr(cIndex)"></i>
                <i class="blue icon-tianjia" @click="addTr"></i>
              </div>
            </div>
          </div>
          <!-- </template> -->
          <!-- <div v-else class="citem">
            <div class="flex">
              <span class="f14 color_9">产品名称</span>
              <input type="text" v-model="ci.product_name" placeholder="请输入产品名称">
            </div>
            <div class="flex">
              <span class="f14 color_9">规格型号</span>
              <input type="text" v-model="ci.product_model" placeholder="请输入规格型号">
            </div>
            <div class="flex">
              <span class="f14 color_9">数量</span>
              <input type="text" v-model="ci.product_num" placeholder="请输入数量">
            </div>
            <div class="flex">
              <span class="f14 color_9">操作</span>
              <div class=""><i class="red icon-jianqu"></i><i class="blue icon-tianjia"></i></div>
            </div>
          </div> -->
        </div>
      </div>
      <div>
        <div class="f14 color_9">供货日期</div>
        <div class="border">
          <van-field readonly v-model="supplier_form.supplier_date" is-link placeholder="供货日期" @click="showDate = true"/>
          <van-popup v-model:show="showDate" round position="bottom">
            <van-datetime-picker type="date" v-model="currentDate" :min-date="minDate" :max-date="maxDate" @confirm="chooseDate" @cancel="showDate = false" />
          </van-popup>
        </div>
      </div>
      <div>
        <div class="f14 color_9">负责人</div>
        <div class="border">
          <input type="text" v-model="supplier_form.supplier_manager" placeholder="负责人"/>
        </div>
      </div>
      <div>
        <div class="f14 color_9">公司地址</div>
        <div class="border">
          <input type="text" v-model="supplier_form.supplier_address" placeholder="公司地址"/>
        </div>
      </div>
      <div>
        <div class="f14 color_9">营业执照</div>
        <div class="border">
          <van-uploader class="mt-10" v-model="fileList" :before-read="beforeRead" :after-read="afterRead" name="1"
            accept="application/msword,application/vnd.openxmlformats-officedocument.wordprocessingml.document,application/pdf,image/jpeg,image/png">
            <span class="btn">上传</span>
          </van-uploader>
          <input type="text" v-model="supplier_form.supplier_license" placeholder="营业执照" readonly/>
        </div>
      </div>
      <!-- <div>
        <div class="f14 color_9">检测报告</div>
        <div class="border">
          <van-uploader class="mt-10" v-model="fileList2" :before-read="beforeRead" :after-read="afterRead" name="2"
            accept="application/msword,application/vnd.openxmlformats-officedocument.wordprocessingml.document,application/pdf,image/jpeg,image/png">
            <span class="btn">上传</span>
          </van-uploader>
          <input type="text" v-model="supplier_form.product_report" placeholder="检测报告" readonly/>
        </div>
      </div> -->
      <div class="fixed text_center" @click="saveSupplier">保存</div>
    </form>
  </div>
</template>

<script>
import {ref, reactive, toRefs, getCurrentInstance, onMounted} from 'vue'
import { useRouter } from 'vue-router'
import { Dialog, Toast } from 'vant'
import store from '@/store/index.js'
import axios from 'axios'
import Head from 'components/common/Head.vue'

export default {
  name: 'SupplierDetail', 
  components: {
    Head,
  },
  setup(props, context){
    const {proxy} = getCurrentInstance()
    const Api = proxy.$commonApi
    const router = useRouter()

    var project_id = Api.sessionGet('project_id')
    const _this = reactive({
      project_id: project_id,
      supplier_list: [],
    })
    const supplier_form = ref({})
    let product_list = ref([])
    let currentDate = new Date()
    let showDate = ref(false)
    let upload_type = ref(0)  //等着放upload中name给的值，因为有两个upload
    
    const getHeader = ()=>{
      let headers = Api.getHeader();
      let header = {}
      header.USERKEY = headers.userKey;
      header.USERTOKEN = headers.token;
      return header
    }
    onMounted(()=>{
      supplier_form.value = store.state.item
      delete supplier_form.value.show
      product_list.value = supplier_form.value.product_list
      console.log(supplier_form.value)
      getSupplierList()
    })
    //获取供方信息
    const getSupplierList = ()=>{
      proxy.$post('/supplier/index').then(res=>{
        console.log(res)
        if (res.code == 0) {
          _this.supplier_list = res.data.data
        }else {
          Api.msg(res.msg);
        }
      })
    }
    // 删除huo添加 产品信息
    function deleTr(n){
      if(n){
        Dialog.confirm({message:'是否删除此产品信息？'})
        .then(()=>{
          supplier_form.value.product_list.splice(n,1)
        })
        .catch(()=>{return false})
      }else{
        return false
      }
    }
    function addTr(){
      supplier_form.value.product_list.push({product_num:0})
    }
    // 选择供货日期
    const chooseDate = (val) => {
      supplier_form.value.supplier_date = `${val.getFullYear()}-${val.getMonth() + 1}-${val.getDate()}`
      showDate.value = false;
      console.log(supplier_form.value)
    }
    // 文件上传前检验格式和大小
    const beforeRead = (file)=>{
      console.log(file)
      const allowType = 'msword,vnd.openxmlformats-officedocument.wordprocessingml.document,pdf,png,jpeg'
      const type = file.type.split("/")[1]
      // 上传的只能是 doc,docx,pdf,jpeg,jpg,png 格式!
      if(allowType.indexOf(type) == -1){
        Toast.fail('文件格式错误！');
        return false;
      }else if(file.size > 2 * 1024 * 1024){
        Toast.fail('上传文件大小不能超过 2MB!');
        return false;
      }else{
        return file;
      }
    }
    //文件上传（没有传到服务器）
    const afterRead = (file, name)=>{
      upload_type.value = name.name
      file.status = 'uploading'
      file.message = '上传中...'
      submitImgs(file)
    }
    //提交文件到服务器
    function submitImgs(file){
      console.log(file)
      let formData = new FormData()
      formData.append('file', file.file)
      if(upload_type.value==1){
        formData.append('upload_type', 'supplier_license')
        formData.append('supplier_license', supplier_form.supplier_license)
      }
      if(upload_type.value==2){
        formData.append('upload_type', 'product_report')
        formData.append('product_report', supplier_form.product_report)
      }
      axios.post('/customer/importSaleContract', formData).then(res=>{
        console.log(res)
        if(res.data.code==0){
          file.status = 'done'
          file.message = '上传成功'
          if(upload_type.value==1){ supplier_form.value.supplier_license = res.data.url } 
          if(upload_type.value==2){ supplier_form.value.product_report = res.data.url }
          console.log(supplier_form.value)
        }else{
          Api.msg(res.data.msg)
        }
      })
    }
    //保存顾客信息并返回上一步更新
    const saveSupplier = () => {
      let name = supplier_form.value.supplier_name
      let list = supplier_form.value.product_list
      let date = supplier_form.value.supplier_date
      let manager = supplier_form.value.supplier_manager
      let address = supplier_form.value.supplier_address
      let license = supplier_form.value.supplier_license
      if(!name){ Toast.fail('供方名称不能为空！');return false; }
      if(!date){ Toast.fail('供货日期不能为空！');return false; }
      if(!manager){ Toast.fail('负责人不能为空！');return false; }
      if(!address){ Toast.fail('供方地址不能为空！');return false; }
      if(!license){ Toast.fail('营业执照不能为空！');return false; }
      // if(list.length==0){ Toast.fail('产品信息不能为空！');return false;}
      for(let i=0;i<list.length;i++){
        if(!list[i].product_name){ Toast.fail('产品名称不能为空！');return false; }
        if(!list[i].product_model){ Toast.fail('规格型号不能为空！');return false; }
        if(!list[i].product_num){ Toast.fail('数量不能为空！');return false; }
      }
      proxy.$post('/supplier/saveSupplier', {
        supplier_form: supplier_form.value,
        product_list: product_list.value,
      }).then(res => {
        console.log(res)
        Api.msg(res.msg, 0, '', function () {
          if (res.code == 0) {
            go(-1)
            getSupplierList();
            resetPercent();
          }
        })
      })
    }
    //更新进度
    const resetPercent = () => {
      let data = {}
      data.template_id = -9;
      context.emit("updatePercent", data)
    }
    function go(n){
      router.go(n)
    }
    return {
      ...toRefs(_this), 
      supplier_form, 
      minDate: new Date(2000, 0, 1),
      maxDate: new Date(2025, 5, 1),
      currentDate,
      showDate, 
      upload_type, 
      getHeader, 
      deleTr, 
      addTr,
      chooseDate, 
      beforeRead, 
      afterRead, 
      saveSupplier, 
      resetPercent, 
      go,
    };
  }
}
</script>

<style scoped>
  form{
    margin-left:12px;
    margin-right:12px; 
  }
  form>div+div{
    margin-top: 20px;
  }
  .border{
    border-bottom: 1px solid #EBEDF5;
  }
  input{
    border:none;
    /* border-bottom: 1px solid #EBEDF5; */
    width: 100%;
    font-size:16px;
    line-height: 2; 
    margin-top:10px;
  }
  :deep(.van-cell){
    padding-left:0;
    padding-right:0;
    font-size: 16px;
  }
  :deep(.van-uploader__preview){
    display: none;
  }
  .citem{
    background:#f3f4fa;
    padding: 10px;
    margin-top:10px;
  }
  .flex+.flex{
    margin-top:8px;
  }
  .flex .f14{
    width:5em;
  }
  .flex .f14+*{
    width:calc(100% - 5em);
    font-size: 14px;
  }
  .citem input{
    margin:0;
    padding:0 10px;
    height: 2em;
  }
  .citem i{
    padding:2px;
    border:1px solid;
    border-color: initial;
    border-radius:50%;
    margin-right:20px;
  }
</style>
