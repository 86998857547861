<template>
  <div class="item text_center">
    <div class="in_block pr">
      <!-- <el-progress :percentage="value1" :color="customColor"></el-progress> -->
      <van-circle v-model:current-rate="currentRate_c" :speed="100" 
        :stroke-width="60" :color="gradientColor" layer-color="#F5F6FA" 
        size="60px" start-position="bottom"/>
      <div class="text">
        <div class="DIN f28 blue">{{text}}<span class="f12 color_9">%</span></div>
      </div>
    </div>
    <div class="f14"><slot name="tit"></slot></div>
    <!-- <div class="f14 red">预览</div> -->
  </div>
</template>

<script>
import { ref, computed } from 'vue'

export default {
  name: 'HomeItem',
  props:{
    currentRate:{
      type:Number
    },
  },
  setup (props) {
    const currentRate_c = parseInt(props.currentRate)
    const text = computed(() => currentRate_c.toFixed(0))
    const gradientColor = ref({
      '0%': '#3061F2',
      '100%': '#AAA0FA',
    })

    return {
      currentRate_c, text, gradientColor, 
    };
  }
}
</script>

<style scoped>
  .item{
    background: #fff;
    border-radius: 10px 40px 10px 10px;
    box-shadow: 0px 5px 19px 1px rgba(1,27,70,0.08);
    padding: 12px 15px;
    width:calc(33.33% - 8px);
    box-sizing: border-box;
  }
  .item:nth-child(3n){
    margin-right: 0;
  }
  .in_block{
    box-shadow:0px 0px 10px 1px rgba(1,27,70,0.08);
    border-radius:50%;
    padding:10px;
    width:80px;
    height:80px;
    box-sizing: border-box;
    margin:0 auto;
    display:flex;
    align-items: center;
    justify-content: center;
  }
  .text{
    position: absolute;
    /* top: 30%;
    right: 19px;
    line-height: 1; */
    letter-spacing: -0.15em; 
  }
  .DIN span{
    vertical-align:top;
  }
  .f14{
    color:#9d9fa6;
  }
  .normal .f14{
    color:#000;
  }
  .active .f14{
    color: #3061f2;
  }
  .red{
    background: rgba(254, 44, 85, .1);
    display: inline-block;
    padding: 0 14px;
    height: 20px;
    line-height: 20px;
    margin: 10px auto 0;
    border-radius:2px;
  }
</style>