<!-- 企业信息 -->
<template>
  <div class="bg">
    <Head>
      <template v-slot:left><div>
        <i class="in_block icon-xiangxia trans90" @click="go(-1)"></i>
        {{template_name}}
      </div></template>
    </Head>
    <!-- <div class="progress flex">
      <span class="f14">进度</span>
      <van-progress :percentage="percentage" stroke-width="12" track-color="#F5F6FA"
      color="linear-gradient(90deg, #3061F2 1%, #AAA0FA 100%)" pivot-text="√" />
      <span><span class="red">{{percentage}}</span>/100</span>
    </div> -->
    <form action="">
      <div class="prudence red f14"><i class="iconfont icon-zhuyi blue mr-10"></i>请谨慎填写，保存后不可更改!</div>
      <div v-for="(item,index) in list" :key="index" class="bg_fff">
        <!-- 文本框 textarea -->
        <div v-if="item.type==0">
          <div class="f16 color_9">{{item.title}}</div>
          <div class="f14 red mt-10" v-if="item.remark">注：{{item.remark}}</div>
          <textarea v-model="item.value" :placeholder="'请输入'+item.title"></textarea>
        </div>
        <!-- 输入框 input -->
        <div v-if="item.type==1">
          <div class="f16 color_9">{{item.title}}</div>
          <div class="f14 red mt-10" v-if="item.remark">注：{{item.remark}}</div>
          <input type="text" v-model="item.value" :placeholder="'请输入'+item.title"/>
        </div>
        <!-- 单选 -->
        <div v-if="item.type==2">
          <div class="f16 color_9">{{item.title}}</div>
          <div class="f14 red mt-10" v-if="item.remark">注：{{item.remark}}</div>
          <van-radio-group v-model="item.value" class="flex_wrap">
            <van-radio v-for="(it,k) in item.list" :key="k" :name="it.title">{{it.title}}</van-radio>
          </van-radio-group>
        </div>
        <!-- 复选 -->
        <div v-if="item.type==3">
          <div class="f16 color_9">{{item.title}}</div>
          <div class="f14 red mt-10" v-if="item.remark">注：{{item.remark}}</div>
          <van-checkbox-group v-model="item.options" class="flex_wrap">
            <van-checkbox v-for="(it,k) in item.list" :key="k" shape="square" :name="it.title">{{it.title}}</van-checkbox>
          </van-checkbox-group>
        </div>
        <!-- 文件上传 -->
        <div v-if="item.type==5">
          <div class="f16 color_9">{{item.title}}</div>
          <div class="f14 color_9 mt-10" v-if="item.remark">注：{{item.remark}}</div>
          <!-- <van-uploader v-model="fileList" multiple :max-size="5 * 1024 * 1024" @oversize="onOversize" :after-read="afterRead">
            <span>上传{{item.title}}</span>
            <template #preview-cover="{file}">
              <div class="preview-cover van-ellipsis">{{file.name}}</div>
              <input type="text" v-model="file.rename" class="preview-cover van-ellipsis" @click.stop/>
              <i class="icon-yixianshi-"></i>
            </template>
          </van-uploader> -->
          <!-- <van-swipe :autoplay="3000" indicator-color="#3061F2">
            <van-swipe-item v-for="(it,k) in item.list" :key="k" >
              <img :src="site+it.file" />
              <input type="text" v-model="it.title" placeholder="修改名称"/>
            </van-swipe-item>
          </van-swipe> -->
          <!-- <div class="flex_wrap">
            <div class="item" v-for="(it, k) in item.list" :key="k">
              <img v-if="suff(it)=='pdf'" 
                :src="site+'/static/index/img/pdf200.jpg'" 
                @click="previewImg(site+it.file)"/>
              <img v-else 
                :src="site+it.file" 
                @click="previewImg(site+it.file)" />
              <div class="flex">
                <input type="text" v-model="it.title" />
                <i class="icon-yixianshi-"></i>
              </div>
              <i class="icon-guanbi red pab" @click="deleteImg(index,k)"></i>
            </div>
            --分割线 前边的是item.list 后边的是fileList--
            <div class="item" v-for="(it, k) in fileList" :key="k">
              <img v-if="suff(it.file.name)=='pdf'" 
                :src="site+'/static/index/img/pdf200.jpg'" 
                @click="previewImg(it.content)"/>
              <img v-else 
                :src="it.content" 
                @click="previewImg(it.content)" />
              <div class="flex">
                <input type="text" v-model="it.file.newName" />
                <i class="icon-yixianshi-"></i>
              </div>
              <i class="icon-guanbi red pab" @click="deletePrevImg(k)"></i>
            </div>
          </div> -->
          <!-- <PictureItem :list="list" :forlist1="item.list" :forlist2="fileList" :index="index" 
            @changeValue="changeValue" @changelist2="changelist2"></PictureItem> -->
          <UploadImgs :list="list" :item="item" :index="index" @changeItemList="changeItemList" @changeValue="changeValue"></UploadImgs>
        </div>
        <!-- 时间选择 -->
        <div v-if="item.type==7">
          <div class="f16 color_9">{{item.title}}</div>
          <div class="f14 red mt-10" v-if="item.remark">注：{{item.remark}}</div>
          <!-- <input type="
            date" /> -->
          <!-- <van-cell :value="item.date" @click="dateShow = true" />
          <van-calendar v-model="dateShow" @confirm="onConfirm" /> -->
        </div>
      </div>
      <div class="text_center fixed" @click="complate">提交信息</div>
    </form>
    <!-- 营业执照图片放大 -->
    <Popup :isShow="isShow" :src="src" @closePopup="close"></Popup>
  </div>
</template>

<script>
import { ref, reactive, toRefs, watch, getCurrentInstance } from 'vue'
import { useRoute, useRouter } from 'vue-router'
import axios from 'axios'
import { Toast } from 'vant'
import Head from 'components/common/Head'
// import PictureItem from 'components/content/home/pictureItem'
import UploadImgs from 'components/content/home/uploadImgs'
import Popup from 'components/common/Popup'

export default {
  name: 'Information',
  components:{
    Head,
    // PictureItem,
    UploadImgs, 
    Popup,
  },
  setup(props, context){
    var  route = useRoute()
    var router = useRouter()
    const {proxy} = getCurrentInstance()
    const Api = proxy.$commonApi
    let suff = Api.get_file_suff

    let template_id = route.params.template_id || 0 
    let page = ref(1);
    let pageSize = ref(5);
    let totalPage = ref(0);
    let fileList = ref([])
    let isShow = ref(false) //是否显示图片放大
    let src = ref('')  //存放点击的那个图片的src
    let content = ref('')  //存放PDF的地址


    let headers = Api.getHeader();
    const defaultData = reactive({
      action:Api.UPLOAD_URL,
      site:Api.WEB_SITE,
      allList:[],
      list:[],
      percentage:0,
      headers:{
        USERKEY:headers.userKey,
        USERTOKEN:headers.token,
      },
      template_name:'模板信息'
    });
    //获取模板须填写项
    const getTagsList = ()=>{
      proxy.$post('/template/getFormInfo',{
        template_id:template_id,
        page:page.value,
      }).then((response)=>{
        console.log(response)
        defaultData.percentage = response.percent;
        var allList = response.list
        allList.forEach((v,k)=>{
          if(v.type==3){
            var options = []
            if(!(!v.value||v.value=='undefinde'||v.value==null||v.value=='null')){
              options = v.value.split(',')
            }
            allList[k].options = options
          }
        })
        defaultData.allList = allList
        let nowList = [];
        allList.forEach( function(v, k) {
          if(k>=(page.value-1)*pageSize.value&&k<page.value*pageSize.value)nowList.push(v);
        });
        defaultData.list = nowList;
        defaultData.template_name = response.template_name
        totalPage.value = Math.ceil(response.list.length/pageSize.value);
        // if(defaultData.list[0].type==5){
        //   fileList.value = defaultData.list[0].list
        //   fileList.value.forEach(v=>{
        //     v.file.rename = ''
        //   })
        // }
        // console.log(totalPage)
      }).catch(()=>{

      });
    }
    // getTagsList();
    //获取当前页需填写的项
    const getCurrentForm = (p)=>{
      let allList = defaultData.allList;
      let nowList = [];
      // console.log(p,' ',(p-1)*pageSize.value,'',p*pageSize.value)
      allList.forEach( function(v, k) {
        if(k>=(p-1)*pageSize.value&&k<p*pageSize.value)nowList.push(v);
      });
      defaultData.list = nowList;
      // console.log(nowList)
    }
    //保存当前页
    const saveCurrentPage = (list)=>{
      // debugger
      let formData = {};
      list.forEach( function(v) {
        if(v.type==5){
          formData[v.code] = v.list.join(',');
          // formData[v.code] = v.list
        }else if(v.type==3){
          formData[v.code] = v.options.join(',');
        }else{
          formData[v.code] = v.value;
        }

      });
      
      proxy.$post('/template/saveForms',{
        template_id:template_id,
        forms:formData,
      }).then((response)=>{
          resetPercent();
          Toast.success('保存成功！');
        console.log(response)
        console.log(defaultData.list)
      }).catch(()=>{

      });
    }
    //下一页
    const nextStep = ()=>{
      saveCurrentPage(defaultData.list);
      // console.log(page.value,'|',totalPage.value)
      if(page.value>=totalPage.value){
        return false;
      }else{
        page.value += 1;
        getCurrentForm(page.value);
      }
    }
    //上一页
    const preStep = ()=>{
      if(page.value>1){
        page.value -= 1
      }else{
        page.value = 1
      }
      getCurrentForm(page.value);
    }
    function changeItemList(list){
      defaultData.list = list
    }
    //保存
    const complate = ()=>{
      // debugger
      // let file = fileList.value
      // console.log(file)
      // if(file.length){
      //   file.map(v=>{
      //     console.log(v)
      //     submitImgs(v).then(()=>{
      //       let list = defaultData.list;
      //       saveCurrentPage(list)
      //     })
      //   })
      // }else{
        let list = defaultData.list;
        saveCurrentPage(list)
      // }
      
      console.log(defaultData.list)
      // let list = defaultData.list;
      // saveCurrentPage(list)
      // fileList.value = []
    }
    //上传成功 没用着
    function handleAvatarSuccess(response){
      if(response.code==1){
        Api.msg(response.msg)
      }else{
        var allList = defaultData.allList;
        var id = response.data.id;
        allList.forEach( function(v, k) {
          if(v.id==id){
            allList[k]['list'].push(response.data.src)
          }
        });
        defaultData.allList = allList;
      }
      
    }
    //更新进度
    const resetPercent = ()=>{
      let totalNum = 0;
      let complete = 0;
      defaultData.allList.forEach(v=>{
        totalNum++;
        if(v.value){
          complete++
        }
      })
      let percent = parseInt((complete/totalNum)*100)
      defaultData.percentage = percent
      let data = {}
      data.template_id = template_id;
      data.percent = percent;
      console.log(data)
      context.emit("updatePercent", data)
    }
    // 限制上传大小，超过了的提示
    function onOversize(file){
      console.log(file)
      Toast("大小不能超过5MB")
    }
    // 图片上传（没有传到服务器）
    function afterRead(file){
      debugger
      if(file instanceof Array){
        file.map(v=>{
          // v.status = 'uploading'
          // v.message = '上传中...'
          v.file.newName = v.file.name
          // submitImgs(v)
        })
      } else {
        // file.status = 'uploading'
        // file.message = '上传中...'
        file.file.newName = file.file.name
        // submitImgs(file)
      }
      console.log(fileList.value)
    }
    // 提交图片到服务器
    function submitImgs(file){
      debugger
      let formData = new FormData()
      formData.append('file', file.file)
      var result = axios.post('/index/uploader', formData, defaultData.headers)
      .then(res=>{
        console.log(res)
        if(res.data.code==0){
          // file.status = 'done'
          // file.message = '上传成功'
          // defaultData.list[0].list.push(res.data.data.src)
          var item = {file:res.data.data.src,  title:res.data.data.title}
          defaultData.list[0].list.push(item)
          //defaultData.list[0].list.push(res.data.data.src)
          // _this.companyInfo.license = res.data.data.src
        }
      })
      console.log(fileList.value)
      console.log(defaultData.list)
      fileList.value = []
      console.log(fileList.value)
      console.log(result)
      return Promise.resolve(result)
    }
    // 压缩图片
    // function compress(img){
    //   let url = ''
    //   var w = Math.min(700, img.width);//当图片像素>700的时候，等比例压缩，这个数字可以调
    //   var h = img.height * (w / img.width);
    //   var canvas = document.createElement('canvas')
    //   var ctx = canvas.getContext('2d')
    //   canvas.width = w
    //   canvas.height = h
    //   ctx.drawImage(img, 0, 0, w, h)
    //   url = canvas.toDataURL('image/png',1)//1代表精细度，越高越好
    //   return url
    // }
    // 将base64转换为blob
    // function dataURLtoBlob(data){
    //   var arr = data.split(',')
    //   var mine = arr[0].match(/:(.*?);/)[1]
    //   var bstr = atob(arr[1])
    //   var n = bstr.length
    //   var u8arr = new Uint8Array(n)
    //   while(n--){
    //     u8arr[n] = bstr.charCodeAt(n)
    //   }
    //   return new Blob([u8arr], {type: mine})
    // }
    // 查看图片放大,通过Popup组件
    function previewImg(e){
      isShow.value = true
      if(typeof e == 'string'){
        if(suff(e)=="pdf"){
          content.value = e
        }else{
          src.value = e
        }
      }else{
        if(suff(e.file.name)=='pdf'){
          content.value = e.content
        }else{
          src.value = e.content
        }
      }
      
    }
    // 关闭图片放大,通过Popup组件
    function close(n){
      isShow.value = n
    }
     
    // }
    //删除图片文件
    const deletePrevImg = (k)=>{
      fileList.value.splice(k,1)
    }
    const deleteImg = (index, k)=>{
      // let allList = defaultData.allList
      let allList = defaultData.list
      // console.log(allList)
      var row = allList[index]
      var path = row.list[k]
      proxy.$post('index/delImg',{
        path:path,
      }).then(res=>{
        if(res.code==0){
          if(row.list.length==1){
            row.list = []
          }else{
            row.list.splice(k, 1)
          }
          // console.log(row)
          allList[index] = row
          
          defaultData.list = allList 
          // console.log()
        }
      })
    }
    function changeValue(flag, url){
      isShow.value = flag
      if(suff(url)=="pdf"){
        content.value = url
      }else{
        src.value = url
      }
    }
    function changelist2(n, list){
      list.splice(n,1)
      fileList.value = list
    }
    function go(n){
      router.go(n)
    }

    //监听路由变化
    watch(()=>router.currentRoute.value.path,(toPath)=>{
      if(toPath=='/home'){
        template_id = 0;
      }else if(toPath.indexOf('/home/information')!==-1){
        template_id = route.params.template_id||0
        page.value = 1
        getTagsList()
      }
    },{immediate:true,deep:true});
    
    
    return {
      ...toRefs(defaultData),
      page, pageSize, totalPage,
      isShow,
      src, content, 
      nextStep, preStep, 
      changeItemList, 
      complate, 
      handleAvatarSuccess, 
      resetPercent, 
      deletePrevImg, 
      deleteImg, 
      changeValue, 
      changelist2, 
      go, 

      fileList, 
      onOversize, 
      afterRead, 
      submitImgs, 
      // compress, 
      // dataURLtoBlob, 

      previewImg, 
      close, 


      suff, 
    }
  }
}
</script>

<style scoped>
  .bg{
    background: #FBFBFB;
  }
  .progress{
    padding: 0 50px;
    height: 40px;
    line-height: 40px;
    align-items: center;
    background:#fff;
    box-shadow: 0px 3px 4px 0px rgba(1,27,70,0.03);
    margin-top:44px;
  }
  .progress>span{
    width: 40px;
  }
  .progress>div{
    width: calc(100% - 40px);
  }
  :deep(.van-progress__pivot){
    height:12px;
    min-width: 12px;
    background:#fff !important;
    color:var(--color-text-blue);
    line-height:12px;
    box-shadow:1px 0 0px rgb(170, 160, 250);
  }
  :deep(.van-cell__right-icon){
    display: none;
  }
  .prudence{
    background: #E1EDFA;
    border: 1px solid #CFD2E6;
    border-radius: 4px;
    height: 36px;
    line-height: 36px;
    padding: 0 15px;
    margin: 0 12px;
  }
  .bg_fff{
    margin: 12px;
    border-radius: 10px;
    padding: 12px;
    box-shadow: 0px 3px 4px 0px rgba(1,27,70,0.03);
  }
  .bg_fff>div+div{
    margin-top: 20px;
  }
  /* input{
    border:none;
    border-bottom: 1px solid #EBEDF5;
    width: 100%;
    font-size:16px;
    line-height: 2; 
    margin-top:10px;
  } */
  :deep(.van-cell--clickable){
    border-bottom: 1px solid #EBEDF5;
    font-size: 16px;
  }
  :deep(.van-cell){
    padding-left:0;
    padding-right:0;
  }
  :deep(.van-field__word-limit){
    margin-top: -16px;
  }
  :deep(.van-field__control){
    background: #F5F6FA;
    padding:5px 10px;
    font-size: 16px;
    border-radius:5px;
    box-sizing:border-box;
  }
  .flex_wrap{
    gap:10px 15px;
    margin-top:10px;
  }
  .flex_wrap>div{
    min-width:100px;
  }
  :deep(.van-radio){
    align-items:flex-start;
  }
  :deep(.van-checkbox__icon .van-icon),
  :deep(.van-radio__icon .van-icon){
    font-size:14px;
    line-height: 1.5;
  }
  :deep(.van-checkbox__icon--checked .van-icon),
  :deep(.van-radio__icon--checked .van-icon){
    background:#3061F2;
    border:#3061F2;
  }
  textarea{
    width:100%;
    padding:5px 10px;
    box-sizing:border-box;
    font-size: 16px;
    background: #F5F6FA;
    border:none;
    border-radius:5px;
    height:65px;
    margin:12px 0;
  }
  :deep(.van-swipe__track){
    padding-bottom:30px;
  }
  :deep(.van-swipe__indicator){
    background-color:rgba(48, 97, 242, .5);
  }

  /* 上传资质 */
  /* :deep(.van-uploader__wrapper){
    position: relative;
    padding-top:50px;
    gap:10px;
  }
  :deep(.van-uploader__input-wrapper){
    position: absolute;
    top:10px;
  }
  :deep(.van-uploader__input-wrapper) span{
    display: inline-block;
    height:34px;
    line-height: 34px;
    padding:0 10px;
    white-space:nowrap;
    font-size: 16px;
    background: linear-gradient(90deg,#3061f2 0%, #aaa0fa 100%);
    color:#fff;
    border-radius:4px;
  }
  :deep(.van-uploader__preview-image){
    width:150px;
    height:150px;
    border: 1px solid #d9d9d9;
    border-radius: 4px;
    box-sizing: border-box;
  }
  :deep(.van-image__img){
    object-fit:contain !important;
    height:calc(100% - 32px);
  }
  :deep(.van-uploader__preview-cover){
    top: auto;
    display: flex;
    align-items: center;
    background: #f5f5f5;
    border-radius:0 0 4px 4px;
    padding:2px 0;
  }
  input{
    width:100%;
    padding:0 10px;
    border:1px solid #dcdfe6;
    border-radius:4px;
    height:25px;
    line-height:25px;
  }
  .icon-yixianshi-{
    margin:0 5px;
  } */
    /* 循环fileList */
  /* .van-uploader+.flex_wrap{
    gap:15px;
  }
  .item{
    width:150px;
    height:150px;
    border:1px solid #d9d9d9;
    background: #fff;
    border-radius: 4px;
    box-sizing: border-box;
    position: relative;
  }
  img{
    width:100%;
    height:110px;
    object-fit: contain;
  }
  .flex{
    background:#f5f5f5;
    border-radius: 0 0 4px 4px;
    padding: 4px 0;
    align-items:center;
  }
  .icon-yixianshi-{
    margin:0 5px;
  }
  .icon-guanbi{
    top: 0px;
    right: 0px;
    cursor: pointer;
    font-size: 20px;
  }

  :deep(.van-uploader__preview){
    display: none;
  } */
</style>
