<template>
  <div>
    <Head>
      <template #left><div>
        <i class="in_block icon-xiangxia trans90" @click="go(-1)"></i>
        设备清单
      </div></template>
    </Head>
    <form action="">
      <div class="item">办公设备：<span class="btn" @click="add(1)">添加办公设备</span>
        <div class="f12 red mt-10">注：实际办公中用到的设备</div>
        <table class="mt-10">
          <thead>
            <tr>
              <th width="25%">名称</th>
              <th width="25%">品牌</th>
              <th width="25%">数量</th>
              <th width="25%">操作</th>
            </tr>
          </thead>
          <tbody>
            <template v-if="office_equipment_list.length">
              <tr v-for="(item, index) in office_equipment_list" :key="item.id">
                <td>{{ item.name }}</td>
                <td><input v-model="item.model_parameter" placeholder="输入品牌"/></td>
                <td><input type="number" v-model="item.product_num" placeholder="数量"/></td>
                <td><i class="red icon-jianqu" @click="deletOfficeThis(1,item,index)"></i></td>
              </tr>
            </template>
            <template v-else>
              <tr>
                <td colspan="4" class="text_center lh2">暂无内容</td>
              </tr>
            </template>
          </tbody>
        </table>
      </div>
      <div class="item">生产设备：<span class="btn" @click="add(2)">添加生产设备</span>
        <div class="f12 red mt-10">注：请填写生产过程中所用到的设备</div>
        <table class="mt-10">
          <thead>
            <tr>
              <th width="25%">名称</th>
              <th width="25%">型号参数</th>
              <th width="25%">数量</th>
              <th width="25%">操作</th>
            </tr>
          </thead>
          <tbody>
            <template v-if="production_equipment_list.length">
              <tr v-for="(item, index) in production_equipment_list" :key="item.id">
                <td>{{ item.name }}</td>
                <td><input v-model="item.model_parameter" placeholder="输入型号参数"/></td>
                <td><input type="number" v-model="item.product_num" placeholder="数量"/></td>
                <td><i class="red icon-jianqu" @click="deletOfficeThis(2,item,index)"></i></td>
              </tr>
            </template>
            <template v-else>
              <tr>
                <td colspan="4" class="text_center lh2">暂无内容</td>
              </tr>
            </template>
          </tbody>
        </table>
      </div>
      <div class="item">监测设备：
        <div class="f12 red mt-10">注：请填写生产过程中所用到的监视测量设备</div>
        <table class="mt-10">
          <thead>
            <tr>
              <th width="25%">名称</th>
              <th width="25%">型号参数</th>
              <th width="25%">数量</th>
              <th width="25%">操作</th>
            </tr>
          </thead>
          <tbody>
            <template v-if="monitor_equipment_list.length">
              <tr v-for="(item, index) in monitor_equipment_list" :key="item.id">
                <td><input v-model="item.name" placeholder="输入名称"></td>
                <td><input v-model="item.model_parameter" placeholder="输入型号参数"/></td>
                <td><input type="number" v-model="item.product_num" placeholder="数量"/></td>
                <td><i class="red icon-jianqu" @click="deletMonitorThis(index)"></i>
                  <i class="blue icon-tianjia" @click="addThis"></i>
                </td>
              </tr>
            </template>
            <template v-else>
              <tr>
                <td colspan="4" class="text_center lh2">暂无内容</td>
              </tr>
            </template>
          </tbody>
        </table>
      </div>
      <div class="fixed text_center" @click="saveEquipment">保存</div>
    </form>
    <!--弹出层 添加设备的弹出层-->
    <div class="confirm" v-if="isShow">
      <div class="zhezhao"></div>
      <div class="bg_fff">
        <div class="title pr">设备列表<i class="pab icon-tianjia" @click="isShow=false"></i></div>
        <div class="search">
          <van-field v-model="keyword" clearable placeholder="输入要查找的设备名称" @keyup.enter="search"/>
          <i class="icon-icon-" @click="search"></i>
        </div>
        <ul class="list">
          <div class="bg_fff flex-b-c" v-for="(item, index) in list" :key="item.id">
            <span class="name">{{item.name}}</span>
            <span v-if="item.is_del" class="btn red icon-jianqu" @click="removeThis(item, index)"></span>
            <span v-else class="btn icon-tianjia" @click="increaseThis(item, index)"></span>
          </div>
        </ul>
      </div>
    </div>
  </div>
</template>

<script>
import { ref, reactive, toRefs, onMounted, getCurrentInstance } from 'vue'
import { useRouter } from 'vue-router'
import { Toast } from 'vant'
// import store from '@/store/index.js'
import Head from 'components/common/Head.vue'
export default {
  name: 'EquipmentSchedule',
  components: {
    Head, 
  },
  setup(props, context){
    const {proxy} = getCurrentInstance()
    const Api = proxy.$commonApi
    const router = useRouter()

    const _this = reactive({
      monitor_equipment_list:[],
      office_equipment_list:[],
      production_equipment_list:[],
      show_flag:1, // ?? 0：全部 1：办公设备 2：生产设备 3：监测设备

      keyword: '',
      equipment_type: 0,
      currentPage: 1,
      list:[],  //设备列表
    })
    let isShow = ref(false)
    onMounted(()=>{
      getEquipmentList()
    })
    function getEquipmentList(){
      proxy.$post('/company/getEquipmentList', _this)
      .then(res=>{
        console.log(res)
        _this.office_equipment_list = res.data.office_equipment_list
        _this.production_equipment_list = res.data.production_equipment_list
        _this.monitor_equipment_list = res.data.monitor_equipment_list
        // console.log(_this)
        // if(store.state.data.office_equipment_list.length || store.state.data.production_equipment_list.length){
        //   _this.office_equipment_list = store.state.data.office_equipment_list
        //   _this.production_equipment_list = store.state.data.production_equipment_list
        //   console.log(_this)
        // }
        // console.log(_this)
        // if(store.state.type==1){
        //   _this.office_equipment_list = store.state.equipmentList
        // }
        // if(store.state.type==2){
        //   _this.production_equipment_list = store.state.equipmentList
        // }
        console.log(_this)
      })
    }
    function indexList(){
      proxy.$post('/equipment/index', {
        search_word: _this.keyword,
        page: _this.currentPage,
        equipment_type: _this.equipment_type,
      }).then(res=>{
        console.log(res)
        _this.list = res.data
        // store.state.resList = res.data
        // console.log(store.state.resList)
        console.log(_this.list)
      })
    }

    // 删除table这一行
    function deletOfficeThis(equipment_type, item, index){
      var id = item.id
      if(equipment_type==1){
        _this.office_equipment_list.splice(index, 1)
        // store.state.officeList = _this.office_equipment_list
      }
      if(equipment_type==2){
        _this.production_equipment_list.splice(index, 1)
        // store.state.productionList = _this.production_equipment_list
      }
      _this.equipment_type = equipment_type
      indexList()
      setTimeout(()=>{
        console.log(_this.list)
        _this.list.filter(v=>{
          if(v.id==id){
            v.is_add = 1
            v.is_del = 0
          }
        })
      },1000)
      console.log(_this.list)
    }
    function deletMonitorThis(index){
      if(_this.monitor_equipment_list.length>1){
        _this.monitor_equipment_list.splice(index, 1)
      }else{
        return Toast('监测设备至少要有一项')
      }
    }
    // 添加table新的一行
    function addThis(){
      var i = {id:'', model_parameter:'', name:'', product_num:'0'}
      _this.monitor_equipment_list.push(i)
    }
    // 弹出层显示 添加设备
    function add(index){
      // if(name=='办公设备'){
      //   _this.equipment_type = 1
      //   // store.state.equipmentList = _this.office_equipment_list
      // }
      // if(name=='生产设备'){
      //   _this.equipment_type = 2
      //   // store.state.equipmentList = _this.production_equipment_list
      // }
      // console.log(store.state.equipmentList)
      // store.state.type = _this.equipment_type
      // router.push('/home/equipmentSchedule/equipmentScheduleAdd')
      isShow.value = true
      _this.equipment_type = index
      _this.keyword = ''
      indexList()
    }
    // 关闭弹出层
    function close(n){
      isShow.value = n
    }
    // 弹出框的关键字搜索
    const search = ()=>{
      indexList()
    }
    // 弹出层的去掉 删除这一项
    function removeThis(item, index){
      _this.list[index].is_add = 1
      _this.list[index].is_del = 0
      var id = item.id
      console.log(id, _this)
      if(_this.equipment_type==1){
        var a_index = _this.office_equipment_list.findIndex(i=>{
          return i.id==id
        })
        if(a_index !== -1){
          _this.office_equipment_list.splice(a_index, 1)
        }
      }
      if(_this.equipment_type==2){
        var b_index = _this.production_equipment_list.find(i=>{
          return i.id==id
        })
        if(b_index !== -1){
          _this.production_equipment_list.splice(b_index, 1)
        }
      }
      console.log(_this)
    }
    // 弹出框的加上
    function increaseThis(item, index){
      _this.list[index].is_add = 0
      _this.list[index].is_del = 1
      var id = item.id
      console.log(id)
      if(_this.equipment_type==1){
        var a = _this.office_equipment_list.find(i=>{
          return i.id==id
        })
        if(a==undefined){
          _this.office_equipment_list.push({id:item.id+'', name:item.name, model_parameter:'', product_num:''})
        }
      }
      if(_this.equipment_type==2){
        var b = _this.production_equipment_list.find(i=>{
          return i.id==id
        })
        if(b==undefined){
          _this.production_equipment_list.push({id:item.id+'', name:item.name, model_parameter:'', product_num:''})
        }
      }
      console.log(_this)
    }
    // 保存信息
    function saveEquipment(){
      proxy.$post('/company/saveEquipment',{
        office_equipment_list: _this.office_equipment_list,
        production_equipment_list: _this.production_equipment_list,
        monitor_equipment_list: _this.monitor_equipment_list
      }).then(res=>{
        console.log(res)
        if(res.code==0){
          resetPercent()
          // getEquipmentList()
        }
        Api.msg(res.msg)
      })
    }
    //更新进度
    const resetPercent = ()=>{
      let data = {}
      data.template_id = -7;
      context.emit("updatePercent", data)
    }
    // 返回上一步
    function go(n){
      router.go(n)
    }
    return {
      ...toRefs(_this), 
      isShow, 
      add, 
      deletOfficeThis, 
      deletMonitorThis, 
      addThis, 
      close, 
      search, 
      removeThis, 
      increaseThis, 
      saveEquipment, 
      resetPercent, 
      go, 
    };
  }
}
</script>

<style scoped>
  .item{
    margin:10px; 
    padding-bottom: 20px;
  }
  table{
    width:100%;
    border:1px solid #dadce5;
  }
  th{ 
    background:#eaeffe;
    font-size:14px;
    line-height: 3;
    border-right:1px solid #dadce5;
  }
  th:last-child{
    border:none;
  }
  td{
    padding:6px;
    border-right:1px solid #dadce5;
    border-bottom:1px solid #dadce5;
    text-align: center;
  }
  i.red{
    padding:3px;
    border:1px solid var(--color-text-red);
    border-radius:50%;
    width:20px;
    height:20px;
  }
  i.blue{
    padding:3px;
    border:1px solid var(--color-text-blue);
    border-radius:50%;
    width:20px;
    height:20px;
    margin-left:10px;
  }
  input{
    width:100%;
    padding:0 6px;
    box-sizing:border-box;
    border:1px solid #eee;
  }

  .confirm{
    position:fixed;
    top:0;
    left:0;
    width:100vw;
    height:100vh;
    background: rgba(0,0,0,.5);
    z-index:10;
  }
  :deep(.zhezhao){
    height:35vh;
  }
  .confirm>.bg_fff{
    background:#fff;
    margin-top:35vh; 
    height:65vh; 
    border-radius:20px 20px 0 0;
  }
  .confirm .title{
    height:3em; 
    line-height:3em; 
    padding:0 calc(3vw + 20px) 0 3vw;
    text-align: center;
    border-bottom:1px solid #f8f8f8;
  }
  .title .icon-tianjia{
    transform:rotate(45deg);
    right:3vw; 
    top: 0;
  }
  .search{
    margin:10px 12px 0;
    position:relative;
  }
  .search .van-cell{
    line-height:30px;
    padding:0 50px 0 20px;
    border:1px solid var(--color-text-blue);
    border-radius:20px;
  }
  .search i{
    position:absolute;
    font-size:24px;
    right:3px;
    top:3px;
    width:45px;
    height:26px;
    background:var(--color-text-blue);
    color:#fff;
    line-height:26px;
    border-radius:20px;
    text-align:center;
  }
  .list{
    height:calc(65vh - 100px);
    overflow: auto;
    margin-top:10px;
  }
  .list>.bg_fff{
    margin:12px;
    border-radius:10px;
    padding:12px;
    box-shadow: 0 3px 4px rgba(1,27,70,0.03);
  }
  .btn.red{
    background:var(--color-text-red);
    color:#fff;
  }
</style>
