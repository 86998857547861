<template>
  <div>
    <Head>
      <template v-slot:left><div>
        <i class="in_block icon-xiangxia trans90" @click="go(-1)"></i>
        打包文件预览
      </div></template>
    </Head>
    <div class="ml-10 mr-10">
      <span class="btn mt-10" @click="packageProject()">打包下载</span>
      <ul v-if="list.length" class="mt-10">
        <li class="flex-b-c" v-for="(item,index) in list" :key="index">
          <span class="tit">{{item.title}}</span>
          <div class="btn" @click="viewFile(item)">预览</div>
        </li>
      </ul>
      <div v-else>暂无文件列表！</div>
    </div>
    <div v-if="fileLoading" class="fixed">
      <van-loading color="#3061f2" size="30px" vertical>正在打包中...</van-loading>
    </div>
    <!-- 预览文件 -->
    <Cfm :is-show="isShow" @close="closeShow">
      <template v-slot:title>预览文件</template>
      <template v-slot:content>
        <div class="dialog_body">
          <div v-for="(item,index) in content_list" :key="index">
            <div v-html="item.content"></div>
            <div v-if="item.file_type=='project_manual' && index>7" class="close" @click="isShow=false">关闭</div>
            <div v-if="item.file_type=='project_manual' && index>7" class="edit" @click="innerVisible=true;record_id=item.id;manualHtml=item.content;">修改</div>
          </div>
        </div>
      </template>
    </Cfm>
    <!-- 编辑，内容修改 -->
    <Cfm :is-show="innerVisible" @close="closeInner">
      <template v-slot:title>内容修改</template>
      <template v-slot:content>
        <div class="dialog_body">
          <Editor api-key="uahg8l3f2b52kizlkonoxwoduijvjy7liz06xgq6m2uurqrh"
              v-model="manualHtml"
              :init="init"
          />
          <div class="flex">
            <label><input type="checkbox" v-model="is_change_recode"/> 是否生成修改记录</label>
            <div class="btn" @click="saveChange">保存</div>
            <div class="close" @click="innerVisible=false">关闭</div>
          </div>
        </div>
      </template>
    </Cfm>
  </div>
</template>

<script>
import { ref, reactive, toRefs, getCurrentInstance, onMounted } from 'vue'
import {useRouter} from 'vue-router'
import Head from 'components/common/Head.vue'
import Cfm from 'components/common/Cfm.vue'
// 引入编辑器
import Editor from '@tinymce/tinymce-vue'
export default {
  name: 'ViewProject',
  components: {
    Head,
    Cfm,
    Editor,
  },
  setup (props, context) {
    const {proxy} = getCurrentInstance()
    const Api = proxy.$commonApi
    const router = useRouter()

    let _this = reactive({
      site: Api.WEB_SITE,
      list:[],
      content_list:[],
      keyword:'',
      pageSize:10,
      count:0,
      current_page:1,
    })
    let isShow = ref(false)  //预览的弹框是否展示
    let innerVisible = ref(false) //修改的弹框是否展示
    let record_id = ref(0);  // 修改记录id
    let is_change_recode = ref(false);  // 是否生成修改记录标识
    let fileLoading = ref(false) //打包的时候的loading

    // 初始化富文本编辑器
    const init = {
      //language_url: '/tinymce/langs/zh_CN.js', // 中文化 默认为英文
      //base_url: '/tinymce', // 基础路径
      language: 'zh_CN', // 设置富文本编辑器语言
      license_key: 'gpl', // 注册key
      toolbar: 'undo redo fontfamily fontsize forecolor backcolor bold italic underline strikethrough alignleft aligncenter alignright removeformat',
      font_family_formats: '宋体=SimSun;黑体=SimHei;仿宋=FangSong;楷体=KaiTi;隶书=LiSu;幼圆=YouYuan;微软雅黑=Microsoft YaHei;Arial;Times New Roman;Verdana;', // 工具栏自定义字体选项
      font_size_formats: '9pt 10pt 11pt 12pt 14pt 16pt 18pt 20pt 22pt 24pt 36pt', //文字大小
      height: 'calc(100% - 100px)',  // 设置高度
      menubar: false, // 设置富文本编辑器菜单, 默认true
      branding: false, // 关闭底部官网提示 默认true
      statusbar: true, // 显示底部状态栏 默认true
      readonly: false, // 设置只读属性 默认 false
      resize: false, // 调节编辑器大小 默认 true
    };
    let manualHtml = ref('');

    onMounted(()=>{
      getProject()
    })
    const getProject = ()=>{
      proxy.$post('/Project/previewProject',{
        page:_this.current_page
      }).then(res=>{
        console.log(res)
        _this.list = res.data
        _this.pageSize = res.pageSize
        _this.count =  res.count
        _this.current_page = res.current_page
      })
    }
    // 打包下载
    function packageProject(){
      fileLoading.value = true
      proxy.$post('/project/packageProject',{
        project_id: 0
      }).then(res=>{
        console.log(res)
        if(res.code==0){
          fileLoading.value = false
          const link = document.createElement('a')
          link.setAttribute('href',Api.WEB_SITE+res.data)
          link.click()
        }else{
          Api.msg(res.msg)
        }
      })
    }
    //预览
    const viewFile = (item)=>{
      proxy.$post("Project/projectFile",{
        file_type:item.file_type,
      }).then((res)=>{
        console.log(res)
        if(res.code == 0){
          isShow.value = true;
          _this.content_list = res.data;
        }else{
          Api.msg(res.msg);
        }
      })
    }
    // 关闭弹出框
    function closeShow(flag){
      isShow.value = flag;
    }
    function closeInner(flag){
      innerVisible.value = flag;
    }
    // 保存
    const saveChange = () => {
      proxy.$post("Project/saveChangeRecord",{
        record_id: record_id.value,
        change_info: manualHtml.value,
        is_change_recode: is_change_recode.value?1:0,
      }).then((res)=>{
        if(res.code == 0){
          Api.msg(res.msg);
          innerVisible.value = false;
        }else {
          Api.msg(res.msg);
        }
      })
    }
    //更新进度
    const resetPercent = ()=>{
      let data = {}
      data.template_id = -3;
      context.emit("updatePercent", data)
    }
    function go(n){
      router.go(n)
    }
    return {
      ...toRefs(_this), 
      isShow, 
      innerVisible, 
      record_id, 
      fileLoading, 
      init, 
      manualHtml , 
      packageProject, 
      viewFile, 
      closeShow, 
      closeInner, 
      saveChange, 
      resetPercent, 
      go, 
    }
  }
}
</script>

<style scoped>
  li{
    background: #f5f6fa;
    height: 40px;
    line-height: 40px;
    width: 100%;
    border-radius: 4px;
    margin: 2px 0;
    padding: 0 6px;
    box-sizing: border-box;
  }
  .tit{
    width: 65%;
    font-size: 16px;
  }
  .confirm{
    background: linear-gradient(rgba(0, 0, 0, .5) 10vh, #fff 10vh, #fff 100vh);
    width: 100%;
    height: 100vh;
    position: fixed;
    top: 0;
    z-index: 10;
    padding:calc(10vh + 10px) 15px 20px;
    box-sizing: border-box;
  }
  .icon-tianjia{
    display: inline-block;
    transform:rotate(45deg); 
  }
  .dialog_body{
    height: calc(100% - 30px);
    margin-top:10px;
    margin-left:10px;
    overflow-y:auto;
  }
  .dialog_body>div{
    margin-bottom:30px;
  }
  .dialog_body img{
    min-width: 100px;
    max-width: 100%;
  }
  .close, .edit{
    float:right;
    background:transparent; 
    color:#1c1b22;
    height:26px;
    line-height: 26px;
    width:48px;
    text-align: center;
    border:1px solid #9d9fa6;
    border-radius:4px;
    font-size: 14px;
  }
  .edit{
    margin-right:15px;
    background:var(--color-text-blue);
    color:#fff;
    border-color:var(--color-text-blue);
  }
  .dialog_body .flex{
    justify-content: flex-end;
    margin:15px 10px;
    gap:10px;
  }
  .fixed{
    left: 0;
    top:0;
    width:100vw;
    height:100vh;
    background: rgba(255, 255, 255, 0.8);
    z-index:10;
    display: flex;
    justify-content: center;
    align-items: center;
  }
</style>
