<template>
  <div>
    <Head>
      <template v-slot:left><div>
        <i class="in_block icon-xiangxia trans90" @click="go(-1)"></i>
        我的
      </div></template>
    </Head>
    uer
  </div>
</template>

<script>
import { useRouter } from 'vue-router';
import Head from 'components/common/Head.vue';
export default {
  name: 'User',
  components:{
    Head,
  },
  setup () {
    const router = useRouter();

    function go(n){
      router.go(n)
    }
    return {
      go,
    };
  }
}
</script>

<style scoped>
  
</style>