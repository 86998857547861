<template>
  <div class="popup_bottom" :class="isShow?'open':''">
    <div class="zhezhao"></div>
    <div class="bg_fff">
      <div class="title"><slot name="title"></slot><i class="icon-guanbi" @click="close"></i></div>
      <slot name="content"></slot>
    </div>
  </div>
</template>

<script>
export default {
  name: 'Cfm',
  props: {
    isShow:{
      type:Boolean
    },
  },
  setup (props, context) {
    const close = () => {
      context.emit('close', false);
    }
    return {
      close,
    }
  }
}
</script>

<style scoped>
  .popup_bottom{
    position: fixed;
    top: 100%;
    left: 0;
    width: 100%;
    height: 100%;
    transition: all .8s cubic-bezier(0.685, 0.0473, 0.346, 1);
    z-index: 10;
  }
  .open{
    top:0;
  }
  .zhezhao{
    position:relative;
  }
  .bg_fff{
    position: relative;
    top: -100%;
    left: 0;
    width: 100vw;
    height:90vh;
    border-radius:20px 20px 0 0;
    transition: all 1s cubic-bezier(0.685, 0.0473, 0.346, 1);
    z-index:10;
  }
  .open .bg_fff{
    transform:translateY(10vh);
  }
  .title{
    text-align: center;
    font-size: 16px;
    font-weight: bold;
    line-height: 3;
    box-shadow:0 1px 5px rgba(1,27,70,.1); 
    margin-bottom:10px;
  }
  .title i{
    float: right;
    line-height: 48px;
    margin-right: 15px;
    font-size: 22px;
    font-weight: normal;
  }
</style>
