<template>
  <div class="head">
    <slot name="left"></slot>
    <slot name="middle"></slot>
    <slot name="right"></slot>
  </div>
</template>

<script>
export default {
  name:'Head'
}
</script>

<style>
  .head{
    height:44px;
    line-height:44px;
    box-shadow: 0px 3px 4px 0px rgba(1,27,70,0.03);
    display:flex;
    justify-content: space-between;
    align-items: center;
    padding:0 12px;
    position:fixed;
    left:0;
    top:0;
    background:#fff;
    width:100%;
    box-sizing: border-box;
    z-index: 10;
  }
  
</style>