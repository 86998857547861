<template>
  <div class="bg">
    <Head>
      <template v-slot:left><div>
        <i class="in_block icon-xiangxia trans90" @click="go(-1)"></i>
        已选清单
      </div></template>
    </Head>
    <div v-if="!manage" class="text_right" @click="changeManage">
      <i class="icon-yixianshi-"></i> 管理清单
    </div>  
    <div v-else class="text_right red" @click="changeManage">退出管理</div>
    
    <div v-if="pickedList.length>0" class="list">
      <!-- <van-list v-model="loading" :finished="finished" finished-text="没有更多了" @load="onLoad"> -->
      <div v-for="(item, index) in pickedList" :key="item.id" class="flex--c">
        <div v-show="manage" class="left">
          <i :class="item.ispicked?'icon-danxuan_xuanzhong blue':'icon-yuanhuan'" 
            @click="selectedThis(item)"></i>
        </div>
        <van-swipe-cell class="right">
          <div class="right_text">
            <div class="tit">{{ item.name }}</div>
            <div class="num mt-15">{{item.number}}</div>
          </div>
          <template v-if="!manage" #right>
            <van-button square type="danger" text="删除" @click="subtraction(index)"/>
          </template>
        </van-swipe-cell>
      </div>
      <!-- </van-list> -->
      <div v-show="manage" class="fixed flex-b-c">
        <div @click="checkAllFun">
          <i :class="checkAll ? 'icon-danxuan_xuanzhong blue' : 'icon-yuanhuan'"></i> 全选
        </div>
        <span @click="deleteAll">删除<span >({{num}})</span></span>
      </div>
    </div>
    <van-empty v-else image="error" description="暂无清单！" />
  </div>
</template>

<script>
import { ref, reactive, toRefs, onMounted, getCurrentInstance } from 'vue'
import { useRouter } from 'vue-router'
import Head from 'components/common/Head'
import { Toast, Dialog } from 'vant'

export default {
  name: 'ExternalPickedList',
  components:{
    Head,
  },
  setup (props,context) {
    const {proxy} = getCurrentInstance()
    const Api = proxy.$commonApi
    const router = useRouter()

    let manage = ref(false)  //判断是否在管理清单
    let _this = reactive({
      list:[],
      pickedList:[],
    })
    let num = ref(0)    //管理的时候 选中的个数
    let checkAll = ref(false)
    // let loading = ref(false)
    // let finished = ref(false)
    
    onMounted(()=>{
      getMyListing()
      getListing()
    })
    const getMyListing = ()=>{
      proxy.$post('listing/init_listing',{})
      .then(res=>{
        console.log(res)
        res.list.forEach(v=>{
          v.ispicked = false
        })
        _this.pickedList = res.list
      })
    }
    // 获取外来清单
    const getListing = ()=>{
      proxy.$post('listing/index',{})
      .then(res=>{
        console.log(res)
        let list = res.data
        _this.list = updateList(list)
      })
    }
    // 返回上一步
    function go(n){
      router.go(n)
    }
    // 管理清单 || 完成
    function changeManage(){
      manage.value = !manage.value
    }
    // 删除单一项 左滑点击“删除”
    const subtraction = (index)=>{
      _this.pickedList.splice(index, 1)
      _this.list = updateList(_this.pickedList)
      saveListing()
      // return false;
    }
    //更新列表
    function updateList(list){
      let pickIds = Api.array_column(_this.pickedList, 'id')
      list.forEach((v,k)=>{
        if(pickIds.indexOf(v.id.toString()) >= 0){
          list[k].is_add = 1
        }else{
          list[k].is_add = 0
        }
      })
      return list
    }
    //保存外来清单
    const saveListing = ()=>{
      proxy.$post("listing/saveListing",{
        list:_this.pickedList,
      }).then((res)=>{
        if(res.code==0){
          resetPercent()
          Toast.success('操作成功！')
          // proxy.$message({type: 'success',message: '操作成功！'});
        }else{
          Toast.fail('操作失败！')
          // proxy.$message.error('操作失败！');
        }
      })
    }
    //更新进度
    const resetPercent = ()=>{
      let data = {}
      data.template_id = -1;
      context.emit("updatePercent", data)
    }
    // 是否选中要删除的项，并且选中的数字个数发生变化
    function selectedThis(item){
      if(item.ispicked){
        item.ispicked = !item.ispicked
        num.value--
      }else{
        item.ispicked = !item.ispicked
        num.value++
      }
      // 是否全选
      if(num.value === _this.pickedList.length){
        checkAll.value = true
      } else{
        checkAll.value = false
      }
    }
    // 点击全选
    function checkAllFun(){
      if(checkAll.value){
        checkAll.value = !checkAll.value
        for(var i in _this.pickedList){
          _this.pickedList[i].ispicked = false
        }
        num.value = 0
      } else {
        checkAll.value = !checkAll.value
        for(var j in _this.pickedList){
          _this.pickedList[j].ispicked = true
        }
        num.value = _this.pickedList.length
      }
    }
    // 点击删除按钮
    function deleteAll(){
      if(num.value === 0){
        Toast('请先选择要删除的清单')
      } else {
        // isShow.value = true
        Dialog.confirm({
          message:'确认将这' + num.value + '条清单删除吗？', 
          cancelButtonText:'再想想',
          cancelButtonColor:'#888'
        }).then(()=>{
          console.log('点击了确认')
          var list = _this.pickedList;
          for(var i=0; i<list.length; ){
            if(list[i].ispicked){
              list.splice(i,1)
            }else{
              i++
            }
          }
          num.value = 0
          if(_this.pickedList.length==0){
            manage.value = !manage.value
          }
          // list.forEach(v=>{
          //   if(v.fixed!==1) newList.push(v)
          // })
          // _this.pickedList = newList
          // _this.list = updateList(_this.list)
          _this.list = updateList(_this.pickedList)
          saveListing()
          Toast.success('删除成功！')
        }).catch(()=>{
          console.log('点击了取消/再想想')
        })
      }
    }
    // const onLoad = ()=>{
    //   _this.currentPage += 1
    // }
    // watch((_this.pickedList,(newV)=>{
    //   _this.pickedList = newV
    // }),{deep:true,immediate:true}
    // )

    return {
      manage, 
      ...toRefs(_this), 
      num, 
      checkAll, 

      getMyListing, 
      getListing, 
      go, 
      changeManage, 
      subtraction, 
      updateList, 
      saveListing, 
      resetPercent, 
      selectedThis, 
      checkAllFun, 
      deleteAll, 
      // loading, finished,
      // onLoad,
    }
  }
}
</script>

<style scoped>
  .bg{
    background: #FBFBFB;
    min-height:calc(100vh - 50px);
  }
  .text_right{
    margin-right:12px;
    height: 30px;
    line-height: 30px;
  }
  .list{
    padding-bottom:55px;
    min-height: calc(100vh - 44px - 50px);
  }
  .flex--c{
    margin:12px;
  }
  .left i{
    font-size:20px;
    margin-right: 8px;
  }
  .right{
    width: 100%;
    /* padding:12px; */
    background:#fff;
    box-shadow: 0px 3px 4px 0px rgba(1,27,70,0.03);
    border-radius: 10px;
  }
  .right_text{
    width:100%;
    padding:12px;
    box-sizing:border-box;
  }
  :deep(.van-button--square){
    height:100%;
  }
  .tit{
    font-size:16px;
  }
  .num{
    font-size:14px;
    color:#999;
  }
  .num+div{
    background: var(--color-text-blue);
    color:#fff;
    font-size:14px;
    padding:0 12px;
    height:20px;
    line-height: 20px;
    border-radius:20px;
  }
  .fixed{
    background: #fff;
    height: 50px;
    padding-left:20px;
    padding-right:12px;
    bottom: 0;
    left: 0;
    right: 0;
    box-shadow: 0px -3px 4px 0px rgba(1,27,70,0.03);
  }
  .fixed i{
    font-size: 20px;
  }
  .fixed span{
    background: var(--color-text-blue);
    color:#fff;
    height:40px;
    line-height: 40px;
    text-align: center;
    border-radius:20px;
    width:60%;
    margin-left:20%;
    bottom:20px; 
    z-index: 5;
  }
  .fixed span span{ 
    margin-left:0 
  }
</style>
