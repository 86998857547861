<!-- 外来清单 -->
<template>
  <div class="bg">
    <Head>
      <template v-slot:left><div>
        <i class="in_block icon-xiangxia trans90" @click="go(-1)"></i>
        执行标准
      </div></template>
    </Head>
    <!-- <div class="progress flex">
      <span class="f14">进度</span>
      <van-progress :percentage="percentage" stroke-width="12" track-color="#F5F6FA" 
      color="linear-gradient(90deg, #3061F2 1%, #AAA0FA 100%)" pivot-text="√" />
      <span><span class="red">{{percentage}}</span>/100</span>
    </div> -->
    <form action="" ref="formScreening">
      <div class="flex-b-c mb-10">
        <div class="search">
          <!-- <select v-model="type">
            <option v-for="item in dropdownList" :key="item.value" :label="item.label" :value="item.value"></option>
          </select> -->
          <van-dropdown-menu active-color="#3061F2">
            <van-dropdown-item v-model="type" :options="dropdownList" />
          </van-dropdown-menu>
          <van-field v-model="keyword" clearable :placeholder="type==1?'请输入产品、服务名称':'请输入条款、法规编号'" @keyup.enter="search"/>
          <i class="icon-icon-" @click="search"></i>
        </div>
        <!-- <div class="filter">
          <i class="icon-filter"></i>
          <Linkage :first_id="industrys_pid" :second_id="industrys_id" length="4" type="listing_category" @changeEvent="changeListing"></Linkage>
        </div> -->
        <button type="button" class="chooseBtn" @click="chooesedList">已选清单({{pickedList.length}})</button>
      </div>
    </form>
    <div class="prudence" v-if="!showAll && pickedList.length">已选 <span class="red">{{pickedList.length}}条</span> 信息</div>
    <div class="prudence" v-else>为您搜索到 <span class="red">{{count}}条</span> 相关信息</div>
    <ul v-if="!showAll && pickedList.length" class="list">
      <div v-for="(item, index) in pickedList" :key="item.id" class="bg_fff">
        <div class="tit line2">{{ item.name }}</div>
        <div class="flex-b-c mt-10">
          <div class="num">{{item.number}}</div>
          <div class="addbtn" @click="deleteThis(index, item)">
              <i class="icon-jianqu"></i>
            </div>
        </div>
      </div>
    </ul>
    <ul v-else-if="listAll.length">
      <van-list v-model:loading="loading" :finished="finished" finished-text="没有更多了" :immediate-check="false" offset="50" @load="onLoad">
        <li v-for="item in listAll" :key="item.id" class="bg_fff" :class="item.picked?'on':''" >
          <div class="tit line2">{{item.name}}</div>
          <div class="flex-b-c mt-10">
            <div class="num">{{item.number}}</div>
            <div class="addbtn" :class="item.is_add?'disabled':''" @click="checkThis(item)">
              <i class="icon-tianjia"></i>
            </div>
          </div>
        </li>
      </van-list>
    </ul>
    <van-empty v-else image="error" description="暂无清单！" />
    <div class="fixed flex-b-c">
      <!-- <van-badge v-if="content" :content="content" @click="toPickedList">
        <i class="icon-shu"></i>
      </van-badge>
      <i v-else class="icon-shu" @click="toPickedList"></i>
      <span>生成清单</span> -->
      <div v-if="!showAll && pickedList.length" class="blue" @click="deleteAllPicked">全部删除</div>
      <div v-else class="flex--c red">需要选择您企业所生产产品的相关执行标准，输入产品名称或者标准编号即可。</div>
    </div>
  </div>
</template>

<script>
import { ref, reactive, toRefs, onMounted, getCurrentInstance,
  watch
} from 'vue'
import { useRouter } from 'vue-router'
import Head from 'components/common/Head'
// import Linkage from 'components/common/Linkage.vue'
import { Toast, Dialog } from 'vant'

export default {
  name: 'ExternalList',
  components:{
    Head,
    // Linkage,
  },
  setup(props, context){
    const router = useRouter()
    const {proxy} = getCurrentInstance()
    const Api = proxy.$commonApi

    let _this = reactive({
      list:[],
      keyword:'',
      pageSize:10,
      count:0,
      currentPage:1,
      pickedList:[],
      cid:0,
      level:0,
      type:1,
      dropdownList:[{value:1, text:'名称'}, {value:2, text:'编号'}],
    })
    let industrys_pid = ref(0)
    let industrys_id = ref(0)

    let listAll = ref([])
    let loading = ref(false)
    let finished = ref(false)
    let showAll = ref(false)  //是否显示全部的清单，默认否，显示的是已选清单

    onMounted(()=>{
      getMyListing(true)
    })
    //已加入列表中的清单 
    function getMyListing(show=true){
      proxy.$post('listing/init_listing',{

      }).then(res=>{
        console.log(res)
        _this.pickedList = res.list
        if(res.list.length){
          showAll.value = false
        }else{
          showAll.value = true
        }
        if(show) getListing()
      }).catch(err=>{
        console.log(err)
      })
    }
    //获取外来清单列表
    function getListing(){
      if(_this.level<1 && _this.cid>0){
        Api.msg('分类至少选择两级！')
        return false
      }
      // var load = layer.load(0, {offset:['40%','65%']})
      proxy.$post('listing/index',{
        page:_this.currentPage,
        cid:_this.cid,
        name:_this.keyword,
        type:_this.type,
      }).then(res=>{
        console.log(res)
        loading.value = false
        let list = res.data
        _this.list = updateList(list)
        _this.pageSize = res.pageSize
        _this.count = res.count
        console.log(_this.currentPage, '------', res.current_page)
        _this.currentPage = res.current_page

        listAll.value = listAll.value.concat(res.data)
        if( res.count <= listAll.value.length){
          finished.value = true
        }
        console.log(listAll.value)
        // layer.close(load)
      })
    }
    //更新列表
    function updateList(list){
      let pickIds = Api.array_column(_this.pickedList, 'id')
      list.forEach((v,k)=>{
        if(pickIds.indexOf(v.id.toString()) >= 0){
          list[k].is_add = 1
        }else{
          list[k].is_add = 0
        }
      })
      return list
    }
    //分类回调
    const changeListing = (cids,level)=>{
      _this.cid = cids[level]||0;
      _this.level = level;
      search()
      // console.log(cids,'|',level)
    }
    
    //搜索
    const search = ()=>{
      showAll.value = true
      listAll.value = []
      _this.currentPage = 1;
      finished.value = false
      getListing()
      // 拿到全部的数据，所以要切换_this.currentPage的值，
      // 然后一次展示10条，拉倒最底部加载新的10条，就是onLoad那个函数
      // _this.currentPage += 1
      // getListing()

    }
    //添加清单
    const checkThis = (row)=>{
      if(row.is_add){
        Toast('此项已添加！')
        return false
      }else{
        row.is_add = 1
      }
      _this.pickedList.push(row);
      _this.list = updateList(_this.list);
      saveListing();
    }
    //删除清单
    const deleteThis = (index, row)=>{
      Dialog.confirm({
        message:'你确定要删除清单吗？'
      }).then(()=>{
        row.is_add = 0
        _this.pickedList.splice(index, 1)
        _this.list = updateList(_this.pickedList)
        saveListing()

        if(_this.pickedList.length==0){
          search()
        }
      })
    }
    // 全部删除
    function deleteAllPicked(){
      Dialog.confirm({
        message:'你确定要删除全部清单吗？', 
      }).then(()=>{
        var list = _this.pickedList;
        for(var i=0; i<list.length; ){
          list[i].is_add = 0
          list.splice(i, 1)
        }
        _this.list = updateList(_this.pickedList)
        saveListing()
        Toast.success('删除成功！')
        search()
      })
    }
    // 跳转到已选清单的页面
    function chooesedList(){
      // router.push('/home/externalList/externalPickedList')
      showAll.value = false
    }
    //保存外来清单
    const saveListing = ()=>{
      proxy.$post("listing/saveListing",{
        list:_this.pickedList,
      }).then((res)=>{
        if(res.code==0){
          resetPercent()
          Toast.success({message:'操作成功！'})
        }else{
          Toast.fail({message:'操作失败！'})
        }
      })
    }
    //更新进度
    const resetPercent = ()=>{
      let data = {}
      data.template_id = -1;
      context.emit("updatePercent", data)
    }
    // 返回上一步
    function go(n){
      router.go(n)
    }
    const onLoad = ()=>{
      _this.currentPage += 1
      loading.value = true
      getListing()
    }
    watch(loading, (newV)=>{
      console.log('loading发生了变化，现在的值是：', newV)
      loading.value = newV
    })

    return{
      ...toRefs(_this), 
      industrys_pid, 
      industrys_id,
      listAll, 
      loading, 
      finished, 
      showAll, 
      getMyListing, 
      getListing,
      updateList, 
      changeListing, 
      search, 
      checkThis, 
      deleteThis, 
      deleteAllPicked, 
      chooesedList, 
      saveListing, 
      resetPercent,  
      go, 
      onLoad, 
    }
  }
}
</script>

<style scoped>
  .bg{
    background: #fbfbfb;
    min-height:calc(100vh - 50px);
  }
  :deep(.van-progress__pivot){
    height:12px;
    min-width: 12px;
    background:#fff !important;
    color:var(--color-text-blue);
    line-height:12px;
    box-shadow:1px 0 0px rgb(170, 160, 250);
  }
  .progress{
    padding: 0 50px;
    height: 40px;
    line-height: 40px;
    align-items: center;
    background:#fff;
    box-shadow: 0px 3px 4px 0px rgba(1,27,70,0.03);
    margin-top:44px;
  }
  .progress>span{
    width: 40px;
  }
  .progress>div{
    width: calc(100% - 40px);
  }
  .progress+div{
    margin:12px;
  }
  form{
    margin-left:12px;
    margin-right: 12px;
    padding-bottom: 0;
    min-height:auto;
  }
  .search{
    width:calc(100% - 50px);
    position: relative;
  }
  :deep(.van-dropdown-menu){
    border:0;
    height: 30px;
    position: absolute;
    z-index: 2;
    background: transparent;
    width: 4em;
    text-align: center;
  }
  :deep(.van-dropdown-menu__bar){
    background: transparent;
    height: 32px;
    border-radius:20px 0 0 20px;
  }
  :deep(.van-ellipsis){
    font-size:14px;
  }
  :deep(.van-dropdown-item){
    width:5em;
    font-size:14px;
    left:12px;
  }
  :deep(.van-dropdown-item .van-overlay),
  :deep(.van-dropdown-item .van-cell__value){
    display: none;
  }
  .search :deep(.van-cell){
    line-height: 30px;
    padding:0 50px 0 64px;
    border: 1px solid var(--color-text-blue);
    border-radius: 20px;
  }
  :deep(.van-dropdown-item .van-cell){
    padding:0 10px;
    border:none;
  }
  /* input{
    background:#fff;
    border:1px solid var(--color-text-blue);
    border-radius:20px;
    height:30px;
    line-height: 30px;
    padding-left:15px;
    padding-right:50px;
    width: 100%;
    box-sizing: border-box;
  } */
  .icon-icon-{
    font-size: 24px;
    position: absolute;
    right: 3px;
    top: 3px;
    width: 45px;
    height: 26px;
    background: #3061F2;
    color: #fff;
    line-height: 26px;
    border-radius: 20px;
    text-align: center;
  }
  .filter{
    width:50px;
    text-align: center;
    position:relative;
  }
  .filter .fill{
    position: absolute;
    z-index: 0;
    left:0;
    top:0;
    width:100%;
    height:100%;
  }
  .filter :deep(.van-cell){
    width:100%; 
    height:100%;
    box-sizing:border-box;
    background: transparent;
  }
  .filter :deep(.van-cell input),
  .filter :deep(.van-cell input::placeholder){
    color:transparent; 
  }
  .chooseBtn{
    background: var(--color-text-blue);
    color:#fff;
    border:0;
    border-radius:4px;
    white-space: nowrap;
    padding:0 10px;
    height:30px; 
    line-height:30px;
    font-size:15px;
  }
  .prudence{
    background: #E1EDFA;
    border: 1px solid #CFD2E6;
    border-radius: 4px;
    height: 36px;
    line-height: 36px;
    padding: 0 15px;
    margin: 10px 12px;
    font-size: 14px;
  }
  ul{
    /* margin-bottom:60px;
    height:calc(100vh - 110px); */
    height:calc(100vh - 190px);
    overflow-y:auto;
  }
  .bg_fff{
    margin: 12px;
    border-radius: 10px;
    padding: 12px;
    box-shadow: 0px 3px 4px 0px rgba(1,27,70,0.03);
  }
  .num{
    font-size:12px;
    color:#999;
  }
  .addbtn{
    background:var(--color-text-blue);
    color:#fff;
    border-radius:20px;
    padding:0 15px;
    height:28px;
    line-height: 28px;
    box-shadow: 0px 2px 10px 1px rgba(48,97,242,0.5);
  }
  .addbtn.disabled{
    background: #fff;
    color:var(--color-text-blue);

  }
  .fixed{
    background: #fff;
    height: 50px;
    padding-left:20px;
    padding-right:12px;
    bottom: 0;
    left: 0;
    right: 0;
    box-shadow: 0px -3px 4px 0px rgba(1,27,70,0.03);
  }
  /* .fixed span{
    background: var(--color-text-blue);
    color:#fff;
    height:40px;
    line-height: 40px;
    text-align: center;
    border-radius:20px;
    width:60%;
    margin-left:20%;
    bottom:20px; 
    z-index: 5;
  } */
</style>
