import { createApp } from 'vue'
import App from './App.vue'
import Vant from 'vant'
import router from '@/router/index'
import store from '@/store/index'
// import server from '@/utils/server'
import Request from '@/utils/axios'
import commonApi from '@/store/commonApi.js'

import '@/assets/css/font/iconfont.css'
import '@/assets/css/font/iconfont.js'
import 'vant/lib/index.css'

commonApi.getServerDate().then(()=>{
  const app = createApp(App)
  app.use(Vant)
  app.use(router)
  app.use(store)
  // app.use(server)
  app.use(Request)
  app.config.globalProperties.$axios = Request
  app.use(commonApi)
  app.config.globalProperties['$commonApi'] = commonApi
  app.config.globalProperties.HOST = '/api.php'

  app.mount('#app')
})
const debounce = (fn, delay) => {
  let timer = null;
  return function () {
    let context = this;
    let args = arguments;
    clearTimeout(timer);
    timer = setTimeout(function () {
      fn.apply(context, args);
    }, delay);
  }
}

const _ResizeObserver = window.ResizeObserver;
window.ResizeObserver = class ResizeObserver extends _ResizeObserver {
  constructor(callback) {
    callback = debounce(callback, 16);
    super(callback);
  }
}
