<template>
  <div>
    <van-checkbox-group v-model="options">
      <van-checkbox v-for="(it,k) in list" :key="k" shape="square" :name="it">{{it.title}}</van-checkbox>
    </van-checkbox-group>
    -------------------------
    <van-checkbox-group v-model="checked" shape="square">
      <van-checkbox v-for="(i,n) in lis" :key="n" :name="i">{{i}}</van-checkbox>
    </van-checkbox-group>
  </div>
</template>

<script>
import {ref, 
  // reactive, toRefs, getCurrentInstance
} from 'vue'
// import { useCascaderAreaData } from '@vant/area-data'
// import Linkage from 'components/common/Linkage.vue'

export default {
  name: 'demo',
  components: {
    
  },
  props: {},
  setup () {
    // const {proxy} = getCurrentInstance()
    // const Api = proxy.$commonApi
    // console.log(Api)
    let options = ref(['拔料机'])
    let list = ref([
      {title: '拔料机', check: '1'},
      {title: '磕料机', check: '0'},
      {title: '滚丝机', check: '0'},
      {title: '冷镦机', check: '0'}
    ])
    const checked = ref(['a', 'b']);
    let lis = ref(['复选框 a', '复选框 b'])
    return {
      options,list,
      checked,
      lis,
    };
  }
}
</script>

<style scoped>
  

</style>