<template>
  <div>
    <Head>
      <template v-slot:left><div class="f18">企业管理</div></template>
      <template v-slot:right><button @click="quit"><i class="icon-tuichu mr-10"></i>退出</button></template>
    </Head>
    <div class="flex_wrap">
      <Itembar v-for="item in list" :key="item.id + new Date().getTime()"
        :currentRate="item.percent" :status="item.status" 
        @click="itemClick(item.id)">
        <template #name>{{item.company_name ? item.company_name : item.project_name}}</template>
        <template #shortname>{{item.brief_name}}</template>
        <template #system><div class="f12">{{item.cer_name}}</div></template>
        <template #industry_name>{{item.industry_name}}</template>
        <div class="red dele" @click.stop="delProject(item.id)">删除</div>
      </Itembar>
    </div>
    <div class="fixed" @click="goAdd"><i class="icon-tianjia"></i></div>
  </div>
</template>

<script>
import { reactive, toRefs, onMounted, getCurrentInstance } from 'vue'
import { useRoute, useRouter } from 'vue-router'
import { Toast } from 'vant'
import Head from 'components/common/Head'
import Itembar from 'components/content/change/ItemBar'

export default {
  name:'Change',
  components:{
    Head,
    Itembar,
  },
  setup(){
    const {proxy} = getCurrentInstance()
    const Api = proxy.$commonApi
    const route = useRoute()
    const router = useRouter()
    console.log(route.params.id)

    let defaultData = reactive({
      list:[],
    })

    onMounted(()=>{
      getProjectList()
    })
    // 获取列表
    function getProjectList(){
      proxy.$post('project/list',{

      }).then(res=>{
        defaultData.list = res.data
        console.log(res)
      }).catch(err=>{
        console.log(err)
      })
    }
    // 退出登录
    function quit(){
      Api.removeItem('user_id')
      Api.removeItem('project_id')
      router.push('/login')
    }
    // 删除企业
    function delProject(id){
      Api.confirm('你确定要删除吗？',()=>{
        proxy.$post('project/delProject',{
          project_id: id
        }).then(res=>{
          console.log(res)
          Api.msg(res.msg, 0, '', ()=>{
            if(res.code==0 || res.code==200){
              getProjectList()
            }
          })
        }).catch(err=>{
          console.log(err)
        })
      })
    }
    // 切换企业/项目
    function itemClick(id){
      proxy.$post('project/change',{
        project_id:id,
      }).then(res=>{
        console.log(res)
        if(res.code==200 || res.code==0){
          // 更新会员信息
          Api.sessionSet('project_id', id)
          Api.storageSet('project_id', id, 3600)
          router.push('/home')
        }else{
          Toast(res.msg)
        }
      }).catch(err=>{
        console.log(err)
      })
    }
    function goAdd(){
      router.push('/change/add')
    }

    // watch(()=>router.currentRoute.value.path,(toPath)=>{
    //   if(toPath=='/home'){
    //     template_id = 0;
    //   }else if(toPath.indexOf('/home/information')!==-1){
    //     template_id = route.params.template_id||0
    //     page.value = 1
    //     getTagsList()
    //   }
    // },{immediate:true,deep:true});

    return{
      ...toRefs(defaultData),
      quit, 
      delProject, 
      itemClick,
      goAdd, 
    }
  }
}
</script>

<style scoped>
  .head button{
    font-size: 14px;
    background:#ebedf5;
    border:1px solid #dcdfe6;
    border-radius:4px;
    height: 32px;
    line-height: 32px;
    padding:0 15px;
  }
  .flex_wrap{
    padding:20px 12px 0;
    margin-top:44px;
    margin-bottom: 15px;
    gap:12px;
  }
  .dele{
    position: absolute;
    font-size:14px;
    top: 4px;
    right: 10px;
  }
  .fixed{
    right:4.4vw;
    bottom: 14.67vw;
    background:var(--color-text-blue);
    color:#fff;
    padding:15px;
    border-radius:50%; 
    box-shadow:0px 4px 20px 0px rgba(0,13,255,0.5);
    z-index:5;
  }
  .fixed i{
    font-size:28px;
  }
</style>