<!-- 编辑管理层 -->
<template>
  <div>
    <Head>
      <template #left><div>
        <i class="in_block icon-xiangxia trans90" @click="go(-1)"></i>
        管理员工
      </div></template>
    </Head>
    <div>
      <div class="flex">
        <button class="btn" @click="handleAdd">添加员工</button>
        <div>
          <input type="search" v-model="searchObj.search_word" placeholder="请输入员工姓名搜索"/>
          <button class="btn_search" @click="searchEvent"><i class="f20 icon-icon-"></i></button>
        </div>
      </div>
      <div class="list mt-10">
        <template v-if="tableData.length">
          <div class="item" v-for="item in tableData" :key="item.id">
            <div class="flex"><span>姓名</span><div>{{item.staff_name}}</div></div>
            <div class="flex"><span>职位</span><div>{{item.staff_position}}</div></div>
            <div class="flex"><span>学历</span><div>{{item.staff_education}}</div></div>
            <div class="flex"><span>入职时间</span><div>{{item.entry_time}}</div></div>
            <div class="flex">
              <span>级别</span>
              <div class="flex_wrap">
                <span v-if="item.is_head==1" class="red">负责人</span>
                <span v-if="item.is_head==0" @click="staff_updata(item.staff_id, item.department_id, 'is_head')">设为负责人</span>
                <span v-if="item.is_guandai==1" class="red">管理代表者</span>
                <span v-if="item.is_guandai==0" @click="staff_updata(item.staff_id, item.department_id, 'is_guandai')">设为管理代表者</span>
              </div>
            </div>
            <div class="flex">
              <span>操作</span>
              <div class="flex_wrap">
                <span @click="handleEdit(item)">编辑</span>
                <span class="red" @click="staffDel(item)">删除</span>
              </div>
            </div>
          </div>
        </template>
        <van-empty v-else description="暂无员工信息！" />
      </div>
    </div>
  </div>
</template>

<script>
import{ref, reactive, onMounted, getCurrentInstance} from 'vue'
import { useRouter } from 'vue-router'
import store from '@/store/index.js'
import Head from 'components/common/Head.vue'
export default {
  name: 'DepartmentEditGuan',
  components: {
    Head
  },
  setup () {
    const {proxy} = getCurrentInstance()
    const Api = proxy.$commonApi
    const router = useRouter()

    let row = ref({});
    let tableData = ref([]);
    const staff_form = ref({});
    let dataState = reactive({
      company_id: '',
      department_id: ''
    });
    // 查询输入框初始值
    const searchObj = reactive({
      search_word:''
    })
    onMounted(()=>{
      let item = store.state.item
      getStaffList(item.company_id, item.id)
      dataState.company_id = item.company_id
      dataState.department_id = item.id
    })
    //获取人员列表
    const getStaffList = (company_id,department_id) =>{
      proxy.$post("/department/staff_list",{
        //page:currentPage.value,
        company_id:company_id,
        department_id:department_id,
        search_word:searchObj.search_word
      }).then((res)=>{
        tableData.value = res.data.data;
      })
    }
    
    // 添加员工
    const handleAdd = ()=>{
      staff_form.value = {};
      staff_form.value.is_head = 0;
      staff_form.value.is_guandai = 0
      store.state.childitem = staff_form.value
      router.push('/home/department/addStaff')
    }
    // 查询事件
    const searchEvent=()=>{
      getStaffList(dataState.company_id, dataState.department_id);
    }
    // 删除员工信息
    const staffDel = (row)=>{
      Api.confirm('是否删除此员工?',()=>{
        proxy.$post('department/staffDel',{
          staff_id:row.staff_id,
        }).then(res=>{
          console.log(res)
          if(res.code==0||res.code==200){
            getStaffList(dataState.company_id,dataState.department_id);
          }
          Api.msg(res.msg);
        });
      });
    }
    //编辑人员
    const handleEdit = (row)=>{
      staff_form.value = row;
      if (row.is_head==1){
        staff_form.value.is_head = true;
      }
      if (row.is_guandai==1){
        staff_form.value.is_guandai = true;
      }
      // dialogFormVisible.value = true;
      store.state.childitem = staff_form.value
      router.push('/home/department/addStaff')
    }
    //更新员工级别
    const staff_updata = (staff_id,department_id,flag)=>{
      console.log(staff_id)
      console.log(department_id)
      console.log(flag)
      proxy.$post('department/staff_updata',{
        staff_id:staff_id,
        department_id:department_id,
        flag:flag
      }).then((res)=> {
        console.log(res)
        if (res.code == 0) {
          console.log(dataState.company_id)
          getStaffList(dataState.company_id, dataState.department_id);
        }
      });
    }
    // 返回上一步
    function go(n){ router.go(n) }

    return {
      row, 
      tableData, 
      staff_form, 
      dataState,
      searchObj, 
      getStaffList, 
      handleAdd,
      searchEvent,
      staffDel,
      handleEdit,
      staff_updata,
      go,
    }
  }
}
</script>

<style scoped>
  .head+div>.flex{
    margin: 12px;
  }
  .btn+div{
    width:calc(100% - 86px); 
  }
  input{
    border:1px solid #EBEDF5;
    font-size:16px;
    width: calc(100% - 45px - 10px);
    height:30px; 
    line-height: 30px;
    margin-left:10px;
    padding-left:10px;
    border-radius:5px 0 0 5px;
  }
  .btn_search{
    background:var(--color-text-blue);
    color:#fff;
    border:1px solid #EBEDF5;
    height:30px;
    width:45px;
    border-radius:0 5px 5px 0;
    margin-left:-1px;
    vertical-align: top;
  }
  .item{
    box-shadow: 0px 0px 4px rgba(1,27,70,0.05);
    margin: 10px;
    padding: 10px;
    border-radius:10px;
  }
  .item .flex{
    height:30px;
    line-height: 30px;
  }
  .flex>span{
    width:5em;
    font-size: 14px;
    color:#666;
  }
  .flex span+div{
    width:calc(100% - 60px);
    font-size: 14px;
  }
  .flex_wrap{
    gap:15px;
    font-size:14px;
    color:#000;
  }
  .flex_wrap span{
    color: #3061F2;
  }
  span.red{
    color:#f00;
  }
</style>
