<template>
  <div>
    <Head>
      <template v-slot:left><div>
        <i class="in_block icon-xiangxia trans90" @click="go(-1)"></i>
        部门信息
      </div></template>
    </Head>
    <form action="">
      <div>
        <div class="f14 color_9">部门名称</div>
        <div>
          <input type="text" v-model="row.name" placeholder="请输入部门名称" />
        </div>
      </div>
      <div>
        <div class="f14 color_9">所属部门</div>
        <div class="border">
          <van-field readonly clickable v-model="d_name" placeholder="请选择所属部门" @click="showPicker=true"/>
          <van-popup v-model:show="showPicker" round position="bottom">
            <van-cascader :options="department_list" @finish="onConfirm"  @close="showPicker=false" />
          </van-popup>
        </div>
      </div>
      <div>
        <div class="f14 color_9">部门职能</div>
        <div>
          <van-checkbox-group v-model="row.tags_ids" class="flex_wrap mt-10">
            <van-checkbox v-for="(it,k) in tagsList" :key="k" :name="it.tags_id"
              shape="square" checked-color="#3061F2">{{ it.tags_name }}</van-checkbox>
          </van-checkbox-group>
        </div>
      </div>
      <div class="fixed text_center" @click="saveDepartment">保存</div>
    </form>
  </div>
</template>

<script>
import { ref, reactive, toRefs, onMounted, getCurrentInstance } from 'vue'
import { useRouter } from 'vue-router'
import store from '@/store/index.js'
import Head from 'components/common/Head.vue'
export default {
  name: 'AddDepartment',
  components: {
    Head,
  },
  setup (props, context) {
    const router = useRouter() 
    const {proxy} = getCurrentInstance()
    const Api = proxy.$commonApi
    
    let _this = reactive({
      row:{},
      department_list:[],
      tagsList:[]
    })
    let d_name = ref('')  //页面上展示的 所属部门 的文字
    const showPicker = ref(false)
    const customFieldName = {
      text:'name',
    }
    // let partName = ref('')

    onMounted(()=>{
      _this.row = store.state.item
      console.log(_this.row)
      getDepartList()
    })
    function getDepartList(){
      proxy.$post('/department/list',{

      }).then(res=>{
        console.log(res)
        _this.department_list = res.departList
        _this.tagsList = res.tagsList
        res.departList.forEach(v=>{
          v.text = v.name
          v.value = v.id
        })
        // 根据pid来确定显示的文字，而不是显示pid的数字
        var pid = _this.row.pid
        if(pid){
          d_name.value = res.departList.filter(x => x.id==pid)[0].name
        }
      })
    }

    function go(n){ router.go(n); }
    const onConfirm = ({ selectedOptions }) => {
      d_name.value = selectedOptions.map((option) => option.text).join('/')
      showPicker.value = false
      _this.row.pid = selectedOptions[0].id
      // partName.value = val.name
    }
    // 保存部门信息
    const saveDepartment = () =>{
      let row = _this.row;
      if(!row.name){
        Api.msg('请填写部门名称！');
        return false;
      }
      if(!row.pid){
        Api.msg('请选择所属部门！');
        return false;
      }
      if(!row.tags_ids){
        Api.msg('请选择部门职能！');
        return false;
      }
      proxy.$post('department/saveDepartment',row).then((res)=>{
        console.log(res)
        if(res.code==0||res.code==200){
          getDepartList();
          resetPercent();
          go(-1)
        }
        Api.msg(res.msg)
      });
    }
    //更新进度
    const resetPercent = ()=>{
      let data = {}
      data.template_id = -2;
      context.emit("updatePercent", data)
    }

    return {
      ...toRefs(_this),
      d_name, 
      showPicker, 
      customFieldName, 
      go, 
      onConfirm, 
      saveDepartment, 
    }
  }
}
</script>

<style scoped>
  form{
    margin-left:12px;
    margin-right:12px; 
  }
  form>div{
    margin-bottom: 20px;
  }
  input{
    border:none;
    border-bottom: 1px solid #EBEDF5;
    width: 100%;
    font-size:16px;
    line-height: 2; 
    margin-top:10px;
  }
  .border{
    border-bottom: 1px solid #EBEDF5;
  }
  :deep(.van-cell){
    padding-left:0;
    padding-right:0;
    font-size: 16px;
  }
  :deep(.van-cell::after){
    display: none;
  }
  .flex_wrap{
    gap:15px 40px;
  }
</style>
