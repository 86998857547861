import  {createStore }  from 'vuex'

const store = createStore({
  state:{
    companyName: '北京恒歌科技有限公司',
    list: [
      {"tit":"矿用产品安全标志标识", "num":"AQ 1043-2007","picked":false},
      {"tit":"锂离子蓄电池汽车应急启动电源", "num":"CAB 1022-2014","picked":false},
      {"tit":"重庆市地理空间信息内容及要素代码标准", "num":"DB50/T 351-2010","picked":false},
      {"tit":"辣椒辣度的感官评价方法", "num":"GB/T 21265-2007","picked":false},
      {"tit":"标志用公共信息图形符号 第9部分: 无障碍设施符号", "num":"GB/T 10001.9-2008","picked":false},
      {"tit":"标志用公共信息图形符号 第2部分:旅游休闲符号", "num":"GB/T 10001.2-2006","picked":false},
      {"tit":"矿用产品安全标志标识2", "num":"AQ 1043-2007","picked":false},
      {"tit":"锂离子蓄电池汽车应急启动电源2", "num":"CAB 1022-2014","picked":false},
      {"tit":"重庆市地理空间信息内容及要素代码标准2", "num":"DB50/T 351-2010","picked":false},
      {"tit":"辣椒辣度的感官评价方法2", "num":"GB/T 21265-2007","picked":false},
      {"tit":"标志用公共信息图形符号 第9部分: 无障碍设施符号2", "num":"GB/T 10001.9-2008","picked":false},
      {"tit":"标志用公共信息图形符号 第2部分:旅游休闲符号2", "num":"GB/T 10001.2-2006","picked":false},
      {"tit":"矿用产品安全标志标识3", "num":"AQ 1043-2007","picked":false},
      {"tit":"锂离子蓄电池汽车应急启动电源3", "num":"CAB 1022-2014","picked":false},
      {"tit":"重庆市地理空间信息内容及要素代码标准3", "num":"DB50/T 351-2010","picked":false},
      {"tit":"辣椒辣度的感官评价方法3", "num":"GB/T 21265-2007","picked":false},
      {"tit":"标志用公共信息图形符号 第9部分: 无障碍设施符号3", "num":"GB/T 10001.9-2008","picked":false},
      {"tit":"标志用公共信息图形符号 第2部分:旅游休闲符号3", "num":"GB/T 10001.2-2006","picked":false},
      {"tit":"矿用产品安全标志标识4", "num":"AQ 1043-2007","picked":false},
      {"tit":"锂离子蓄电池汽车应急启动电源4", "num":"CAB 1022-2014","picked":false},
      {"tit":"重庆市地理空间信息内容及要素代码标准4", "num":"DB50/T 351-2010","picked":false},
      {"tit":"辣椒辣度的感官评价方法4", "num":"GB/T 21265-2007","picked":false},
      {"tit":"标志用公共信息图形符号 第9部分: 无障碍设施符号4", "num":"GB/T 10001.9-2008","picked":false},
      {"tit":"标志用公共信息图形符号 第2部分:旅游休闲符号4", "num":"GB/T 10001.2-2006","picked":false}
    ],
    newPickedList: [], 
    count: 0,
    item:{},  //部门人员的添加部门  顾客管理中某个顾客的信息
    childitem:{},  //部门人员的添加员工
  },
  mutations:{
    chanegCompName(state,n){
      state.companyName = n
    },
    changeList(state, array){
      // for(var i in array){
      //   array[i].picked = false
      // }
      state.newPickedList = array
    },
    changeCount(state, n){
      state.count = n
    }
  },
  actions:{},
  getters:{
    countAdd(state){
      return state.count++
    }
  },
  modules:{}
})
export default store