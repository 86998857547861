<template>
  <div>
    <Head>
      <template #left><div>
        <i class="in_block icon-xiangxia trans90" @click="go(-1)"></i>
        顾客信息
      </div></template>
    </Head>
    <form class="" action="">
      <div>
        <div class="f14 color_9">顾客名称</div>
        <div class="border">
          <input type="text" v-model="customer_form.customer_name" placeholder="请输入顾客名称"/>
        </div>
      </div>
      <div>
        <div class="f14 color_9">产品名称</div>
        <div class="border">
          <input type="text" v-model="customer_form.product_name" placeholder="请输入产品名称"/>
        </div>
      </div>
      <div>
        <div class="f14 color_9">销售数量</div>
        <div class="border">
          <input type="text" v-model="customer_form.sale_num" placeholder="请输入销售数量"/>
        </div>
      </div>
      <div>
        <div class="f14 color_9">销售日期</div>
        <div class="border">
          <van-field readonly v-model="customer_form.sale_date" is-link placeholder="销售日期" @click="showDate = true"/>
          <van-popup v-model:show="showDate" round position="bottom">
            <van-datetime-picker type="date" v-model="currentDate" :min-date="minDate" :max-date="maxDate" @confirm="chooseSaleDate" @cancel="showDate = false" />
          </van-popup>
        </div>
      </div>
      <div>
        <div class="f14 color_9">联系人</div>
        <div class="border">
          <input type="text" v-model="customer_form.contacts" placeholder="联系人"/>
        </div>
      </div>
      <div>
        <div class="f14 color_9">联系电话</div>
        <div class="border">
          <input type="text" v-model="customer_form.contact_phone" placeholder="联系电话"/>
        </div>
      </div>
      <div>
        <div class="f14 color_9">销售合同</div>
        <div class="border">
          <van-uploader class="mt-10" v-model="fileList" :before-read="beforeRead" :after-read="afterRead" 
            accept="application/msword,application/vnd.openxmlformats-officedocument.wordprocessingml.document,application/pdf,image/jpeg,image/png">
            <span class="btn">上传</span>
          </van-uploader>
          <input type="text" v-model="customer_form.sale_contract" placeholder="销售合同" readonly/>
        </div>
      </div>
      <div class="fixed text_center" @click="saveCustomer">保存</div>
    </form>
  </div>
</template>

<script>
import {ref, reactive, toRefs, getCurrentInstance, onMounted} from 'vue'
import { useRouter } from 'vue-router'
import { Toast } from 'vant';
import store from '@/store/index.js'
import axios from 'axios'
import Head from 'components/common/Head.vue'

export default {
  name: 'CustomerDetail', 
  components: {
    Head,
  },
  setup(props, context){
    const {proxy} = getCurrentInstance()
    const Api = proxy.$commonApi
    const router = useRouter()

    var project_id = Api.sessionGet('project_id');
    const _this = reactive({
      project_id: project_id,
      customer_list: [],
    })
    const customer_form = ref({})
    let currentDate = new Date()
    let showDate = ref(false)

    onMounted(()=>{
      customer_form.value = store.state.item
      console.log(customer_form.value)
      getCustomerList()
    })
    //获取顾客信息
    const getCustomerList = () => {
      proxy.$post('customer/index').then(res => {
        console.log(res)
        if (res.code == 0) {
          _this.customer_list = res.data.data
        }else {
          Api.msg(res.msg);
        }
      })
    }
    const getHeader = ()=>{
      let headers = Api.getHeader();
      let header = {}
      header.USERKEY = headers.userKey;
      header.USERTOKEN = headers.token;
      return header
    }
    // 选择入职时间
    const chooseSaleDate = (val) => {
      customer_form.value.sale_date = `${val.getFullYear()}-${val.getMonth() + 1}-${val.getDate()}`
      showDate.value = false;
      console.log(customer_form.value)
    }
    // 文件上传前检验格式和大小
    const beforeRead = (file)=>{
      console.log(file)
      const allowType = 'msword,vnd.openxmlformats-officedocument.wordprocessingml.document,pdf,png,jpeg'
      const type = file.type.split("/")[1]
      // 上传的只能是 doc,docx,pdf,jpeg,jpg,png 格式!
      if(allowType.indexOf(type) == -1){
        Toast.fail('文件格式错误！');
        return false;
      }else if(file.size > 2 * 1024 * 1024){
        Toast.fail('上传文件大小不能超过 2MB!');
        return false;
      }else{
        return file;
      }
    }
    //文件上传（没有传到服务器）
    const afterRead = (file)=>{
      file.status = 'uploading'
      file.message = '上传中...'
      console.log(file)
      submitImgs(file)
    }
    //提交文件到服务器
    function submitImgs(file){
      console.log(file)
      let formData = new FormData()
      formData.append('file', file.file)
      axios.post('/customer/importSaleContract', formData, getHeader())
      .then(res=>{
        console.log(res)
        if(res.data.code==0){
          file.status = 'done'
          file.message = '上传成功'
          console.log(res.data.url)
          customer_form.value.sale_contract = res.data.url
          console.log(customer_form.value)
        }else{
          Api.msg(res.msg)
        }
      })
      // axios.post('/customer/importSaleContract', file.file)
      // .then(res=>{
      //   console.log(res)
      //   if(res.code==0){
      //     file.status = 'done'
      //     file.message = '上传成功'
      //     // let item = {id:0, company_id:33, project_id:project_id, create_time:create_time, upload_time:upload_time, file:'二进制'}
      //     customer_form.sale_contract = res.url
      //   }else{
      //     Api.msg(res.msg)
      //   }
      // })
    }
    //保存顾客信息并返回上一步更新
    const saveCustomer = () => {
      let customer_name = customer_form.value.customer_name;
      let product_name = customer_form.value.product_name;
      let sale_num = customer_form.value.sale_num;
      let sale_date = customer_form.value.sale_date;
      let contacts = customer_form.value.contacts;
      let contact_phone = customer_form.value.contact_phone;
      let sale_contract = customer_form.value.sale_contract;
      if(!customer_name){
        Api.msg('顾客名称不能为空！');return false;
      }
      if(!product_name){
        Api.msg('产品名称不能为空！');return false;
      }
      if(!sale_num){
        Api.msg('销售数量不能为空！');return false;
      }
      if(!sale_date){
        Api.msg('销售日期不能为空！');return false;
      }
      if(!contacts){
        Api.msg('联系人不能为空！');return false;
      }
      if(!contact_phone){
        Api.msg('联系电话不能为空！');return false;
      }
      if(!sale_contract){
        Api.msg('销售合同不能为空！');return false;
      }
      proxy.$post('customer/saveCustomer', {
        'customer_form':customer_form.value,
      }).then(res => {
        Api.msg(res.msg, 0, '', function () {
          if (res.code == 0) {
            go(-1)
            getCustomerList();
            resetPercent();
          }
        })
      })
    }
    //更新进度
    const resetPercent = () => {
      let data = {}
      data.template_id = -8;
      context.emit("updatePercent", data)
    }
    function go(n){
      router.go(n)
    }
    return {
      ...toRefs(_this), 
      customer_form, 
      minDate: new Date(2000, 0, 1),
      maxDate: new Date(2025, 5, 1),
      currentDate,
      showDate,
      getHeader,
      chooseSaleDate, 
      beforeRead, 
      afterRead, 
      saveCustomer, 
      resetPercent, 
      go,
    };
  }
}
</script>

<style scoped>
  form{
    margin-left:12px;
    margin-right:12px; 
  }
  form>div+div{
    margin-top: 20px;
  }
  .border{
    border-bottom: 1px solid #EBEDF5;
  }
  input{
    border:none;
    /* border-bottom: 1px solid #EBEDF5; */
    width: 100%;
    font-size:16px;
    line-height: 2; 
    margin-top:10px;
  }
  :deep(.van-cell){
    padding-left:0;
    padding-right:0;
    font-size: 16px;
  }
  :deep(.van-uploader__preview){
    display: none;
  }
</style>
