<template>
  <div>
    <Head>
      <template #left><div>
        <i class="in_block icon-xiangxia trans90" @click="go(-1)"></i>
        流程管理
      </div></template>
    </Head>
    <!-- 进度条 -->
    <!-- <div class="progress flex">
      <span class="f14">进度</span>
      <van-progress :percentage="percentage" stroke-width="12" track-color="#F5F6FA"
        color="linear-gradient(90deg, #3061F2 1%, #AAA0FA 100%)" pivot-text="√" />
      <span><span class="red">{{percentage}}</span>/100</span>
    </div> -->
    <form action="" ref="formScreening">
      <div class="input pr">
        <van-field v-model="keyword" clearable placeholder="请输入流程图名称" @keyup.enter="search"/>
        <!-- <input v-model="keyword" clearable placeholder="请输入流程图名称" @keyup.enter="search" /> -->
        <i class="pab icon-icon-" @click="search"></i>
      </div>
      <div class="btns mt-10">
        <button type="button" @click="changeNave(0)">我的流程图({{flowList.length}})</button>
        <!-- <button type="button" @click="changeNave(3)"><i class="icon-shangchuan"></i>上传流程图</button> -->
        <button type="button" @click="changeNave(1)"><i class="icon-tianjia"></i>自建流程图</button>
      </div>
    </form>
    <!-- 已添加流程图 -->
    <!-- 我的流程图 -->
    <div class="" v-show="current_nav==0">
      <ul v-if="flowList.length">
        <li v-for="(item,index) in flowList" :key="index" :class="item.picked?'on':''">
          <div class="tit line1" @click="spreadFlow(item.id)">{{item.name}}工艺流程图</div>
          <div class="img" v-show="spread_id==item.id">
            <img :src="site+item.path+'&random='+Math.random()" @click="previewImg"/>
            <div class="btn editbtn" v-if="item.is_edit==1" @click="editFlow(item)">编辑</div>
            <div class="btn editbtn" v-else-if="item.is_edit==0 && item.type==0" @click="editSystemFlow(item)">编辑</div>
            <div class="btn delebtn" @click="delFlow(item.id)">删除</div>
          </div>
        </li>
      </ul>
      <van-empty v-else image="error" description="暂无流程信息！" />
    </div>
    <!-- 作废了！上传流程图 -->
    <div v-show="current_nav==3" class="mb-60">
      <form ref="formScreening">
        <div class="flex mt-10 mb-10">
          <div class="flex--c">
            <van-uploader class="red" :after-read="afterRead" >
              <span class="btn">点击上传</span>
            </van-uploader>
          </div>
        </div>
      </form>
      <ul v-if="flowImgList.length>0">
        <li v-for="(item, index) in flowImgList" :key="index" class="flex">
          <div class="tit line1">
            <img :src="site+item.path" :preview-src-list="[site+item.path]" lazy @click="previewImg"/>
          </div>
          <div class="num line1">
            <input v-model="item.name"/>
          </div>
          <span class="btn" @click="delFlow(index)">删除</span>
        </li>
      </ul>
      <van-empty v-else image="error" description="暂无上传流程信息！" />
    </div>
    <!-- 自建流程图 -->
    <form class="zjlct mb-60" v-show="current_nav==1" ref="formScreening">
      <div class="input mt-10">
        <span class="f14">产品名称：</span><input v-model="name" placeholder="请输入产品名称"/> 
      </div>
      <div class="lists1" ref="multipleTable">
        <div class="item" v-for="(item, index) in flowInfo" :key="item.id">
          <div class="flex">
            <span>步骤</span>
            <div>{{index+1}}</div>
          </div>
          <div class="flex">
            <span>流程名称</span>
            <div><input type="text" v-model="item.name" placeholder="请输入流程名称"/></div>
          </div>
          <div class="flex">
            <span>流程内容</span>
            <div><input type="text" v-model="item.content" placeholder="请输入流程内容"/></div>
          </div>
          <div class="flex">
            <span>是否外包</span>
            <div><input type="checkbox" v-model="item.is_out"/>是</div>
          </div>
          <div class="flex">
            <span>是否特殊</span>
            <div><input type="checkbox" v-model="item.is_special"/>是</div>
          </div>
          <div class="flex">
            <span>是否关键</span>
            <div><input type="checkbox" v-model="item.is_key"/>是</div>
          </div>
          <div class="flex">
            <span>需确认</span>
            <div><input type="checkbox" v-model="item.is_affirm"/>是</div>
          </div>
          <div class="flex">
            <span>需要时</span>
            <div><input type="checkbox" v-model="item.is_need"/>是</div>
          </div>
          <div class="flex">
            <span>操作</span>
            <div>
              <i class="icon-xiangshang" @click="moveUp(index)"></i>
              <i class="icon-xiangxia" @click="moveDown(index)"></i>
              <i class="red icon-jianqu" @click="deleteTr(index)"></i>
              <i class="blue icon-tianjia" @click="addTr(index)"></i>
            </div>
          </div>
        </div>
      </div>
    </form>
    <!-- 搜索页面 -->
    <div v-show="current_nav==2" class="">
      <div  class="prudence f14">为您搜索到 <span class="red">{{count}}条</span> 相关信息</div>
      <ul v-if="listAll.length">
        <van-list v-model:loading="loading" :finished="finished" finished-text="没有更多了" :immediate-check="false" offset="50" @load="onLoad">
          <li v-for="(item, index) in listAll" :key="index" :class="item.picked?'on':''">
            <div class="tit line1" @click="spreadFlow(item.id)">{{item.name}}</div>
            <div class="img pr" v-show="spread_id==item.id">
              <img :src="site+item.path" />
              <div v-if="chooseIds.indexOf(item.id.toString())==-1" class="btn pab" @click="chooseFlow(item.id)">使用</div>
              <div v-else class="btn pab red" >已使用</div>
            </div>
          </li>
        </van-list>
      </ul>
      <van-empty v-else image="error" description="暂无清单！" />
    </div>
    <!-- 编辑 我的流程图 -->
    <form class="zjlct mt-10 mb-60" v-show="current_nav==4">
      <div class="input">
        <span class="f14">产品名称：</span><input v-model="name" readonly /> 
      </div>
      <div class="lists1">
        <div class="item" v-for="(item, index) in flowInfo" :key="item.id">
          <div class="flex">
            <span>步骤</span>
            <div>{{index+1}}</div>
          </div>
          <div class="flex">
            <span>流程名称</span>
            <div>{{ item.name }}</div>
          </div>
          <div class="flex">
            <span>流程内容</span>
            <div>{{ item.content }}</div>
          </div>
          <div class="flex">
            <span>是否外包</span>
            <div><input type="checkbox" v-model="item.is_out"/>是</div>
          </div>
          <div class="flex">
            <span>是否特殊</span>
            <div><input type="checkbox" v-model="item.is_special"/>是</div>
          </div>
          <div class="flex">
            <span>是否关键</span>
            <div><input type="checkbox" v-model="item.is_key"/>是</div>
          </div>
          <div class="flex">
            <span>需确认</span>
            <div><input type="checkbox" v-model="item.is_affirm"/>是</div>
          </div>
          <div class="flex">
            <span>需要时</span>
            <div><input type="checkbox" v-model="item.is_need"/>是</div>
          </div>
        </div>
      </div>
    </form>
    <!-- 固定在底部的内容 -->
    <div class="fixed">
      <div v-if="current_nav==0" class="f14 red">根据企业产品进行工艺流程图的编写，可以输入产品名称直接选择适合的流程图，也可以自行上传流程图片，如以上都不满足，可以根据产品自行建立符合的生产流程。</div>
      <button v-if="current_nav != 0" class="fr" @click="back">返回</button>
      <button v-if="current_nav==1" class="fr" @click="saveFlow">保存</button>
      <button v-if="current_nav==3" class="fr" @click="saveFlowList">保存</button>
      <button v-if="current_nav==4" class="fr" @click="saveSystemFlow">保存</button>
    </div>

    <!-- 图片点击放大 -->
    <Popup :isShow="isShow" :src="src" @closePopup="close"></Popup>
  </div>
</template>

<script>
import { ref, reactive, toRefs, onMounted, getCurrentInstance } from 'vue'
import { useRouter } from 'vue-router'
import axios from 'axios'
import Head from 'components/common/Head.vue'
import Popup from 'components/common/Popup.vue'
export default {
  name: 'Flow',
  components: {
    Head,
    Popup
  },
  props: {},
  setup (props, context) {
    const {proxy} = getCurrentInstance()
    const Api = proxy.$commonApi
    const router = useRouter()

    let project_id = Api.sessionGet('project_id')
    let _this = reactive({
      action:Api.UPLOAD_URL,
      site:Api.WEB_SITE,
      current_nav:0,
      keyword:'',
      pageSize:10,
      count:0,
      current_page:1,
      currentPage:1,
      flowList:[],//我的流程图 已添加流程图
      flowImgList:[],//上传流程图 上传的流程列表
      searchList:[],//搜索后展示的列表
      flowInfo:[   //自建流程图  或者 编辑我的流程图
        {name:'',content:''},
      ],
      name:'',
      flow_id:0,
      spread_id:0,
      showClassify:true,
      chooseIds:[],
    })
    let listAll = ref([]) // 列表数据
    let loading = ref(false) // 是否正在加载
    let finished = ref(false)  // 是否已经全部加载完
    let isShow = ref(false)  // 弹出层是否显示
    let src = ref('') // 弹出层图片地址

    onMounted(()=>{
      getFlowList()
    })
    //获取公共头部
    const getHeader = ()=>{
      let headers = proxy.$commonApi.getHeader();
      let header = {}
      header.USERKEY = headers.userKey
      header.USERTOKEN = headers.token
      return header
    }
    //获取工艺流程图
    const getFlowList = ()=>{
      proxy.$post('project/getFlowList',{
        project_id:project_id
      }).then(res=>{
        console.log(res)
        let list = res||[]
        let flowImgList = []
        list.forEach((v,k)=>{
          if(v.type==1)flowImgList.push(v)
          if(k==0)_this.spread_id = v.id
        })
        _this.flowImgList = flowImgList
        _this.chooseIds = Api.array_column(list,'flow_id')
        // console.log(_this.chooseIds)
        _this.flowList = res
        console.log(_this.flowList)
        if (_this.flowList.length>0){
          _this.current_nav = 0;
        }else {
          _this.current_nav = 2;
          _this.current_page = 1;
          getSearchList();
        }
      });
      resetPercent();
    }
    //编辑流程
    const editFlow = (item)=>{
      // console.log(item)
      if(item.type==1){
        _this.current_nav = 3
      }else{
        editSystemFlow(item)
      }
    }
    // 编辑系统流程图
    const editSystemFlow = (item) => {
      console.log(item)
      _this.flow_id = item.id
      let config = item.flow_config
      config.forEach((v,k)=>{
        if(v.is_out==1){ config[k].is_out = true }
        else{ config[k].is_out = false }

        if(v.is_key==1){ config[k].is_key = true }
        else{ config[k].is_key = false }

        if(v.is_special==1){ config[k].is_special = true }
        else{ config[k].is_special = false }

        if(v.is_affirm==1){ config[k].is_affirm = true }
        else{ config[k].is_affirm = false }

        if(v.is_need==1){ config[k].is_need = true }
        else{ config[k].is_need = false }
      })
      _this.flowInfo = config
      _this.name = item.name
      _this.current_nav = 4
    }
    //删除流程
    const delFlow = (id)=>{
      Api.confirm('您确定要删除流程图吗？',()=>{
        proxy.$post('project/delFlow',{id:id})
        .then(res=>{
          Api.msg(res.msg,0,'',()=>{
            if(res.code==0){
              getFlowList()
            }
          })
        });
      })
    }
    //搜索
    const getSearchList = ()=>{
      proxy.$post('flow/list',{
        page: _this.current_page,
        keyword: _this.keyword
      }).then(res=>{
        console.log(res)
        loading.value = false
        // _this.searchList = []
        let list = res.data
        list.forEach((v,k)=>{
          if(k==0) _this.spread_id = v.id
        })
        // _this.searchList = list
        listAll.value = listAll.value.concat(res.data)
        _this.pageSize = res.pageSize
        _this.count = res.count
        _this.current_page = res.current_page
        if(res.count < listAll.value.length){
          finished.value = true
        }
        console.log(_this)
      })
    }
    const search = ()=>{
      _this.current_nav = 2
      _this.current_page = 1
      listAll.value = []
      finished.value = false
      getSearchList()
    }
    //收起/展开流程图
    const spreadFlow = (id)=>{
      console.log(id)
      console.log(_this.spread_id)
      if(_this.spread_id==id){
        _this.spread_id = 0
      }else{
        _this.spread_id = id
      }
    }
    //选择流程图
    const chooseFlow = (id)=>{
      proxy.$post('product/chooseFlow',{id:id}).then(res=>{
        Api.msg(res.msg)
        if(res.code==0){
          getFlowList()
        }
      });
    }
    //返回首页
    const back = ()=>{
      _this.current_nav = 0
      getFlowList()
    }
    // 添加流程
    // 上移
    const moveUp = (index)=>{
      if(index===0){
        return
      } 
      let list = _this.flowInfo
      list.splice(index - 1, 1, ...list.splice(index, 1, list[index - 1]));
      _this.flowInfo = list
    }
    // 下移
    const moveDown = (index)=>{
      let list = _this.flowInfo
      if(index===list.length){
        return
      }
      list.splice(index, 1, ...list.splice(index + 1, 1, list[index]));
      _this.flowInfo = list
    }
    //删除
    const deleteTr = (index)=>{
      _this.flowInfo.splice(index, 1)
    }
    //添加
    const addTr = (index)=>{
      var newTr = {name:'',content:''}
      _this.flowInfo.splice(index+1, 0, newTr)
    }
    //保存流程图
    const saveFlow = ()=>{
      let flowInfo = _this.flowInfo
      flowInfo.forEach((v,k)=>{
        console.log(v, k)
        if(v.is_out=='true'||v.is_out===true){flowInfo[k].is_out = 1}
        else{flowInfo[k].is_out = 0}

        if(v.is_affirm=='true'||v.is_affirm===true){flowInfo[k].is_affirm = 1}
        else{flowInfo[k].is_affirm = 0}

        if(v.is_key=='true'||v.is_key===true){flowInfo[k].is_key = 1}
        else{flowInfo[k].is_key = 0}

        if(v.is_need=='true'||v.is_need===true){flowInfo[k].is_need = 1}
        else{flowInfo[k].is_need = 0}

        if(v.is_special=='true'||v.is_special===true){flowInfo[k].is_special = 1}
        else{flowInfo[k].is_special = 0}
      })
      console.log(flowInfo)
      let data = {}
      data.name = _this.name
      data.config = flowInfo
      data.flow_id = _this.flow_id
      if(_this.name==''){
        Api.msg('请填写产品名称！')
        return false
      }
      let isEmpty = false
      flowInfo.forEach(v=>{
        if(v.name=='')isEmpty = true
      })
      if(isEmpty){
        Api.msg('流程名称不能为空！')
        return false
      }
      proxy.$post('project/saveFlow',data).then(res=>{
        Api.msg(res.msg,0,'',()=>{
          if(res.code==0){
            getFlowList()
            _this.current_nav = 0;
          }
        })
      });
    }
    //保存上传工艺流程图
    const saveFlowList = ()=>{
      let flowImgList = _this.flowImgList
      proxy.$post('project/saveFlowList',{flowList:flowImgList}).then(res=>{
        Api.msg(res.msg,0,'',()=>{
          if(res.code==0){
            getFlowList()
            _this.current_nav = 0
          }
        })
      })
    }
    // 保存系统流程图
    const saveSystemFlow = () => {
      let flowInfo = _this.flowInfo
      flowInfo.forEach((v,k)=>{
        if(v.is_out=='true'||v.is_out===true){
          flowInfo[k].is_out = 1
        }else{
          flowInfo[k].is_out = 0
        }
        if(v.is_key=='true'||v.is_key===true){
          flowInfo[k].is_key = 1
        }else{
          flowInfo[k].is_key = 0
        }
        if(v.is_special=='true'||v.is_special===true){
          flowInfo[k].is_special = 1
        }else{
          flowInfo[k].is_special = 0
        }
        if(v.is_affirm=='true'||v.is_affirm===true){
          flowInfo[k].is_affirm = 1
        }else{
          flowInfo[k].is_affirm = 0
        }
        if(v.is_need=='true'||v.is_need===true){
          flowInfo[k].is_need = 1
        }else{
          flowInfo[k].is_need = 0
        }
      })
      let data = {}
      data.name = _this.name
      data.config = flowInfo
      data.flow_id = _this.flow_id
      proxy.$post('project/saveSystemFlow',data).then(res=>{
        Api.msg(res.msg,0,'',()=>{
          if(res.code==0){
            getFlowList()
            _this.current_nav = 0;
          }
        })
      });
    }
    //切换导航
    const changeNave = (nav)=>{
      if(nav==1&&_this.current_nav!=1){
        _this.flowInfo =  [
          {name:'',content:''},
        ]
      }
      _this.current_nav = nav
    }
    const onLoad = ()=>{
      loading.value = true
      _this.current_page += 1
      if(_this.count % _this.pageSize){
        if(_this.current_page <= _this.count / _this.pageSize + 1){
          setTimeout(()=>{
            getSearchList()
          },0)
        }else{
          loading.value = false
          finished.value = true
        }
      }else{
        if(_this.current_page <= _this.count / _this.pageSize){
          setTimeout(()=>{
            getSearchList()
          },0)
        }else{
          loading.value = false
          finished.value = true
        }
      }
      console.log('第'+_this.current_page+'页', 'loading值：'+loading.value)
    }
    // 查看图片放大
    function previewImg(e){
      isShow.value = true
      src.value = e.target.src
    }
    // 关闭图片放大
    function close(n){
      isShow.value = n
    }
    //更新进度
    const resetPercent = ()=>{
      let data = {}
      data.template_id = -4;
      context.emit("updatePercent", data)
    }
    // 返回上一步
    function go(n){
      router.go(n)
    }
    
    //作废了！图片上传（没有传到服务器）
    const afterRead = (file)=>{
      if(file instanceof Array){
        file.map(v=>{
          v.status = 'uploading'
          v.message = '上传中...'
          submitImgs(v)
        })
      } else {
        file.status = 'uploading'
        file.message = '上传中...'
        submitImgs(file)
      }
    }
    //作废了！提交图片到服务器
    function submitImgs(file){
      let formData = new FormData()
      formData.append('file', file.file)
      axios.post('/index/uploader', formData, getHeader())
      .then(res=>{
        // console.log(res)
        if(res.data.code==0){
          file.status = 'done'
          file.message = '上传成功'
          let item = {id:0,name:'',company_id:0,path:'',project_ids:[]}
          item.path = res.data.data.src
          _this.flowImgList.push(item)
        }else{
          Api.msg(res.msg)
        }
      })
    }

    return {
      ...toRefs(_this),
      listAll,
      loading,
      finished,
      isShow, 
      src, 
      getHeader,
      getFlowList, 
      editFlow, 
      editSystemFlow, 
      delFlow, 
      getSearchList,
      search,
      spreadFlow,
      chooseFlow,
      back,
      moveUp,
      moveDown,
      deleteTr,
      addTr,
      saveFlow, 
      saveFlowList, 
      saveSystemFlow, 
      changeNave,
      onLoad,
      previewImg,
      close, 
      resetPercent, 
      go,
      
      afterRead, 
      submitImgs, 
    };
  }
}
</script>

<style scoped>
  form{
    margin-left:12px;
    margin-right:12px;
    padding-bottom:0;
    min-height:auto;
  }
  :deep(.van-cell){
    padding:0;
    line-height:36px;
    width:calc(100% - 50px);
    background: #f5f6fa;
  }
  input{
    background:#f5f6fa;
    height: 30px;
    line-height: 30px;
    border:none; 
    padding:0 15px;
    border-radius:4px; 
  }
  :deep(.van-field__control){
    font-size:16px;
    padding:0 15px;
    box-sizing: border-box;
    border-radius:4px; 
  }
  i.pab{
    right:0;
    top: 0;
    height: 36px;
    line-height:36px;
    width:50px;
    font-size: 22px;
    background:var(--color-text-blue);
    color:#fff;
    text-align: center;
    display: inline-block;
    border-radius:0 5px 5px 0;
  }
  .btns{
    display: flex;
    gap:10px;
  }
  .btns button{
    background: var(--color-text-blue);
    color: #fff;
    border:none;
    font-size: 14px;
    border-radius:4px;
    height:30px;
    line-height:30px;
    padding:0 10px;
  }
  .mb-60{
    margin-bottom: 60px;
  }
  /* 外部流程图 搜索页面 */
  form+div{
    margin:10px 12px 60px;
  }
  .prudence{
    background: #E1EDFA;
    border: 1px solid #CFD2E6;
    border-radius: 4px;
    height: 36px;
    line-height: 36px;
    padding: 0 15px;
    margin: 15px 0;
  }
  li{
    margin:2px 0;
  }
  .tit{
    background:#f5f6fa; 
    height:40px;
    line-height:40px;
    width: 100%;
    border-radius:4px;
    padding:0 6px;
    box-sizing: border-box;
  }
  .img{
    background:#fff;
    width: 100%;
    position: relative;
  }
  .img img{
    max-width:100%;
  }
  .btn.pab{
    right:10px;
    top: 0;
  }
  .delebtn, 
  .btn.red {
    background:#f00;
  }
  /* 我的流程图 */
  .btn+.btn{
    margin-left:10px;
  }
  /* 自建流程图 */
  .lists1 .item{
    box-shadow:0px 0px 4px 0px rgba(1,27,70,0.13);
    margin:12px 0;
    padding:10px;
    border-radius:5px; 
  }
  .item .flex+.flex{
    margin-top:8px;
  }
  .item .flex>span{ 
    font-size:14px;
    color:#777;
    width:5em;
    line-height: 30px;
  }
  .item .flex>div{
    width:calc(100% - 60px);
    line-height: 30px;
  }
  .item input[type=checkbox]{
    width:auto;
    vertical-align: middle;
    margin-right:5px;
  }
  .item i{
    margin-right:15px;
  }
  /* 固定在底部 */
  .fixed{
    bottom: 0;
    width:100%;
    height:50px;
    display: flex;
    justify-content: space-around;
    align-items: center;
    overflow: hidden;
    box-shadow: 0px 0px 10px 0px rgba(1,27,70,0.13);
    background: #fff;
    padding:0 10px;
    margin:0;
    box-sizing: border-box;
  }
  .fixed button{
    font-size: 14px;
    background: linear-gradient(90deg, #3061F2 0%, #3061F2 0%, #AAA0FA 100%);
    color:#fff;
    padding:0 20px;
    height:2em;
    line-height: 2;
    border:0;
    border-radius:50px;
    width:4em;
    box-sizing:content-box;
  }
</style>
