<template>
  <div class="point pr">
    <div class="f12 red" :class="text==='展开'?'line1':''">{{ message }}</div>
    <div class="f12 blue pab" @click="showAllPoint">{{ text }}</div>
  </div>
</template>

<script>
import {ref} from 'vue'
export default {
  name: 'Point',
  props: {
    message: String
  },
  setup () {
    let text = ref('展开')

    function showAllPoint(){
      if(text.value === '展开'){
        text.value = '收起'
      } else {
        text.value = '展开'
      }
    }

    return {
      text,
      showAllPoint, 
    }
  }
}
</script>

<style scoped>
 .pab{
    right: 0;
    bottom: 0;
  }
</style>