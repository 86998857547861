<template>
	<Tab>
    <!-- 传递过来的list -->
    <!-- <TabItem v-for="item in list" :key="item.id" :path="item.link">
      <template #icon><i :class="item.icon"></i></template>
      <template #text><div>{{ item.text }}</div></template>
    </TabItem> -->
    <!-- 写死的list -->
    <TabItem v-for="(item,index) in tabList" :key="index" :path="item.link">
      <template #icon><i :class="item.icon"></i></template>
      <template #text><div>{{ item.text }}</div></template>
    </TabItem>
  </Tab>
</template>

<script>
import { ref } from 'vue'
import Tab from 'components/common/Tab'
import TabItem from 'components/common/TabItem'
	
export default {
  name: 'MainTabBar',
  // props:{
  //   list:{
  //     type:Array
  //   }
  // },
  components:{
    Tab,
    TabItem
  },
  setup(){
    let tabList = ref([
      {id:1, link:'/home', icon:'icon-zhuye', text:'首页'},
      {id:2, link:'/message', icon:'icon-tubiao313', text:'消息'},
      {id:0, link:'/change', icon:'icon-qiehuan1', text:'切换'},
      {id:3, link:'/contact', icon:'icon-dianhua', text:'联系'},
      {id:4, link:'/user', icon:'icon-a-1', text:'我的'}
    ])

    return{
      tabList,
    }
  }
}
</script>

<style scoped>
	.tabItem i{ 
		font-size:24px;
		margin-top:2px;
		vertical-align:middle;
    display: inline-block;
    color:#999;
	}
  .tabItem:nth-child(3){
    color: var(--color-text-blue);
    margin-top:-21px;
  }
  .tabItem:nth-child(3) i{
    font-size:34px;
    background:linear-gradient(to top, #3061F2, #8AEBF1);
    color:#fff;
    border-radius:50%;
    padding:5px;
  }
  .on i, .on div{
    color:var(--color-text-blue);
  }
</style>