<template>
  <div>
    <Head>
      <template v-slot:left><div>
        <i class="in_block icon-xiangxia trans90" @click="go(-1)"></i>
        供方管理
      </div></template>
    </Head>
    <span class="btn" @click="addSupplier">添加供方信息</span>
    <div class="mt-10">
      <template v-if="supplier_list.length">
        <div class="item" v-for="item in supplier_list" :key="item.id">
          <div class="flex"><span>供方名称</span><div>{{item.supplier_name}}</div></div>
          <div class="flex" :class="item.show?'h_auto':''">
            <span>产品信息</span>
            <div>
              <span class="blue" @click="showHideMSg(item)">查看详情</span>
              <div v-show="item.show">
                <div class="citem" v-for="ci in item.product_list" :key="ci.product_model">
                  <div class="flex">
                    <span>产品名称</span>
                    <div>{{ci.product_name}}</div>
                  </div>
                  <div class="flex">
                    <span>规格型号</span>
                    <div>{{ci.product_model}}</div>
                  </div>
                  <div class="flex">
                    <span>数量</span>
                    <div>{{ci.product_num}}</div>
                  </div>
                </div>
              </div>
            </div>
          </div>
          <div class="flex"><span>供货日期</span><div>{{item.supplier_date}}</div></div>
          <div class="flex"><span>负责人</span><div>{{item.supplier_manager}}</div></div>
          <div class="flex">
            <span>操作</span>
            <div class="flex_wrap">
              <span @click="uploadReport(item)">上传检测报告</span>
              <span @click="editSupplier(item)">编辑</span>
              <span class="red" @click="handleDelete(item)">删除</span>
            </div>
          </div>
        </div>
      </template>
      <van-empty v-else description="暂无数据！" />
    </div>
    <!-- 弹出框 上传监测报告 -->
    <Cfm :is-show="isShow" @close="close">
      <template v-slot:title>检测报告</template>
      <template v-slot:content>
        <div class="ml-15 mr-15">
          <van-uploader class="mb-10" v-model="fileList2" :before-read="beforeRead" :after-read="afterRead"
            accept="application/msword,application/vnd.openxmlformats-officedocument.wordprocessingml.document,application/pdf,image/jpeg,image/png">
            <span class="btn">上传报告</span>
          </van-uploader>
          <ul class="list">
            <template v-if="report_list.length">
            <li class="flex-b-c" v-for="item in report_list" :key="item.name">
              <span>{{item.name}}</span>
              <i class="red icon-lajitong" @click="delProductReport(item)"></i>
            </li>
            </template>
            <van-empty v-else description="暂无数据！" />
          </ul>
        </div>
      </template>
    </Cfm>
  </div>
</template>

<script>
import { ref, reactive, toRefs, getCurrentInstance, onMounted } from 'vue'
import {useRouter} from 'vue-router'
import { Toast } from 'vant'
import store from '@/store/index.js'
import axios from 'axios'
import Head from 'components/common/Head.vue'
import Cfm from 'components/common/Cfm.vue'

export default {
  name: 'Supplier',
  components: {
    Head,
    Cfm,
  },
  setup(props, context){
    const {proxy} = getCurrentInstance();
    const Api = proxy.$commonApi
    const router = useRouter()

    var project_id = Api.sessionGet('project_id')
    let _this = reactive({
      project_id: project_id,
      supplier_list: [],
      // report_list: [],
    })
    let isShow = ref(false)  //弹出框是否显示
    let option = ref({})  //弹出框显示的是谁的信息,要用它拿supplier_id的值
    let report_list = ref([])  //临时存放supplier_list中某项的report_list,所以getProductReport取不到值的时候，要重置

    onMounted(()=>{
      getSupplierList()
    })
    //获取供方信息
    const getSupplierList = ()=>{
      proxy.$post('/supplier/index').then(res=>{
        console.log(res)
        if (res.code == 0) {
          _this.supplier_list = res.data
          _this.supplier_list.map(v=>{
            return v.show = false
          })
          // console.log(_this.supplier_list)
        }else {
          Api.msg(res.msg);
        }
      })
    }
    // 添加供方信息 
    const addSupplier = () => {
      store.state.item = {product_list:[{product_num:0}]}
      router.push('/home/supplier/detail')
    }
    // 显示/隐藏详情
    const showHideMSg = (row) => {
      if(row.show){
        row.show = false
      }else{
        _this.supplier_list.map(x=>{
          return x.show = false
        })
        row.show = true
      }
    }
    // 点击 上传检测报告，弹出框显示
    const uploadReport = (row) => {
      isShow.value = true
      option.value = row
      getProductReport(row)
    }
    const getProductReport = (row) => {
      proxy.$post('/supplier/getProductReport',{
        supplier_id:row.supplier_id
      }).then(res=>{
        console.log(res)
        if (res.code == 0) {
          report_list.value = res.data
          console.log(report_list.value)
        }else{
          report_list.value = []
        }
      })
    }
    // 关闭弹出框
    const close = (flag) => {
      isShow.value = flag
    }
    // 上传前检查格式和大小
    function beforeRead(file){
      const allowType = 'msword,vnd.openxmlformats-officedocument.wordprocessingml.document,pdf,png,jpeg'
      const type = file.type.split("/")[1]
      // 上传的只能是 doc,docx,pdf,jpeg,jpg,png 格式!
      if(allowType.indexOf(type) == -1){
        Toast.fail('文件格式错误！');
        return false;
      }else if(file.size > 2 * 1024 * 1024){
        Toast.fail('上传文件大小不能超过 2MB!');
        return false;
      }else{
        return file;
      }
    }
    // 文件上传（没有传到服务器）
    function afterRead(file){
      file.status = 'uploading'
      file.message = '上传中...'
      submitFile(file)
    }
    // 提交文件到服务器
    function submitFile(file){
      let formData = new FormData()
      formData.append('file', file.file)
      formData.append('upload_type', 'product_report')
      formData.append('supplier_id', option.value.supplier_id)
      axios.post('/supplier/importSupplierContract',formData).then(res=>{
        console.log(res)
        if (res.data.code==0) {
          file.status = 'done'
          file.message = '上传成功'
          getProductReport(option.value)
          Api.msg('上传成功')
        }else{
          Api.msg(res.data.msg)
        }
      })
    }
    // 删除检测报告
    function delProductReport(item){
      proxy.$post('/supplier/delProductReport',{
        supplier_id: option.value.supplier_id,
        product_report: item.name
      }).then(response=>{
        console.log(response)
        if(response.code==0||response.code==200){
          getProductReport(option.value)
        }
        Api.msg(response.msg)
      })
    }
    // 编辑供方信息
    const editSupplier = (row) => {
      store.state.item = row
      router.push('/home/supplier/detail')
    }
    // 删除供方信息
    const handleDelete = (row) => {
      Api.confirm('是否删除此供方信息?',()=>{
        proxy.$post('supplier/delSupplier',{
          supplier_id:row.supplier_id,
        }).then((res)=>{
          if(res.code==0||res.code==200){
            getSupplierList();
            resetPercent();
          }
          Api.msg(res.msg);
        });
      });
    }
    //更新进度
    const resetPercent = () => {
      let data = {}
      data.template_id = -8;
      context.emit("updatePercent", data)
    }
    function go(n){
      router.go(n)
    }
    return {
      ...toRefs(_this), 
      isShow, 
      report_list, 
      option, 
      addSupplier, 
      showHideMSg, 
      uploadReport, 
      close, 
      beforeRead, 
      afterRead, 
      submitFile, 
      delProductReport, 
      editSupplier, 
      handleDelete, 
      resetPercent, 
      go, 
    };
  }
}
</script>

<style scoped>
  .head+span{
    margin-left: 12px;
  }
  .item{
    box-shadow: 0px 0px 4px rgba(1,27,70,0.05);
    margin: 10px;
    padding: 10px;
    border-radius:10px;
  }
  .item .flex{
    height:30px;
    line-height: 30px;
  }
  .h_auto{
    height:auto !important;
  }
  .flex>span{
    width:5em;
    font-size: 14px;
    color:#999;
  }
  .flex span+div{
    width:calc(100% - 60px);
    font-size: 14px;
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;
  }
  .h_auto>div>div{
    width: 100%;
  }
  .citem{
    background: #f3f4fa;
    border-radius:5px;
    padding:10px;
    margin-bottom: 8px;
  }
  .flex_wrap{
    gap:15px;
  }
  .flex_wrap span{
    color: #3061F2;
  }
  span.red{
    color:#f00;
  }
  :deep(.van-uploader__preview){
    display: none;
  }
  li{
    background: #f3f4fa;
    padding:10px;
    margin-top:3px;
    border-radius:5px;
  }
</style>
