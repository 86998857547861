<template>
  <div class="upload pr">
    <!-- :max-size="5 * 1024 " @oversize="onOversize"  -->
    <van-uploader v-model="fileList" multiple 
      
      :before-read="beforeRead" 
      :after-read="afterRead" 
      >
      <span class="btn">选择{{item.title}}图片</span>
    </van-uploader>
    <span class="pab btn" @click="uploadImgs">提交{{item.title}}图片</span>
    <div class="flex_wrap">
      <div class="item" v-for="(it, k) in item.list" :key="k">
        <img :src="site+it.file" 
          @click="previewImg(site+it.file)" />
        <div class="flex">
          <input type="text" v-model="it.title" />
          <i class="icon-yixianshi-"></i>
        </div>
        <i class="icon-guanbi red pab" @click="deleteImg(index,k)"></i>
      </div>
      --分割线 前边的是item.list 后边的是fileList--
      <div class="item" v-for="(it, k) in fileList" :key="k">
        <img :src="it.content" 
          @click="previewImg(it.content)" />
        <div class="flex">
          <input type="text" v-model="it.file.newName" />
          <i class="icon-yixianshi-"></i>
        </div>
        <i class="icon-guanbi red pab" @click="deletePrevImg(k)"></i>
      </div>
      <!-- 营业执照图片放大 -->
      <!-- <Popup :isShow="isShow" :src="src" @closePopup="close"></Popup> -->
    </div>
  </div>
</template>

<script>
import { ref, reactive, toRefs, getCurrentInstance } from 'vue'
import { Toast } from 'vant'
import axios from 'axios'
// import Popup from 'components/common/Popup'
export default {
  name: 'UploadImgs',
  components: {
    // Popup
  },
  props: {
    list:{
      type:Array,
      default(){
        return []
      }
    },
    item:{
      type:Object
    },
    index:{
      type:Number
    }
  },
  setup (props, context) {
    const {proxy} = getCurrentInstance()
    const Api = proxy.$commonApi

    let headers = Api.getHeader()
    const defaultData = reactive({
      headers:{
        USERKEY: headers.userKey,
        USERTOKEN: headers.token,
      },
      site:Api.WEB_SITE,
      list:props.list,
    })
    let fileList = ref([])
    var totalSize = 0  //所有图片的总大小，包括还未上传到服务气的图片, ???和已经上传到服务器的图片

    // for(var n=0; n<props.item.list.length; n++){
    //   console.log(props.item.list[n])
    // }

    // 图片上传前的验证
    function beforeRead(file){
      debugger
      console.log(file)
      var reg = /image\/(jpeg|jpg|png|gif)$/
      if(file instanceof Array){
        for(var i=0; i<file.length; i++){
          totalSize += file[i].size
          console.log(totalSize)
          if(totalSize>5*1024*1024){
            Toast.fail("大小不能超过5MB")
            totalSize -= file[i].size
            console.log(totalSize)
            return false
          }
          if(!reg.test(file[i].type)){
            Toast.fail("请上传jpg、png、gif格式的图片！")
            return false
          }
        }
        // file.forEach(v=>{
        //   totalSize += v.size
        //   console.log(totalSize)
        //   if(totalSize>5*1024){
        //     Toast.fail("大小不能超过5MB")
        //     return false
        //   }
        //   if(!reg.test(v.type)){
        //     Toast.fail("请上传jpg、png、gif格式的图片！")
        //     return false
        //   }
        // })
      }else{
        totalSize += file.size
        console.log(totalSize)
        if(totalSize>5*1024*1024){
          Toast.fail("大小不能超过5MB")
          totalSize -= file.size
          console.log(totalSize)
          return false
        }
        if(!reg.test(file.type)){
          Toast.fail("请上传jpg、png、gif格式的图片！")
          return false
        }
      }
      return true 
    }
    // 图片上传（没有传到服务器）
    function afterRead(file){
      debugger
      if(file instanceof Array){
        file.map(v=>{
          // v.status = 'uploading'
          // v.message = '上传中...'
          v.file.newName = v.file.name
          // submitImgs(v)
        })
      } else {
        // file.status = 'uploading'
        // file.message = '上传中...'
        file.file.newName = file.file.name
        // submitImgs(file)
      }
      console.log(fileList.value)
    }
    function uploadImgs(){
      if(fileList.value.length==0){
        Toast("请先选择图片再提交！")
      }else{
        fileList.value.forEach(v=>{
          submitImgs(v)
        })
      }
    }
    // 提交图片到服务器
    function submitImgs(file){
      debugger
      let formData = new FormData()
      formData.append('file', file.file)
      var result = axios.post('/index/uploader', formData, defaultData.headers)
      .then(res=>{
        console.log(res)
        if(res.data.code==0){
          // file.status = 'done'
          // file.message = '上传成功'
          // defaultData.list[0].list.push(res.data.data.src)
          var item = {file:res.data.data.src,  title:res.data.data.title}
          defaultData.list[0].list.push(item)
          //defaultData.list[0].list.push(res.data.data.src)
          // _this.companyInfo.license = res.data.data.src
        }
      })
      console.log(fileList.value)
      console.log(defaultData.list)
      fileList.value = []
      console.log(fileList.value)
      console.log(result)
      context.emit('changeItemList', defaultData.list)
      // return Promise.resolve(result)
    }
    // 压缩图片
    // function compress(img){
    //   let url = ''
    //   var w = Math.min(700, img.width);//当图片像素>700的时候，等比例压缩，这个数字可以调
    //   var h = img.height * (w / img.width);
    //   var canvas = document.createElement('canvas')
    //   var ctx = canvas.getContext('2d')
    //   canvas.width = w
    //   canvas.height = h
    //   ctx.drawImage(img, 0, 0, w, h)
    //   url = canvas.toDataURL('image/png',1)//1代表精细度，越高越好
    //   return url
    // }
    // 将base64转换为blob
    // function dataURLtoBlob(data){
    //   var arr = data.split(',')
    //   var mine = arr[0].match(/:(.*?);/)[1]
    //   var bstr = atob(arr[1])
    //   var n = bstr.length
    //   var u8arr = new Uint8Array(n)
    //   while(n--){
    //     u8arr[n] = bstr.charCodeAt(n)
    //   }
    //   return new Blob([u8arr], {type: mine})
    // }
    // 图片放大
    const previewImg = (url)=>{
      context.emit('changeValue', true, url)
    }
    // 删除图片，已经上传到过 服务器的图片
    function deleteImg(index, k){
      let allList = defaultData.list
      var row = allList[index]
      var path = row.list[k]
      proxy.$post('index/delImg',{
        path:path.file,
      }).then(res=>{
        if(res.code==0){
          row.list.splice(k, 1)
          allList[index] = row
          defaultData.list = allList 
          console.log(defaultData.list)
          context.emit('changeItemList', defaultData.list)
        }
      })
    }
    // 删除图片，还未上传到服务器的图片
    const deletePrevImg = (k)=>{
      totalSize -= fileList.value[k].file.size
      fileList.value.splice(k,1)
      console.log(totalSize)
    }

    return {
      ...toRefs(defaultData), 
      fileList, 
      // isShow, 
      beforeRead, 
      afterRead, 
      uploadImgs, 
      submitImgs, 
      previewImg, 
      deleteImg, 
      deletePrevImg, 
    }
  }
}
</script>

<style scoped>
   /* 上传资质 */
  :deep(.van-uploader__wrapper){
    position: relative;
    padding-top:50px;
    gap:10px;
  }
  :deep(.van-uploader__input-wrapper){
    position: absolute;
    top:10px;
  }
  .btn{
    height:34px;
    line-height: 34px;
    white-space:nowrap;
    font-size: 16px;
    background: linear-gradient(90deg,#3061f2 0%, #aaa0fa 100%);
  }
  .btn.pab{
    top:10px;
    left:160px; 
  }
  :deep(.van-uploader__preview){
    display: none;
  }
  :deep(.van-uploader__preview-image){
    width:150px;
    height:150px;
    border: 1px solid #d9d9d9;
    border-radius: 4px;
    box-sizing: border-box;
  }
  :deep(.van-image__img){
    object-fit:contain !important;
    height:calc(100% - 32px);
  }
  :deep(.van-uploader__preview-cover){
    top: auto;
    display: flex;
    align-items: center;
    background: #f5f5f5;
    border-radius:0 0 4px 4px;
    padding:2px 0;
  }
    /* 循环fileList */
  .flex_wrap{
    gap:15px;
  }
  .item{
    width:150px;
    height:150px;
    border:1px solid #d9d9d9;
    background: #fff;
    border-radius: 4px;
    box-sizing: border-box;
    position: relative;
  }
  img{
    width:100%;
    height:calc(100% - 35px);
    object-fit:contain;
  }
  .flex{
    background:#f5f5f5;
    border-radius: 0 0 4px 4px;
    padding: 4px 0;
    align-items:center;
  }
  input{
    width:100%;
    padding:0 10px;
    border:1px solid #dcdfe6;
    border-radius:4px;
    height:25px;
    line-height:25px;
  }
  .icon-yixianshi-{
    margin:0 5px;
  }
  .icon-guanbi{
    top: 0px;
    right: 0px;
    cursor: pointer;
    font-size: 20px;
  }
</style>
