import axios from "axios";
import QS from "qs";
import commonApi from '@/store/commonApi.js'

axios.defaults.baseURL = commonApi.BASE_URL
axios.defaults.timeout = 15000

//允许跨域携带cookie信息
axios.defaults.withCredentials = true; 
axios.defaults.headers['Content-Type'] = 'application/x-www-form-urlencoded'
axios.defaults.headers.post["Content-Type"] = "application/x-www-form-urlencoded;charset=UTF-8"

//请求拦截
axios.interceptors.request.use( 
  async config => {
      // 每次发送请求之前判断vuex中是否存在token        
      // 如果存在，则统一在http请求的header都加上token，这样后台根据token判断你的登录情况
      // 即使本地存在token，也有可能token是过期的，所以在响应拦截器中要对返回状态进行判断 
      // config.headers.authorization = sessionStorage.getItem('Token')
      // let header = commonApi.getHeader()
      // axios.defaults.headers.common['USERKEY'] = header.userKey;
      // axios.defaults.headers.common['USERTOKEN'] = header.token;
      // console.log(header)
      return config;
  },
  error => {
      return Promise.error(error);
  })

  // 响应拦截器
  axios.interceptors.response.use(
    response => {
      if (response.status === 200) {
          return Promise.resolve(response); //进行中        
      } else {
          return Promise.reject(response); //失败       
      }
    },
  // 服务器状态码不是200的情况    
    error => {
      if (error.response.status) {
          switch (error.response.status) {
              // 401: 未登录                
              // 未登录则跳转登录页面，并携带当前页面的路径                
              // 在登录成功后返回当前页面，这一步需要在登录页操作。                
              case 401:
                  break
                  // 403 token过期                
                  // 登录过期对用户进行提示                
                  // 清除本地token和清空vuex中token对象                
                  // 跳转登录页面                
              case 403:
                  sessionStorage.clear()
                  //router.push('/login')
                  break
                  // 404请求不存在                
              case 404:
                  break;
                  // 其他错误，直接抛出错误提示                
              default:
          }
          return Promise.reject(error.response);
      }
  }
);


 /** 
  * get方法，对应get请求 
  * @param {String} url [请求的url地址] 
  * @param {Object} params [请求时携带的参数] 
  */
 const $get = (url, params) =>{
  return new Promise((resolve, reject) => {
      axios.get(url, {
              params: params,
          })
          .then(res => {
              resolve(res.data);
          })
          .catch(err => {
              reject(err.data)
          })
  }).catch(err=>{console.log(err)});
}
/** 
* post方法，对应post请求 
* @param {String} url [请求的url地址] 
* @param {Object} params [请求时携带的参数] 
*/
const $post = (url, params) => {
  return new Promise((resolve, reject) => {
      let header = commonApi.getHeader();
      axios.defaults.headers.common['USERKEY'] = header.userKey;
      axios.defaults.headers.common['USERTOKEN'] = header.token;
      axios.post(url, QS.stringify(params)) //是将对象 序列化成URL的形式，以&进行拼接   
          .then(res => {
              commonApi.storageSet('stamp', res.headers.timestamp, 3600*2)
              resolve(res.data);
          })
          .catch(err => {
              reject(err.data)
          })
  }).catch(err=>{console.log(err)});
}
//下面是vue3必须加的，vue2不需要，只需要暴露出去get，post方法就可以
export default{
  install:(app) => {
  app.config.globalProperties['$get'] = $get;
  app.config.globalProperties['$post'] = $post;
  app.config.globalProperties['$axios'] = axios;
  }
}