<template>
  <div class="bg">
    <Head>
      <template v-slot:left><div>
        <i class="in_block icon-xiangxia trans90" @click="go(-1)"></i>
        企业地址
      </div></template>
    </Head>
    <!-- 进度条 -->
    <!-- <div class="progress flex">
      <span class="f14">进度</span>
      <van-progress :percentage="percentage" stroke-width="12" track-color="#F5F6FA"
        color="linear-gradient(90deg, #3061F2 1%, #AAA0FA 100%)" pivot-text="√" />
      <span><span class="red">{{percentage}}</span>/100</span>
    </div> -->
    <!-- 填写内容 -->
    <form action="">
      <div class="bg_fff">
        <div>
          <div class="f14 color_9">注册地址</div>
          <div class="f12 red">注：请填写营业执照中的地址信息</div>
          <div class="border">
            <Linkage v-if="isHaveNum"
              :first_id="addressInfo.province_id" :second_id="addressInfo.city_id" :third_id="addressInfo.country_id"
              :length="3" flag="regist" :key="0" @changeEvent="changeArea"></Linkage>
            <input type="text" v-model="addressInfo.address" placeholder="请输入详细注册地址" />
          </div>
        </div>
        <div>
          <div class="f14 color_9">办公地址</div>
          <!-- <div class="f12 red">注：实际办公的地址，如与注册地址一致，则进行勾选即可，如不一致，请另行选择填写</div> -->
          <div class="border">
            <Linkage v-if="isHaveNum"
              :first_id="addressInfo.province_id1" :second_id="addressInfo.city_id1" :third_id="addressInfo.country_id1"
              :length="3" flag="office" :key="1" @changeEvent="changeArea"></Linkage>
            <input type="text" v-model="addressInfo.address1" placeholder="请输入详细办公地址" />
            <label for="" class="mt-10"><input type="checkbox" v-model="checked" @click="copyAddress"/> 同步地址</label>
            <i class="iconfont icon-tianjia" @click="addOfficeAddress"></i>
            <div class="f12 red">注：实际办公的地址，如与注册地址一致，则进行勾选即可，如不一致，请另行选择填写</div>
          </div>
        </div>
        <div v-for="(it,index) in addressInfo.office_address_arr" :key="index">
          <div class="f14 color_9">{{ it.title }}
            <!-- 办公地址{{index+1}} -->
            <i class="iconfont icon-lajitong" @click="deleteOfficeAddress(index)"></i>
          </div>
          <div class="border">
            <Linkage v-if="isHaveNum"
              :first_id="it.province_id" :second_id="it.city_id" :third_id="it.country_id"
              :length="3" :flag="index" :key="index" @changeEvent="changeArea"></Linkage>
            <input class="mt-10" type="text" v-model="it.address" placeholder="请输入详细地址">
          </div>
        </div>
        <div>
          <div class="f14 color_9">地址</div>
          <div class="f12 red">注：产品生产所在地，如与注册地、办公地不一致，会形成多场所现象，认证审核过程中，需要对不同场所均进行审核，请确认后谨慎填写。</div>
          <div>
            <span class="btn mr-10 mb-10" @click="addAddress(1)">添加生产地址</span>
            <span class="btn mr-10 mb-10" @click="addAddress(2)">添加销售地址</span>
            <span class="btn mr-10 mb-10" @click="addAddress(3)">添加分公司地址</span>
            <span class="btn mr-10 mb-10" @click="addAddress(4)">添加分支机构地址</span>
            <span class="btn mr-10 mb-10" @click="addAddress(5)">添加连锁店地址</span>
            <span class="btn mr-10 mb-10" @click="addAddress(6)">添加加工厂地址</span>
            <span class="btn mr-10 mb-10" @click="addAddress(7)">添加建筑施工地址</span>
            <span class="btn mr-10 mb-10" @click="addAddress(8)">添加工程勘测地址</span>
            <span class="btn mr-10 mb-10" @click="addAddress(9)">添加建筑监理地址</span>
            <span class="btn mr-10 mb-10" @click="addAddress(10)">添加房地产开发地址</span>
            <span class="btn mr-10 mb-10" @click="addAddress(11)">添加系统集成（安防）地址</span>
          </div>
        </div>
        <div v-for="(it, index) in addressInfo.product_config" :key="index">
          <div class="f14 color_9">
            {{it.title}}{{index+1}}
            <i class="iconfont icon-lajitong" @click="deleAddress(index)"></i>
          </div>
          <div class="border">
            <Linkage v-if="isHaveNum"
              :first_id="it.province_id" :second_id="it.city_id" :third_id="it.country_id"
              :length="3" :flag="index" :key="index" @changeEvent="changeArea"></Linkage>
            <input class="mt-10" type="text" v-model="it.address" placeholder="请输入详细地址">
          </div>
        </div>
      </div>
      <div class="text_center fixed" @click="addressModify">保存</div>
    </form>
  </div>
</template>

<script>
import {ref, toRefs, reactive, getCurrentInstance, onMounted} from 'vue'
import { useRouter } from 'vue-router'
import Head from 'components/common/Head.vue'
import Linkage from 'components/common/Linkage.vue'

export default {
  name: 'Address',
  components: {
    Head,
    Linkage,
  },
  setup (props,context) {
    const {proxy} = getCurrentInstance()
    const Api = proxy.$commonApi
    const router = useRouter()

    //公共数据
    var project_id = Api.sessionGet('project_id');
    let _this = reactive({
      action:Api.UPLOAD_URL,
      site:Api.WEB_SITE,
      checked:false,
      project_id:project_id,
      addressInfo:[{province_id: 0, city_id: 0, country_id: 0, address: ''}],
    });
    let isHaveNum = ref(false)
    onMounted(()=>{
      getAddressList()
    })
    //获取地址信息
    const getAddressList = ()=>{
      let defaut = {
        province_id:0,
        city_id:0,
        country_id:0,
        address:'',
        province_id1:0,
        city_id1:0,
        country_id1:0,
        address1:'',
        office_address_arr: [],
        product_config:[], 
      }
      proxy.$post('company/addressList',{
        company_id:_this.company_id
      }).then(res=>{
        console.log(res)
        _this.addressInfo = res||defaut
        isHaveNum.value = true
      })
    }
    //保存并更新企业信息
    const addressModify = ()=>{
      let addressInfo = _this.addressInfo
      if(!addressInfo.country_id){
        Api.msg('请选择完整的注册地址！');return false;
      }
      if(!addressInfo.address){
        Api.msg('请填写详细的注册地址！');return false;
      }
      if(!addressInfo.country_id1){
        Api.msg('请选择完整的办公地址！');return false;
      }
      if(!addressInfo.address1){
        Api.msg('请填写详细的办公地址！');return false;
      }
      var productAddr = addressInfo.product_config;
      // if(productAddr.length==0){
      //   Api.msg('请添加生产地址！');return false;
      // }
      // var is_complete = true
      // var key = 0;
      // productAddr.forEach((v,k)=>{
      //   if(!v.province_id||!v.city_id||!v.country_id||!v.address){
      //     is_complete = false
      //     key = k+1
      //   }
      // })
      // if(is_complete===false){
      //   Api.msg('请填写完整生产地址'+key+'！');return false;
      // }
      if (productAddr.length > 0) {
        var is_complete = true
        var key = 0;
        productAddr.forEach((v, k) => {
          if (!v.province_id || !v.city_id || !v.country_id || !v.address) {
            is_complete = false
            key = k + 1
          }
        })
        if (is_complete === false) {
          Api.msg('请填写完整' + productAddr[key - 1].title + key + '！');
          return false;
        }
      }
      var officeAddr = addressInfo.office_address_arr;
      if (officeAddr.length > 0) {
        var is_complete_office = true
        var office_key = 0;
        officeAddr.forEach((v, k) => {
          if (!v.province_id || !v.city_id || !v.country_id || !v.address) {
            is_complete_office = false
            office_key = k + 1
          }
        })
        if (is_complete_office === false) {
          Api.msg('请填写完整' + officeAddr[office_key - 1].title + '！');
          return false;
        }
      }
      proxy.$post('company/addressUpdate',addressInfo).then(res=>{
        Api.msg(res.msg,0,'',function(){
          if(res.code==0){
            resetPercent()
          }
        })
      })
    }
    // 添加办公地址
    const addOfficeAddress = () => {
      let item = {office_flag:0, title: '', province_id: 0, city_id: 0, country_id: 0, address: ''}
      var office_address_arr = _this.addressInfo.office_address_arr || []
      item.office_flag = office_address_arr.length;
      item.title = '办公地址' + (office_address_arr.length+1);
      office_address_arr.push(item);
      _this.addressInfo.office_address_arr = office_address_arr
    }
    // 删除办公地址
    const deleteOfficeAddress = (index) => {
      var office_address_arr = _this.addressInfo.office_address_arr || []
      office_address_arr.splice(index, 1);
    }
    // 添加XXX地址
    const addAddress = (params)=>{
      let item = {province_id:0,city_id:0,country_id:0,address:'', product_flag:0, title:'生产地址'}
      var product_config = _this.addressInfo.product_config || []
      item.product_flag = params;
      switch (params){
        case 1:
          item.title = '生产地址';
          break;
        case 2:
          item.title = '销售地址';
          break;
        case 3:
          item.title = '分公司地址';
          break;
        case 4:
          item.title = '分支机构地址';
          break;
        case 5:
          item.title = '连锁店地址';
          break;
        case 6:
          item.title = '加工厂地址';
          break;
        case 7:
          item.title = '建筑施工地址';
          break;
        case 8:
          item.title = '工程勘测地址';
          break;
        case 9:
          item.title = '建筑监理地址';
          break;
        case 10:
          item.title = '房地产开发地址';
          break;
        case 11:
          item.title = '系统集成（安防）地址';
          break;
      }
      product_config.push(item);
      _this.addressInfo.product_config = product_config
    }
    // 删除XXX地址
    function deleAddress(index){
      var product_config = _this.addressInfo.product_config||[]
      product_config.splice(index,1)
    }
    //地区
    //地区选择回调
    const changeArea = (data,flag)=>{
      let addressInfo = _this.addressInfo
      var v1 = data[0]||0
      var v2 = data[1]||0
      var v3 = data[2]||0
      if(flag=='regist'){
        addressInfo.province_id = v1
        addressInfo.city_id = v2
        addressInfo.country_id = v3
      }else if(flag=='office'){
        addressInfo.province_id1 = v1
        addressInfo.city_id1 = v2
        addressInfo.country_id1 = v3
      }else{
        var product_config = _this.addressInfo.product_config||[]
        var row = product_config[flag]||[]
        row.province_id = v1
        row.city_id = v2
        row.country_id = v3
        // product_config[flag] = row
      }
      _this.addressInfo = addressInfo
    }
    // 复选框点击事件
    const copyAddress = ()=>{
      var checked = _this.checked
      var addressInfo = _this.addressInfo
      if(checked==false){
        _this.addressInfo.province_id1 = addressInfo.province_id
        _this.addressInfo.city_id1 = addressInfo.city_id
        _this.addressInfo.country_id1 = addressInfo.country_id
        _this.addressInfo.address1 = addressInfo.address

      }
    }
    //更新进度
    const resetPercent = ()=>{
      let data = {}
      data.template_id = -5;
      context.emit("updatePercent", data)
    }
    function go(n){
      router.go(n)
    }

    return{
      ...toRefs(_this), 
      isHaveNum, 
      addressModify, 
      addOfficeAddress, 
      deleteOfficeAddress, 
      addAddress, 
      deleAddress, 
      changeArea,
      copyAddress,
      resetPercent,
      go,
    }
  }
}
</script>

<style scoped>
  .bg{
    background: #fbfbfb;
  }
  .bg_fff{
    margin:12px;
    padding:12px;
  }
  .bg_fff>div+div{
    margin-top:20px;
  }
  input[type=text]{
    border:1px solid #EBEDF5; 
    border-radius:5px; 
    width:100%;
    font-size: 16px;
    height: 30px;
    line-height: 30px;
    padding: 0 10px;
    box-sizing:border-box;
    margin-top: 10px;
  }
  .fill :deep(.van-cell){
    border-bottom: 1px solid #EBEDF5;
  }
  .icon-tianjia{
    width:32px;
    height: 32px;
    background: #3061f2;
    color:#fff;
    border-radius:50%;
    display: inline-flex;
    justify-content: center;
    align-items: center;
    margin-left:15px;
  }
  .icon-lajitong{
    width: 32px;
    height: 32px;
    background: #f56c6c;
    color: #fff;
    border-radius: 50%;
    display: inline-flex;
    justify-content: center;
    align-items: center;
  }
  .btn{
    min-width:8em; 
    text-align:center;
  }
</style>
