<!-- 企业信息 -->
<template>
  <div class="bg">
    <Head>
      <template v-slot:left><div>
        <i class="in_block icon-xiangxia trans90" @click="go(-1)"></i>
        企业信息
      </div></template>
    </Head>
    <div class="progress flex">
      <span class="f14">进度</span>
      <van-progress :percentage="percentage" stroke-width="12" track-color="#F5F6FA"
      color="linear-gradient(90deg, #3061F2 1%, #AAA0FA 100%)" pivot-text="√" />
      <span><span class="red">{{percentage}}</span>/100</span>
    </div>
    <form action="">
      <div class="bg_fff">
        <div>
          <div class="f14 color_9">企业简称</div>
          <Point :message="pointMsg"></Point>
          <div>
            <input type="text" placeholder="请输入企业简称" />
          </div>
        </div>
        <div>
          <div class="f14 color_9">备注</div>
          <div>
            <!-- <textarea></textarea> -->
            <van-field v-model="form.textarea" rows="3" autosize type="textarea" maxlength="100" placeholder="请输入" show-word-limit />
          </div>
          <Point :message="pointMsg2"></Point>
        </div>
      </div>
      <div class="bg_fff">
        <div>
          <div class="f14 color_9">所属区域</div>
          <div>
            <van-field v-model="form.areaValue" is-link readonly placeholder="省-市" @click="form.show = true" />
            <van-popup v-model:show="form.show" round position="bottom">
              <van-area :area-list="areaList" :columns-num="2" @cancel="form.show = false" @confirm="onFinish"/>
            </van-popup>
          </div>
          <Point :message="pointMsg2"></Point>
        </div>
        <div>
          <div class="f14 color_9">所属行业</div>
          <div>
            <van-field v-model="form.fieldValue2" is-link readonly placeholder="请选择所属行业" @click="form.show2 = true"/>
            <van-popup v-model:show="form.show2" round position="bottom">
              <van-picker :columns="form.industry" @confirm="onConfirm3" @cancel="form.show2 = false" />
            </van-popup>
          </div>
          <Point :message="pointMsg2"></Point>
        </div>
        <div>
          <div class="f14 color_9">企业类型</div>
          <div>
            <van-field v-model="form.result2" is-link readonly placeholder="点击选择企业类型" @click="form.showPicker2 = true" />
            <van-popup v-model:show="form.showPicker2" round position="bottom">
              <van-picker :columns="form.businessType" @confirm="onConfirm2" @cancel="form.showPicker2 = false"/>
            </van-popup>
          </div>
        </div>
        <div>
          <div class="f14 color_9">是否在职能表
            <van-switch class="fr pr" v-model="checked" size="16px" active-color="#3061F2" inactive-color="#dcdee0" ></van-switch>
          </div>
          <Point :message="pointMsg"></Point>
        </div>
        <div>
          <div class="f14 color_9">项目选择</div>
          <div>
            <van-field is-link readonly placeholder="点击选择项目选择" @click="form.showPicker1 = true" />
            <van-popup v-model:show="form.showPicker1" round position="bottom">
              <!-- <van-picker :columns="form.projectList" @confirm="onConfirm2" @cancel="form.showPicker2 = false"/> -->
              <div class="van-picker__toolbar">
                <button type="button" class="van-picker__cancel van-haptics-feedback" @click="cancel">取消</button>
                <button type="button" class="van-picker__confirm van-haptics-feedback" @click="confirm">确认</button>
              </div>
              <div class="flex_wrap">
                <span v-for="(item, index) in form.projectList" :key="index" 
                  @click="pickThis(index)" :class="item.picked?'on':''">{{item.text}}</span>
              </div>
            </van-popup>
            <div class="flex_wrap">
              <span class="line1" v-for="(item, index) in form.result1" :key="index">
                <i class="icon-guanbi blue" @click="deleteThis(item, index)"></i>
                {{item.text}}
              </span>
            </div>
            <Point :message="pointMsg2"></Point>
          </div>
        </div>
      </div>
      <div class="bg_fff">
        <div>
          <div class="f14 color_9">企业资质</div>
          <div class="flex mt-10">
            <van-uploader v-model="fileList" :max-count="1" />
            <div class="f12 color_9">
              <div>1.上传多证合一营业执照，照片或扫描件。</div>
              <div>2.审核完成后不可更改主体，如需更改请联系<span class="blue">咨询工具客服</span></div>
              <div>3.信息需清晰可见，内容真实有效，不得做任何修改。</div>
              <div>4.支持JPG、JPEG、PNG、BMP格式。</div>
            </div>
          </div>
          <div>
            <van-swipe class="my-swipe" :autoplay="3000" indicator-color="white">
              <van-swipe-item>
                <img src="@/assets/img/home/information_1.png" />
                <img src="@/assets/img/home/information_2.png" />
                <img src="@/assets/img/home/information_3.png" />
                <img src="@/assets/img/home/information_2.png" />
              </van-swipe-item>
              <van-swipe-item>2</van-swipe-item>
              <van-swipe-item>3</van-swipe-item>
              <van-swipe-item>4</van-swipe-item>
            </van-swipe>
          </div>
        </div>
      </div>
      <div class="text_center fixed" @click="submit">提交信息</div>
    </form>
  </div>
</template>

<script>
import { ref } from 'vue'
import { useRouter } from 'vue-router'
import { areaList } from '@vant/area-data'
import Head from 'components/common/Head'
import Point from 'components/content/home/Point'
import store from '@/store/index.js'

export default {
  name: 'Information',
  components:{
    Head,
    Point
  },
  setup(){
    const router = useRouter()

    let pointMsg = ref('注：企业名称简写英文字母；如：河北证优科技有限公司，简称为ZYKJ。')
    let pointMsg2 = ref('注：企业名称简写英文字母；如：河北证优科技有限公司，简称为ZYKJ。请选择争取的地区，选择以后就不可以修改了，请谨慎选择，谨慎填写')

    let percentage = ref(30)
    let form = ref({
      enterpriseName: store.state.companyName,
      enterpriseShortName:'',
      textarea:'',
      // 所属区域 省-市
      show: false,
      areaValue:'',
      // 所属行业
      show2: false,
      fieldValue2:'',
      cascaderValue2: '',
      industry: [
        {text:'请选择',value:'0000',children:[
          {text:'', value:'0001'}
        ]},
        {text:'农、牧、林、渔业',value:'0100',children:[
          {text:'请选择', value:'0101'},{text:'农业', value:'0102'},{text:'林业', value:'0103'},
          {text:'畜牧业', value:'0104'},{text:'渔业', value:'0105'},{text:'农林牧渔专业及辅助性活动', value:'0106'}
        ]},
        {text:'采矿与采石', value:'0200',children:[
          {text:'请选择', value:'0201'}
        ]},
        {text:'制造业', value:'0300',children:[
          {text:'请选择', value:'0301'}
        ]},
        {text:'电力、热力、燃气及水生产和供应业', value:'0400',children:[
          {text:'请选择', value:'0401'}
        ]},
        {text:'建筑业', value:'0500',children:[
          {text:'请选择', value:'0501'}
        ]},
        {text:'批发和零售业', value:'0600',children:[
          {text:'请选择', value:'0601'}
        ]},
        {text:'交通运输、仓储和运输业', value:'0700',children:[
          {text:'请选择', value:'0701'}
        ]},
        {text:'住宿和餐饮业', value:'0800',children:[
          {text:'请选择', value:'0801'}
        ]},
        {text:'信息传输、软件和信息技术服务业', value:'0900',children:[
          {text:'请选择', value:'0901'}
        ]},
        {text:'金融业', value:'1000',children:[
          {text:'请选择', value:'1001'}
        ]},
        {text:'房地产业', value:'1100',children:[
          {text:'请选择', value:'1101'}
        ]},
        {text:'租赁和商务服务业', value:'1200',children:[
          {text:'请选择', value:'1201'}
        ]},
        {text:'科学研究和技术服务业', value:'1300',children:[
          {text:'请选择', value:'1301'}
        ]},
        {text:'水利、环境和公共设施管理业', value:'1400',children:[
          {text:'请选择', value:'1401'}
        ]},
        {text:'居民服务、修理和其他服务业', value:'1500',children:[
          {text:'请选择', value:'1501'}
        ]},
        {text:'教育', value:'1600',children:[
          {text:'请选择', value:'1601'}
        ]},
        {text:'卫生和社会工作', value:'1700',children:[
          {text:'请选择', value:'1701'}
        ]},
        {text:'文化、体育和娱乐业', value:'1800',children:[
          {text:'请选择', value:'1801'}
        ]},
        {text:'公共管理、社会保障和社会组织', value:'1900',children:[
          {text:'请选择', value:'1901'}
        ]},
        {text:'国际组织', value:'2000',children:[
          {text:'请选择', value:'2001'}
        ]},
      ],
      // 企业类型
      result2:'',
      showPicker2:false,
      businessType:[
        { text: '生产型', value: 'value1' },
        { text: '销售型', value: 'value2' },
        { text: '工程型', value: 'value3' },
        { text: '设计型', value: 'value4' },
        { text: '服务型', value: 'value5' },
        { text: '生产+销售型', value: 'value6' },
        { text: '工程+设计型', value: 'value7' }
      ],
      // 项目类型
      result1:'',
      showPicker1:false,
      projectList:[
        {text:'ISO9001', value:'value1', picked:false}, 
        {text:'ISO27001', value:'value2', picked:false}, 
        {text:'ISO45001', value:'value3', picked:false},
        {text:'ISO14001', value:'value4', picked:false}, 
        {text:'ISO22000', value:'value5', picked:false}, 
        {text:'ISO20000', value:'value6', picked:false},
        {text:'HACCP', value:'value7', picked:false}, 
        {text:'三体系', value:'value8', picked:false}, 
        {text:'GB/T50430', value:'value9', picked:false}, 
        {text:'IATF16949', value:'value10', picked:false}, 
        {text:'ISO13485', value:'value11', picked:false}
      ],
    })
    
    function go(n){
      router.go(n)
    }
    // 所属区域
    function onFinish(data){
      let province = data[0]
      let city = data[1]

      if(province.name){
        console.log(province,"省");
      }else{
        this.$toast("请选择省");
        return;
      }
 
      if(city.name){
        console.log(city,"市");
      }else{
        this.$toast("请选择市");
        return;
      }

      form.value.areaValue = province.name + '-' + city.name
      form.value.show = false;
    }
    // 所属行业
    function onConfirm3(event) {
      // console.log(event);
      form.value.show2 = false;
      form.value.fieldValue2 = event[0].text + '-' + event[1].text
    }
    // 企业类型
    const onConfirm2 = ( selectedOptions ) => {
      form.value.showPicker2 = false;
      form.value.result2 = selectedOptions.text;
    }
    // 项目类型
    const onConfirm1 = ( selectedOptions ) => {
      form.value.showPicker1 = false;
      form.value.result1 = selectedOptions.text;
    }
    
    const checked = ref(true)
    function submit(){
      console.log('submit')
    }
    const fileList = ref([])
    function pickThis(i){
      form.value.projectList[i].picked = !form.value.projectList[i].picked
    }
    function cancel(){
      form.value.showPicker1 = false;
    }
    function confirm(){
      form.value.showPicker1 = false;

      form.value.result1 = []
      let list = form.value.result1
      let item = {text:'', picked:true}
      for(var i=0; i<form.value.projectList.length; i++){
        if(form.value.projectList[i].picked){
          item = form.value.projectList[i]
          list.push(item)
          item = {text:'', picked:true}
        }
      }
    }
    function deleteThis(item, n){
      for(var i in form.value.projectList){
        if(form.value.projectList[i].text===item.text){
          form.value.projectList[i].picked = false
        }
      }
      form.value.result1.splice(n, 1)
    }
    return {
      areaList, pointMsg, pointMsg2, percentage, form, checked, 
      go, onFinish, onConfirm3, onConfirm2, onConfirm1, 
      submit, pickThis, cancel, confirm, deleteThis, 
      // showAllPoint, 
      fileList, 
    }
  }
}
</script>

<style scoped>
  .bg{
    background: #FBFBFB;
  }
  .progress{
    padding: 0 50px;
    height: 40px;
    line-height: 40px;
    align-items: center;
    background:#fff;
    box-shadow: 0px 3px 4px 0px rgba(1,27,70,0.03);
    margin-top:44px;
  }
  .progress>span{
    width: 40px;
  }
  .progress>div{
    width: calc(100% - 40px);
  }
  :deep(.van-progress__pivot){
    height:12px;
    min-width: 12px;
    background:#fff !important;
    color:var(--color-text-blue);
    line-height:12px;
    box-shadow:1px 0 0px rgb(170, 160, 250);
  }
  :deep(.van-cell__right-icon){
    display: none;
  }
  form{
    margin-bottom:70px;
  }
  .bg_fff{
    margin: 12px;
    border-radius: 10px;
    padding: 12px;
    box-shadow: 0px 3px 4px 0px rgba(1,27,70,0.03);
  }
  input{
    border:none;
    border-bottom: 2px solid #EBEDF5;
    width: 100%;
    font-size:16px;
    line-height: 2; 
    margin-top:10px;
  }
  .bg_fff>div+div{
    margin-top: 20px;
  }
  input{
    border:none;
    border-bottom: 1px solid #EBEDF5;
    width: 100%;
    font-size:16px;
    line-height: 2; 
    margin-top:10px;
  }
  :deep(.van-cell--clickable){
    border-bottom: 1px solid #EBEDF5;
    font-size: 16px;
  }
  :deep(.van-cell){
    padding-left:0;
    padding-right:0;
  }
  :deep(.van-field__word-limit){
    margin-top: -16px;
  }
  :deep(.van-field__control){
    background: #F5F6FA;
    padding:5px 10px;
    font-size: 16px;
    border-radius:5px;
    box-sizing:border-box;
  }
  textarea{
    width:100%;
    padding:5px 10px;
    box-sizing:border-box;
    font-size: 16px;
    background: #F5F6FA;
    border:none;
    border-radius:5px;
    height:65px;
  }
  .van-picker__toolbar+.flex_wrap{
    margin:0 12px;
  }
  .van-picker__toolbar+.flex_wrap span{
    background: #F5F6FA;
    height:28px;
    line-height:28px;
    border-radius:20px;
    margin-right: 12px;
    margin-bottom: 10px;
    width:calc(33.33% - 8px);
    text-align: center;
  }
  .van-picker__toolbar+.flex_wrap span:nth-child(3n){
    margin-right: 0;
  }
  .van-picker__toolbar+.flex_wrap span.on{
    background: var(--color-text-blue);
    color:#fff;
  }
  :deep(.van-popup--bottom){
    height:308px;
  }
  .flex_wrap{
    margin-top: 15px;
  }
  .flex_wrap span{
    height:30px;
    line-height: 30px;
    margin-right: 8px;
    width:calc(33.33% - 8px);
    box-sizing: border-box;
  }
  :deep(.van-uploader__upload) ,
  :deep(.van-uploader__preview-image){
    margin-right:12px;
    margin-bottom:12px;
    width:90px !important;
    height:120px !important;
  }
  .my-swipe img{
    width: 72px;
    height: 96px;
    margin-right:12px;
  }
  .my-swipe img:nth-child(4n){
    margin-right: 0;
  }
  .fixed{
    background: var(--color-text-blue);
    color:#fff;
    height:40px;
    line-height: 40px;
    box-shadow: 0px 0px 10px 1px rgba(0,13,255,0.5);
    border-radius:20px;
    width:60%;
    margin-left:20%;
    bottom:20px; 
    z-index: 5;
  }
</style>