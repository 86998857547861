<template>
  <div>
    <Head>
      <template v-slot:left><div>
        <i class="in_block icon-xiangxia trans90" @click="go(-1)"></i>
        部门人员
      </div></template>
    </Head>
    <!-- 进度条 -->
    <!-- <div class="progress flex">
      <span class="f14">进度</span>
      <van-progress :percentage="percentage" stroke-width="12" track-color="#F5F6FA"
        color="linear-gradient(90deg, #3061F2 1%, #AAA0FA 100%)" pivot-text="√" />
      <span><span class="red">{{percentage}}</span>/100</span>
    </div> -->
    <div class="addbtn">
      <div class="f12 red">* 请先完善各部门负责人信息，然后再填写员工信息</div>
      <span class="btn mt-10" @click="toDepartmentAdd">添加部门</span><br/>
      <span class="btn mt-10 mr-10" @click="downloadExl">下载员工导入模板</span>
      <van-uploader v-model="fileList" :before-read="beforeRead" :after-read="afterRead"
        accept="application/vnd.ms-excel,application/vnd.openxmlformats-officedocument.spreadsheetml.sheet">
        <span class="btn">导入员工信息</span>
      </van-uploader>
    </div>
    <div class="list">
      <div class="item" v-for="item in department_list" :key="item.id">
        <div class="flex"><span>部门</span><div>{{item.name}}</div></div>
        <div class="flex"><span>负责人</span><div>{{item.staff_name}}</div></div>
        <div class="flex"><span>职位</span><div>{{item.staff_position}} <span v-if="item.is_guandai==1">/ 管理代表者</span></div></div>
        <div class="flex"><span>学历</span><div>{{item.staff_education}}</div></div>
        <div class="flex"><span>性别</span><div>{{item.staff_gender}}</div></div>
        <div class="flex"><span>入职时间</span><div>{{item.entry_time}}</div></div>
        <div class="flex">
          <span>操作</span>
          <div class="flex_wrap">
            <span @click="openStaffDialog(item)">管理员工</span>
            <span v-if="item.is_manager==0" @click="editDialog(item)">编辑</span>
            <span v-if="item.is_manager==0" @click="handleDelete(item)" class="red">删除</span>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import {ref, reactive, toRefs, onMounted, getCurrentInstance } from 'vue'
import {useRouter} from 'vue-router'
import { Toast } from 'vant'
import axios from 'axios'
import store from '@/store/index.js'
import Head from 'components/common/Head.vue'

export default {
  name: 'Department',
  components: {
    Head,
  },
  props: {},
  setup(){
    const {proxy} = getCurrentInstance()
    const Api = proxy.$commonApi
    const router = useRouter()
    
    let _this = reactive({
      department_name:'',
      pop_type:0,
      row:{},
      department_list:[],
      tagsList:[],
      edit_title:'',
    })
    let currentPage = ref(1)
    let isShow = ref(false)
    
    onMounted(()=>{
      getDepartList()
    })
    // 获取部门人员列表
    function getDepartList(){
      proxy.$post('/department/list',{
        page:currentPage.value,
      }).then(res=>{
        console.log(res)
        _this.tagsList = res.tagsList
        _this.department_list = res.departList
        // 部门列表
        // let newList = new Array()
        // res.departList.forEach(v=>{
        //   console.log(v.is_guandai, v.is_staff)
        //   if(v.is_guandai==1){
        //     v.is_guandai = true
        //   }else{
        //     v.is_guandai = false
        //   }
        //   if(v.is_staff==0) newList.push(v);
        // })
        // _this.departList = newList;
        // checkDepartment()
      })
    }
    // 检查进度
    // function checkDepartment(){
    //   let departList = _this.list
    //   let dname = ''
    //   let is_guandai = false
    //   let res = {type:1,dname:''};
    //   departList.forEach(v=>{
    //     if(v.is_staff==0){
    //       if(!v.education||!v.position||!v.entry_time){
    //         if(!dname)dname = v.department_name
    //       }
    //       if(v.is_guandai==1){
    //         is_guandai = true
    //       }
    //     }
    //   })
    //   have_msg.value = ''
    //   if(dname){
    //     res.dname = dname;
    //     have_msg.value = dname+' 信息不完善，请先完善部门信息！'
    //   }else{
    //     if(is_guandai==false){
    //       res.type = 2
    //       have_msg.value = '管理代表者尚未选择，请勾选！'
    //     }else{
    //       res.type = 0;
    //     }
    //   }
    //   return res;
    // }
    const checkDepartment = ()=> {
      let departList = _this.department_list;
      let is_staff = false;
      let department_name = '';
      for (let i = 0; i < departList.length; i++) {
        if (departList[i]['staff_name'] == '' || departList[i]['staff_name'] == undefined || departList[i]['staff_name'] == null) {
          is_staff = false;
          department_name = departList[i]['name'];
          break;
        } else {
          is_staff = true;
        }
      }
      if (!is_staff){
        Api.msg(department_name + '负责人信息不完善，请先完善负责人信息！');
        return false;
      }
      return true;
    }
    //添加部门
    function toDepartmentAdd(){
      _this.row = {};
      store.state.item = _this.row
      var flag = checkDepartment()
      if(flag){
        router.push('/home/department/departmentAdd')
      }
    }
    // 下载员工导入模板
    function downloadExl(){
      proxy.$post('/department/downloadStaffExcel').then(res=>{
        console.log(res)
        if(res.code==0){
          const link = document.createElement('a')
          link.setAttribute('href',Api.WEB_SITE+res.data)
          link.click()
        }else{
          Api.msg(res.msg)
        }
      }).catch(err=>{
        console.log(err)
      })
    }
    // 导入前检验文件格式和大小
    function beforeRead(file){
      console.log(file)
      const allowType = 'vnd.ms-excel,vnd.openxmlformats-officedocument.spreadsheetml.sheet'
      const type = file.type.split("/")[1]
      console.log(type)
      // 上传的只能是 xlsx,xls 格式!
      if(allowType.indexOf(type) == -1){
        Toast.fail('只能上传Excel文件！');
        return false;
      }else if(file.size > 2 * 1024 * 1024){
        Toast.fail('上传文件大小不能超过 2MB!');
        return false;
      }else{
        return file;
     }
    }
    // 导入员工信息exl后
    function afterRead(file){
      console.log(file)
      submitExl(file)
    }
    // 提交上传的exl
    function submitExl(file){
      let formData = new FormData()
      formData.append('file', file.file)
      axios.post('/department/importStaff', formData)
      .then(res=>{
        console.log(res)
        Toast(res.data.msg)
      })
    }
    // 管理员工
    const openStaffDialog = (item)=>{
      console.log(item)
      store.state.item = item
      router.push('/home/department/departmentEditStaff')
    }
    // 编辑
    const editDialog = (row) => {
      store.state.item = row
      router.push('/home/department/departmentAdd')
    }
    // 删除
    const handleDelete = (row) => {
      Api.confirm('是否删除此部门?',()=>{
        proxy.$post('department/delDepartment',{
          id:row.id,
        }).then((res)=>{
          if(res.code==0||res.code==200){
            getDepartList();
          }
          Api.msg(res.msg);
        });
      });
    }

    //添加人员
    function handleAdd(){
      var res = checkDepartment()
      if(res.type==1){
        Api.msg(res.dname+' 信息不完善，请先完善部门信息！');
        return false;
      }
      _this.row = {
        did:0,pid:'',charge:'',name:'',position:'',is_staff:1,entry_time:'',education:'',is_guandai:false,tags_ids:[]
      };
      _this.edit_title = '添加人员';
      _this.pop_type = 0;
      isShow.value = true;
    }
    //编辑人员
    function handleEdit(row){
      _this.row = row;
      // console.log(row)
      if(row.is_staff==0){
        _this.edit_title = '编辑部门';
        _this.pop_type = 0;
      }else{
        _this.edit_title = '编辑人员';
        _this.pop_type = 1;
      }
      isShow.value = true;
      // console.log(row)
    }
    // 返回上一步
    function go(n){
      router.go(n)
    }
    return{
      ...toRefs(_this), 
      currentPage, 
      isShow, 
      
      checkDepartment, 
      toDepartmentAdd, 
      downloadExl, 
      beforeRead, 
      afterRead, 
      submitExl, 
      openStaffDialog, 
      editDialog, 
      handleDelete, 
      // handleSEdit, 
      handleAdd, 
      handleEdit, 
      go, 
    }
  },
}
</script>

<style scoped>
  .addbtn{
    margin-left:12px;
    margin-right:12px;
  }
  input.pab{
    left: 0;
    top: 0;
    width: 100%;
    height: 100%;
    opacity: 0;
  }
  .item{
    box-shadow: 0px 0px 4px rgba(1,27,70,0.05);
    margin: 10px;
    padding: 10px;
    border-radius:10px;
  }
  .item .flex{
    height:30px;
    line-height: 30px;
  }
  .flex>span{
    width:5em;
    font-size: 14px;
    color:#666;
  }
  .flex span+div{
    width:clac(100% - 60px);
    font-size: 14px;
  }
  .flex_wrap{
    gap:15px;
    font-size:14px;
    color:#000;
  }
  .flex_wrap span{
    color: #3061F2;
  }
  span.red{
    color:#f00;
  }
</style>
