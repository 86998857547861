<template>
  <div>
    <keep-alive><router-view></router-view></keep-alive>
    <MainTabBar></MainTabBar>
  </div>
</template>

<script>
import MainTabBar from '@/components/content/mainTabBar/MainTabBar'

export default {
  name: 'Index',
  components:{
    MainTabBar
  }
}
</script>

<style scoped>
  
</style>