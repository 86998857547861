<template>
  <div>
    <Head>
      <template v-slot:left><div>
        <i class="in_block icon-xiangxia trans90" @click="go(-1)"></i>
        顾客管理
      </div></template>
    </Head>
    <span class="btn" @click="addCustomer">添加顾客信息</span>
    <div class="mt-10">
      <template v-if="customer_list.length">
        <div class="item" v-for="item in customer_list" :key="item.id">
          <div class="flex"><span>顾客名称</span><div>{{item.customer_name}}</div></div>
          <div class="flex"><span>产品名称</span><div>{{item.product_name}}</div></div>
          <div class="flex"><span>销售数量</span><div>{{item.sale_num}}</div></div>
          <div class="flex"><span>销售日期</span><div>{{item.sale_date}}</div></div>
          <div class="flex"><span>联系人</span><div>{{item.contacts}}</div></div>
          <div class="flex"><span>联系电话</span><div>{{item.contact_phone}}</div></div>
          <div class="flex">
            <span>操作</span>
            <div class="flex_wrap">
              <span @click="editCustomer(item)">编辑</span>
              <span class="red" @click="handleDelete(item)">删除</span>
            </div>
          </div>
        </div>
      </template>
      <van-empty v-else description="暂无数据！" />
    </div>
  </div>
</template>

<script>
import { reactive, toRefs, onMounted, getCurrentInstance } from 'vue'
import {useRouter} from 'vue-router'
import store from '@/store/index.js'
import Head from 'components/common/Head.vue'

export default {
  name: 'Customer',
  components: {
    Head,
  },
  setup(props, context){
    const {proxy} = getCurrentInstance()
    const Api = proxy.$commonApi
    const router = useRouter()

    var project_id = Api.sessionGet('project_id');
    const _this = reactive({
      project_id: project_id,
      customer_list: [],
    })
    
    onMounted(()=>{
      getCustomerList()
    })
    //获取顾客信息
    const getCustomerList = () => {
      proxy.$post('customer/index').then(res => {
        console.log(res)
        if (res.code == 0) {
          _this.customer_list = res.data.data
        }else {
          Api.msg(res.msg);
        }
      })
    }
    // 添加顾客信息
    function addCustomer(){
      store.state.item = {}
      router.push('/home/customer/detail')
    }
    // 编辑顾客信息
    const editCustomer = (row) => {
      store.state.item = row
      router.push('/home/customer/detail')
    }
    // 删除顾客信息
    const handleDelete = (row) => {
      Api.confirm('是否删除此顾客信息?',()=>{
        proxy.$post('customer/delCustomer',{
          customer_id:row.customer_id,
        }).then((res)=>{
          if(res.code==0||res.code==200){
            getCustomerList();
            resetPercent();
          }
          Api.msg(res.msg);
        });
      });
    }
    //更新进度
    const resetPercent = () => {
      let data = {}
      data.template_id = -8;
      context.emit("updatePercent", data)
    }
    function go(n){
      router.go(n)
    }
    return {
      ...toRefs(_this), 
      addCustomer, 
      editCustomer, 
      handleDelete, 
      resetPercent, 
      go,
    };
  }
}
</script>

<style scoped>
  .head+span{
    margin-left: 12px;
    margin-right:12px;
  }
  .item{
    box-shadow: 0px 0px 4px rgba(1,27,70,0.05);
    margin: 10px;
    padding: 10px;
    border-radius:10px;
  }
  .item .flex{
    height:30px;
    line-height: 30px;
  }
  .flex>span{
    width:5em;
    font-size: 14px;
    color:#999;
  }
  .flex span+div{
    width:calc(100% - 60px);
    font-size: 14px;
  }
  .flex_wrap{
    gap:15px;
  }
  .flex_wrap span{
    color: #3061F2;
  }
  span.red{
    color:#f00;
  }
</style>
