<template>
  <div>
    <Head>
      <template #left><div>
        <i class="in_block icon-xiangxia trans90" @click="go(-1)"></i>
        相关资质
      </div></template>
    </Head>
    <form action="">
      <div class="p10">
        <div class="f14 color_9">请根据认证范围上传相关资质，例如：CCC证书、许可证、产品检测报告等相关内容，图片格式不限，可上传多张，单张大小不得超过2M。</div>
        <div class="f14 red mt-10">注：保存数据之前不要刷新页面否则数据会丢失</div>
        <!-- <UploadImgs v-for="(item, index) in list" :key="index" :list="list" :item="item" :index="index" @changeItemList="changeItemList" @changeValue="changeValue"></UploadImgs> -->
        <van-uploader v-model="fileList" :after-read="afterRead" multiple>
          <template #preview-cover=""></template>
        </van-uploader>
        <!-- <span class="btn uploadImg" @click="submitImgs()">上传图片</span> -->
        <div class="flex_wrap">
          <div class="img img1" v-for="(item,index) in imgList" :key="item">
            <img :src="site+item" @click="previewImg">
            <i class="iconfont icon-tianjia" @click="deleUploaded(index)"></i>
          </div>
          <div class="img img2" v-for="(item,index) in newImgList" :key="item">
            <img :src="site+item" @click="previewImg" />
            <i class="iconfont icon-tianjia" @click="deleFileList(index)"></i>
          </div>
        </div>
      </div>
      <div class="blue"><van-checkbox v-model="qualification_flag" shape="square">暂无资质</van-checkbox></div>
      <div class="fixed text_center" @click="saveQualification">保存</div>
    </form>
    <!-- 图片放大 -->
    <Popup :isShow="isShow" :src="src" @closePopup="close"></Popup>
  </div>
</template>

<script>
import { ref, reactive, toRefs, onMounted, getCurrentInstance } from 'vue'
import { useRouter } from 'vue-router'
import axios from 'axios'
import Head from 'components/common/Head.vue'
import Popup from 'components/common/Popup.vue'
export default {
  name: 'Qualification',
  components: {
    Head, 
    Popup,
  },
  setup (props,context) {
    const {proxy} = getCurrentInstance()
    const Api = proxy.$commonApi
    const router = useRouter()

    var project_id = Api.sessionGet('project_id');
    let headers = Api.getHeader();
    const defaultData = reactive({
      action:Api.UPLOAD_URL,
      site:Api.WEB_SITE,
      list:[],
      project_id: project_id,
      headers:{
        USERKEY:headers.userKey,
        USERTOKEN:headers.token,
      },
    })
    let imgList = ref([])  //之前保存过的图片列表
    let newImgList = ref([])  //新上传的图片列表
    const fileList = ref([])
    const qualification_flag = ref(false) //是否选择了“暂无资质”
    let isShow = ref(false)  //Popup组件是否显示
    let src = ref('')  //放大图片的src
    onMounted(()=>{
      getCerList()
    })
    //获取相关资质的图片
    function getCerList(){
      if(qualification_flag.value){ qualification_flag.value = 1 }
      else{ qualification_flag.value = 0 }
      proxy.$post('/company/getCerList',{
        qualification_flag: qualification_flag.value
      }).then(res=>{
        console.log(res)
        imgList.value = res.data
        if(res.qualification_flag){
          qualification_flag.value = true
        }else{
          qualification_flag.value = false
        }
      })
    }
    function afterRead(file){
      if(file instanceof Array){
        file.map(v=>{
          v.file.rename = v.file.name
          submitImgs(v)
        })
      } else {
        file.file.rename = file.file.name
        submitImgs(file)
      }
    }
    // 提交图片到服务器
    function submitImgs(file){
      let formData = new FormData()
      formData.append('id', '99999')
      formData.append('ptype', 'temptags')
      formData.append('file', file.file)
      // formData.append('file', new Date().getTime())
      axios.post('/index/uploader', formData)
      .then(res=>{
        console.log(res)
        if(res.data.code==0){
          file.status = 'done'
          file.message = '上传成功'
          newImgList.value[newImgList.value.length] = res.data.data.src
        }
      })
    }
    // 删除之前上传过的图片
    function deleUploaded(n){
      imgList.value.splice(n,1)
    }
    // 删除这次添加但没有保存过的图片
    function deleFileList(n){
      newImgList.value.splice(n,1)
    }
    // 保存信息
    function saveQualification(){
      console.log(qualification_flag.value)
      if(qualification_flag.value){
        qualification_flag.value=1
      }else{
        qualification_flag.value=0
      }
      let qualification_arr = []
      console.log(imgList.value)
      console.log(newImgList.value)
      for(var i in imgList.value){
        qualification_arr[qualification_arr.length] = imgList.value[i]
      }
      for(var j in newImgList.value){
        qualification_arr[qualification_arr.length] = newImgList.value[j]
      }
      console.log(qualification_arr)
      proxy.$post('/company/qualificationUpdate',{
        qualification_flag: qualification_flag.value,
        qualification_arr:  qualification_arr
      }).then(res=>{
        console.log(res)
        if(res.code==0){
          getCerList()
          resetPercent()
          newImgList.value = []
        }
        Api.msg(res.msg,{});
      })
    }
    //更新进度
    const resetPercent = ()=>{
      let data = {}
      data.template_id = -6;
      context.emit("updatePercent", data)
    }
    // 查看图片放大,通过Popup组件
    function previewImg(e){
      isShow.value = true
      src.value = e.target.src
    }
    function close(n){
      isShow.value = n
    }
    // 返回上一步
    function go(n){
      router.go(n)
    }

    return{
      ...toRefs(defaultData), 
      imgList, 
      newImgList, 
      fileList, 
      qualification_flag, 
      isShow, 
      src, 
      afterRead, 
      submitImgs, 
      deleUploaded, 
      deleFileList, 
      saveQualification, 
      resetPercent, 
      previewImg, 
      close, 
      go, 
    }
  }
}

</script>

<style scoped>
  :deep(.van-uploader__preview){
    display: none;
  }
  .flex_wrap{
    gap:10px;
  }
  .img{
    width:80px;
    height:120px;
    position:relative;
  }
  .img img{
    width:100%;
    height:100%;
  }
  .icon-tianjia{
    display: inline-block;
    transform: rotate(45deg);
    position:absolute;
    top:0;
    right:0;
    font-size:14px;
    padding:3px;
    background:#fff;
    border-radius:50%;
  }
  .blue .van-checkbox{
    justify-content:center;
  }
</style>
