<template>
  <div class="bg">
    <div class="flex-b-c">
      <div class="text">
        <div class="f28 blue">Hello,</div>
        <div class="f14 blue bold">欢迎使用咨询工具</div>
      </div>
      <div v-if="isShow" class="right" @click="login_qrcode">微信登录</div>
      <div v-else class="right" @click="login_qrcode">密码登录</div>
    </div>
    <div class="text_center"><img src="@/assets/img/login/login_1.png" /></div>
    <!-- 账号登录界面 -->
    <form v-if="isShow" action="">
      <div class="name">
        <input v-model="account" ref="username" placeholder="请输入账号"/>
      </div>
      <div class="pwd">
        <input type="password" v-model="pwd" ref="userpwd" placeholder="请输入密码"/>
        <div class="eye">
          <i v-if="canSeePwd" class="icon-review" @click="seePwd"></i>
          <i v-else class="icon-biyan" @click="seePwd"></i>
        </div>
        <div class="fr f12 color_9 mt-10" @click="toGetPwd">忘记密码？</div>
      </div>
      <div class="text_center">
        <div><i class="icon-xiangyou1" @click="checkLogin"></i></div>
        <div class="f12 color_9 mt-10">登录即代表同意证果果<span class="blue">《服务协议》</span>及<span class="blue">《隐私政策》</span></div>
      </div>
    </form>
    <!-- 二维码登录界面 -->
    <form v-else action="">
      <div class="text_center">
        <div class="qrcode pr text_center lose">
          <img src="@/assets/img/login/login_qrcode.png" alt="" />
          <div class="lose" ref="lose" style="display:none;">
            <p class="f15 color_f">二维码已失效，点击刷新</p>
            <i class="icon-shuaxin"></i>
          </div>
        </div>
        <div class="f14 color_9 lh2">请使用微信扫描二维码进行登录</div>
      </div>
    </form>
  </div>
</template>

<script>
import { ref, getCurrentInstance } from 'vue';
import { useRouter } from 'vue-router'
import {Toast} from 'vant'

export default {
  name:'Login',
  setup(){
    const {proxy} = getCurrentInstance()
    const Api = proxy.$commonApi  ; console.log(Api)
    const router = useRouter()

    const isShow = ref(true)
    let account = ref('')
    let pwd = ref('')
    let canSeePwd = ref(false)

    // 登录切换，或账号密码，或二维码
    function login_qrcode(){
      isShow.value = !isShow.value
    }
    // 二维码失效
    // function getResult(){
    //   setInterval(()=>{
    //     proxy.$refs.lose.style.display = 'block'
    //   },1000)
    // }
    // 密码是否可以看到
    function seePwd(){
      canSeePwd.value = !canSeePwd.value
      if(canSeePwd.value){
        proxy.$refs.userpwd.type = 'text'
      }else{
        proxy.$refs.userpwd.type = 'password'
      }
    }
    // 去找回密码页面
    function toGetPwd(){
      router.push('/login/getpwd')
    }
    // 登录，验证账号和密码
    function checkLogin(){
      if( !account.value ){
        Toast('请输入账号')
        proxy.$refs.username.focus()
        return false
      }
      if( !pwd.value ){
        Toast('请输入密码')
        proxy.$refs.userpwd.focus()
        return false
      }
      proxy.$post('/login/login', {
        user_name: account.value,
        password: pwd.value,
      }).then(res=>{
        console.log(res)
        Toast.success(res.msg);
        if(res.code==200){
          Api.storageSet('user_id', res.user_id, 3600)
          Api.storageSet('project_id', 0, 3600)
          Api.sessionSet('user_id', res.user_id)
          Api.sessionSet('project_id', 0)
          router.push('/change')
        }
      }).catch(err=>{
        console.log(err)
      })
      return
    }

    return{
      isShow, 
      account, 
      pwd, 
      canSeePwd, 
      login_qrcode, 
      seePwd, 
      toGetPwd, 
      checkLogin, 
    }
  }
}
</script>

<style scoped>
@import url(@/assets/css/reset.css);

  .bg{
    background:url(@/assets/img/login/login_bg.png) no-repeat left top/100% 100%;
    padding:6.5vw 3.33vw 10vw;
    min-height: 100vh;
    box-sizing: border-box;
  }
  .flex-b-c{
    margin-bottom:50px;
  }
  .right{
    padding:9px 15px;
    background:rgba(255,255,255,.5);
    border-radius:20px;
    font-size:14px;
  }
  .right::before{
    content:'';
    background:url(@/assets/img/login/login.png) no-repeat left top/100%;
    width: 28px;
    height: 23px;
    display: inline-block;
    margin-right:8px;
    vertical-align:middle;
  }
  .text_center img{
    width:140px;
    height:85px;
  }
  form{
    padding-bottom:66px;
    min-height: auto;
  }
  form>div{
    margin-top:40px;
    padding:0 36px;
    position:relative;
  }
  input{
    width:100%;
    border:none;
    border-bottom:2px solid #EBEDF5;
    padding-left:30px;
    height:36px;
    line-height:36px;
    font-size:14px;
    background:transparent;
    box-sizing:border-box;
  }
  .name::before{
    content:'';
    position:absolute;
    top:0;
    background: url(@/assets/img/login/login.png) no-repeat left top/200%;
    background-position: 0 -35px;
    width:24px;
    height:28px;
  }
  .pwd::before{
    content:'';
    position:absolute;
    top:0;
    background: url(@/assets/img/login/login.png) no-repeat left top/200%;
    background-position: 0 -63px;
    width:24px;
    height:25px;
  }
  .eye{
    position: absolute;
    bottom: 0;
    right: 40px;
    line-height: 36px;
  }
  .icon-xiangyou1{
    font-size:28px;
    background: linear-gradient(55deg, #8E05FF 10%, #0F52FF 100%);
    padding:24px 24px;
    border:5px solid #F2F5FE;
    border-radius:50%;
    color:#fff;
    display: inline-block;
  }
  .qrcode img{
    width:160px;
    height: 160px;
  }
  .qrcode .lose{
    display: none;
    position: absolute;
    top: 0;
    left: 50%;
    width: 160px;
    height: 160px;
    transform: translate(-50%);
    background: rgba(0,0,0,.8);
    padding:0 20px;
    box-sizing: border-box;
    align-items: center;
    justify-content: center;
    flex-direction: column;
  }
  .lose .lose{
    display: flex;
  }
  .icon-shuaxin{
    font-size:30px;
    background: #fff;
    color:#000;
    border-radius:50%;
    border:0;
    padding:10px;
    margin-top:10px;
  }
  
</style>