<!-- 企业管理 页面的item -->
<template>
  <div class="itemBar text_center">
    <div class="f14 line2"><slot name="name"></slot></div>
    <div class="f12 orange"><slot name="shortname"></slot></div>
    <div class="in_block pr">
      <!-- <van-circle v-model:current-rate="currentRate" :speed="100" 
        :stroke-width="60" :color="gradientColor" stroke-linecap="round"
        layer-color="#F5F6FA" size="85px" start-position="bottom"/> -->
      <van-circle v-model:current-rate="currentRate_c" :speed="100"
        :stroke-width="60" :color="gradientColor" layer-color="#F5F6FA" 
        size="85px" start-position="bottom"/>
      <div class="text">
        <div class="DIN f28 blue">{{text}}<span class="f12 color_9">%</span></div>
        <div class="f12 color_9">完成度</div>
      </div>
    </div>
    <div class="flex-c- mt-15"><slot name="system"></slot></div>
    <div class="f14"><slot name="industry_name"></slot></div>
    <div v-if="status==0" class="pab red">制作中</div>
    <div v-if="status==1" class="pab red">审核中</div>
    <div v-if="status==2" class="pab red">审核通过</div>
    <div v-if="status==3" class="pab red">未通过</div>
    <div v-if="status==4" class="pab green">制作完成</div>
    <slot></slot>
  </div>
</template>

<script>
import { ref, computed } from 'vue'
// import store from '@/store/index.js'

export default {
  name: 'ItemBar',
  props:{
    currentRate:{
      type:Number
    },
    status:{
      type:Number,
      default:0
    }
  },
  setup(props){
    let currentRate_c = parseInt(props.currentRate)
    const status_c = parseInt(props.status)
    const text = computed(() => currentRate_c.toFixed(0))
    const gradientColor = ref({
      '0%': '#3061F2',
      '100%': '#AAA0FA',
    });

    return {
      currentRate_c, status_c, 
      text, gradientColor, 
    }
  },

}

</script>

<style scoped>
  .itemBar{
    background:#fff;
    box-shadow:0px 5px 19px 1px rgba(1,27,70,0.08);
    border-radius:10px;
    position:relative;
    padding:20px 14px;
    width:calc(50% - 6px);
    box-sizing:border-box; 
  }
  .f14.line2{
    line-height:1.5;
    height:3em;
    color: #3d3d40;
    font-weight: bold;
  }
  .orange{
    color: #F29130;
    line-height:1.5;
    height:1.5em;
  }
  .in_block{
    box-shadow:0px 0px 10px 1px rgba(1,27,70,0.08);
    border-radius:50%;
    padding:10px;
    width:105px;
    height:105px;
    box-sizing: border-box;
    margin:0 auto;
    display:flex;
    align-items: center;
    justify-content: center;
  }
  .text{
    position: absolute;
  }
  .DIN span{
    vertical-align:top;
  }
  .f14{
    color: rgba(48, 97, 242, .5);
  }
  .pab{
    top:0; 
    left:0;
    background:rgba(254, 44, 85, .1); 
    border-radius: 10px 0px 10px 0px;
    font-size:14px;
    line-height:1;
    padding:2px 12px;
  }
  .green{
    background: rgba(48, 97, 242, .1);
    color: #0aa70a;
  }
</style>