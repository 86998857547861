<template>
  <div>
    <div class="bg">
      <div class="f18">Hello,</div>
      <div class="f14">欢迎使用咨询工具</div>
    </div>
    <div class="bg_fff">
      <div class="flex-b-">
        <!-- <div class="f12 flex_1"><i class="blue icon-dizhi"></i> 北京市海淀区</div>
        <div class="f18">{{$store.state.companyName}}</div> -->
        <div class="f18">{{ projectInfo.project_name }}</div>
      </div>
      <div class="mt-30 flex_wrap">
        <HomeItem v-for="(item, index) in list" :key="item.id" :rate="100"
          :class="template_id==item.id ? 'active' : (item.percent==100?'normal':'')"
          :currentRate="item.percent" @click="changeIndex(item.id, index)" >
          <template v-slot:tit>{{item.name}}</template>
        </HomeItem>
        <HomeItem v-for="(item, index) in leftlist" :key="index" :rate="100"
          :class="current==index ? 'active' : (item.percentage==100?'normal':'')"
          :currentRate="item.percentage" @click="itemClick(item.link)">
          <template v-slot:tit>{{item.tit}}</template>
        </HomeItem>
      </div>
    </div>
  </div>
</template>

<script>
import { ref, reactive, toRefs, onMounted, getCurrentInstance } from 'vue'
import { useRouter } from 'vue-router'
import { Toast } from 'vant'
import HomeItem from 'components/content/home/HomeItem'

export default {
  name:'Home',
  components:{
    HomeItem
  },
  setup(){
    const {proxy} = getCurrentInstance()
    const Api = proxy.$commonApi
    const router = useRouter()

    let defaultData = reactive({
      list:[],
      companyInfo:{},
      projectInfo:{},
    })
    let template_id = ref(0)
    let project_id = Api.sessionGet('project_id')
    let fixed_menu = Api.fixed_menu;
    onMounted(()=>{
      getList()
    })
    // 获取模板目录
    function getList(){
      proxy.$post('template/getFormlist',{
        project_id:project_id
      }).then(res=>{
        console.log(res)
        defaultData.list = res.data
        defaultData.companyInfo = res.companyInfo
        defaultData.projectInfo = res.projectInfo
        if(router.push == '/home'){
          let path = ''
          defaultData.list.forEach((v)=>{
            if(path=='' && v.percent==100){
              // template_id.value = v.id
              debugger
              path = fixed_menu[v.id]
              if(v.id==-9) path='/home/supplier'
              if(v.id==-8) path='/home/customer'
              if(v.id==-7) path='/home/equipmentSchedule'
              if(v.id==-6) path='/home/qualification'
              if(v.id==-5) path='/home/address'
              if(v.id==-4) path='/home/flow'
              if(v.id==-3) path='/home/viewProject'
              if(v.id==-2) path='/home/department'
              if(v.id==-1) path='/home/externaList'

              if(v.id==0) path='/home'
              if(v.id > 0) path='/home/information/'+v.id
            }
          })
          if(path!=='' && path!=='/home'){
            router.push(path)
          }
        }
      }).catch(err=>{
        console.log(err)
      })
    }
    // 项目切换
    function changeIndex(id, index){
      if(index>0){
        // var prevPercent = defaultData.list[index-1]['percent']
        // if(prevPercent<100){
        //   if(index==-3){
        //     Toast({message:'资料未完成，无法下载！'})
        //   }else{
        //     Toast({message:'请先完成上一个模板！'})
        //   }
        //   return false
        // }
        for(var i=0; i<index; i++){
          if(defaultData.list[i]['percent']<100){
            Toast(defaultData.list[i]['name'] + '资料不完整，请补充完整！')
            return false;
          }
          continue
        }
      }
      template_id.value = id
      if(id==0) {
        router.push('/home/baseInfo')
      }else if(id>0){
        router.push('/home/information/'+id)
      }else if(id==-33){
        packageProject()
      }
      // else{
      //   router.push(fixed_menu[id])
      // }
      else if(id==-2) {
        router.push('/home/department')
      }else if(id==-1) {
        router.push('/home/externalList')
      }else if(id==-5) {
        router.push('/home/address')
      }else if(id==-4) {
        router.push('/home/flow')
      }else if(id==-7) {
        router.push('/home/equipmentSchedule')
      }else if(id==-6) {
        router.push('/home/qualification')
      }else if(id==-8) {
        router.push('/home/customer')
      }else if(id==-9) {
        router.push('/home/supplier')
      }else if(id==-3) {
        router.push('/home/viewProject')
      }
    }
    // 打包下载
    function packageProject(){
      proxy.$post('project/packageProject',{
        project_id: project_id
      }).then(res=>{
        console.log(res)
        if(res.code==0){
          const link = document.createElement('a')
          link.setAttribute('href',Api.WEB_SITE+res.data)
          link.click()
        }else{
          Api.msg(res.msg)
        }
      })
    }
    // 更新百分比进度
    function updatePercent(data){
      console.log(data)
      getList()
    }

    let current = ref(0)
    let leftlist = ref([
      {id:20,tit:'原企业信息', link:'/home/information-s', percentage:50},
      {id:21,tit:'外来清单', link:'/home/externalList', percentage:50},
      {id:22,tit:'demo', link:'/home/demo', percentage:50},
    ])
    function itemClick(link){
      router.push(link)
    }
    
    return{
      ...toRefs(defaultData), 
      template_id, 
      project_id, 
      changeIndex, 
      packageProject, 
      updatePercent, 

      current, leftlist, itemClick, 
    }
  }
}
</script>

<style scoped>
  .bg{
    background:url(@/assets/img/home/banner.png) no-repeat left top /100%;
    padding:60px 12px 0;
    height:238px;
    box-sizing: border-box;
  }
  .f14{
    color:#3D3D40;
  }
  .bg_fff{
    margin-top: -12px;
    border-radius: 20px 20px 0 0;
    padding: 12px;
    margin-bottom:50px;
  }
  .flex_wrap{
    gap:12px;
  }
</style>
