<template>
  <div>
    <Head>
      <template #left><div>
        <i class="in_block icon-xiangxia trans90" @click="go(-1)"></i>
        员工信息
      </div></template>
    </Head>
    <form class="" action="">
      <div>
        <div class="f14 color_9">姓名</div>
        <div class="border">
          <input type="text" v-model="staff_form.staff_name" placeholder="请输入姓名"/>
        </div>
      </div>
      <div>
        <div class="f14 color_9">职位</div>
        <div class="f12 red">注：职位手动填写，如经理、员工、财务、切割工等。</div>
        <div class="border">
          <input type="text" v-model="staff_form.staff_position" placeholder="请输入职位"/>
        </div>
      </div>
      <div>
        <div class="f14 color_9">性别</div>
        <div class="border">
          <van-field readonly clickable v-model="staff_form.staff_gender" placeholder="请选择" @click="showGender=true"/>
          <van-popup v-model:show="showGender" round position="bottom">
            <van-picker show-toolbar :columns="genderList" @confirm="onConfirmGender" @cancel="showGender = false" />
          </van-popup>
        </div>
      </div>
      <div>
        <div class="f14 color_9">学历</div>
        <div class="border">
          <van-field readonly clickable v-model="staff_form.staff_education" placeholder="请选择学历" @click="showEducation=true"/>
          <van-popup v-model:show="showEducation" round position="bottom">
            <van-picker show-toolbar :columns="educationList" @confirm="onConfirm" @cancel="showEducation = false" />
          </van-popup>
        </div>
      </div>
      <div>
        <div class="f14 color_9">入职时间</div>
        <div class="border">
          <van-field readonly v-model="staff_form.entry_time" is-link placeholder="点击选择时间" @click="showDate = true"/>
          <van-popup v-model:show="showDate" round position="bottom">
            <van-datetime-picker type="date" v-model="currentDate" :min-date="minDate" :max-date="maxDate" @confirm="chooseEntryTime" @cancel="showDate = false" />
          </van-popup>
        </div>
      </div>
      <div>
        <div class="f14 color_9">电话</div>
        <div class="f12 red"> 注：此项非必填，总经理的电话必须填写。</div>
        <div v-show="showErrMsg" class="red f12">请输入正确的手机号</div>
        <div class="border">
          <input type="text" v-model="staff_form.mobile" @blur="checkPhoneNum" placeholder="请输入联系方式"/>
        </div>
      </div>
      <div>
        <div class="f14 color_9">级别</div>
        <div class="flex mt-10">
            <van-checkbox shape="square" checked-color="#3061F2" v-model="staff_form.is_head">是否负责人</van-checkbox>
            <van-checkbox shape="square" checked-color="#3061F2" v-model="staff_form.is_guandai">管理代表者</van-checkbox>
        </div>
      </div>
      <div class="fixed text_center" @click="handleSave">保存信息</div>
    </form>
  </div>
</template>

<script>
import {ref, reactive, getCurrentInstance, onMounted} from 'vue'
import { useRouter } from 'vue-router'
import store from '@/store/index.js'
import Head from 'components/common/Head.vue'
export default {
  name: 'DepartmentAddStaff',
  components: {
    Head
  },
  props: {},
  setup () {
    const {proxy} = getCurrentInstance()
    const Api = proxy.$commonApi
    const router = useRouter()
    let staff_form = ref({})
    let showGender = ref(false)
    let genderList = ref(['男','女','保密'])
    let educationList = ref(["小学","初中","高中","专科","本科","研究生","博士"])
    let showEducation = ref(false)
    let currentDate = new Date()
    let showDate = ref(false)
    let showErrMsg = ref(false)

    let tableData = ref([]);
    let dataState = reactive({
      company_id: '',
      department_id: ''
    });
    
    // 查询输入框初始值
    const searchObj = reactive({
      search_word:''
    })

    onMounted(()=>{
      var item = store.state.item
      var childitem = store.state.childitem
      console.log(childitem)
      staff_form.value = childitem
      if(staff_form.value.staff_gender=='0'){
        staff_form.value.staff_gender = '保密'
      }
      if(staff_form.value.staff_gender=='1'){
        staff_form.value.staff_gender = '男'
      }
      if(staff_form.value.staff_gender=='2'){
        staff_form.value.staff_gender = '女'
      }
      console.log(staff_form.value)
      dataState.company_id = item.company_id
      dataState.department_id = item.id
      getStaffList(dataState.company_id,dataState.department_id)
    })
    //获取人员列表
    const getStaffList = (company_id,department_id) =>{
      proxy.$post("/department/staff_list",{
        //page:currentPage.value,
        company_id:company_id,
        department_id:department_id,
        search_word:searchObj.search_word
      }).then((res)=>{
        console.log(res)
        tableData.value = res.data.data;
      })
    }
    // 选择性别
    const onConfirmGender = (val) => {
      staff_form.value.staff_gender = val
      showGender.value = false
    }
    // 选择学历
    const onConfirm = (val) => {
      staff_form.value.staff_education = val;
      showEducation.value = false;
    }
    // 选择入职时间
    const chooseEntryTime = (val) => {
      staff_form.value.entry_time = `${val.getFullYear()}-${val.getMonth() + 1}-${val.getDate()}`
      showDate.value = false;
    }
    // 验证手机号
    const checkPhoneNum = (e)=>{
      const reg = /^1[3-9]\d{9}$/
      const result = reg.test(e.target.value)
      if(result){
        showErrMsg.value = false
        return staff_form.value.mobile = e.target.value
      }else{
        return showErrMsg.value = true
      }
    }
    // 保存员工信息
    const handleSave = ()=>{
      if(!staff_form.value.staff_name){
        Api.msg('请填写员工姓名！');
        return false;
      }
      if(!staff_form.value.staff_position){
        Api.msg('请填写员工职位！');
        return false;
      }
      if(staff_form.value.staff_gender=='保密'){
        staff_form.value.staff_gender = '0'
      }
      if(staff_form.value.staff_gender=='男'){
        staff_form.value.staff_gender = '1'
      }
      if(staff_form.value.staff_gender=='女'){
        staff_form.value.staff_gender = '2'
      }
      // if(!staff_form.value.staff_education){
      //   Api.msg('请选择员工学历！');
      //   return false;
      // }
      // if(!staff_form.value.entry_time){
      //   Api.msg('请选择入职时间！');
      //   return false;
      // }
      // if(!staff_form.value.mobile){
      //   Api.msg('请填写员工的联系方式！');
      //   return false;
      // }
      staff_form.value.company_id = dataState.company_id;
      staff_form.value.department_id = dataState.department_id;
      console.log(dataState)
      proxy.$post('/department/saveDepartmentStaff',staff_form.value)
      .then((res)=>{
        console.log(res)
        if(res.code==0){
          getStaffList(dataState.company_id,dataState.department_id);
          Api.msg(res.msg,0,'',function(){
            // dialogFormVisible.value = false;
          });
          go(-1)
        }else{
          Api.msg(res.msg,0,'',function(){
            // dialogFormVisible.value = true;
          });
        }

      });
    }
    // 返回上一步
    function go(n){ router.go(n) }

    return {
      staff_form, 
      showGender, 
      genderList, 
      educationList,
      showEducation,
      currentDate,
      showDate,
      showErrMsg,
      minDate: new Date(2000, 0, 1),
      maxDate: new Date(2025, 5, 1),
      onConfirmGender, 
      onConfirm,
      chooseEntryTime,
      checkPhoneNum,
      handleSave,
      go,
    }
  }
}
</script>

<style scoped>
  form{
    margin-left:12px;
    margin-right:12px; 
  }
  form>div+div{
    margin-top: 20px;
  }
  .border{
    border-bottom: 1px solid #EBEDF5;
  }
  input{
    border:none;
    /* border-bottom: 1px solid #EBEDF5; */
    width: 100%;
    font-size:16px;
    line-height: 2; 
    margin-top:10px;
  }
  :deep(.van-cell){
    padding-left:0;
    padding-right:0;
    font-size: 16px;
  }
  :deep(.van-cell::after){
    display: none;
  }
  :deep(.van-field .van-cell:after){
    border:none;
  }
  .flex{
    gap:20px;
  }
</style>
