<template>
  <div class="bg">
    <Head>
      <template v-slot:left><div>
        <i class="in_block icon-xiangxia trans90" @click="go(-1)"></i>
        基本信息
      </div></template>
    </Head>
    <!-- 进度条 -->
    <!-- <div class="progress flex">
      <span class="f14">进度</span>
      <van-progress :percentage="percentage" stroke-width="12" track-color="#F5F6FA"
        color="linear-gradient(90deg, #3061F2 1%, #AAA0FA 100%)" pivot-text="√" />
      <span><span class="red">{{percentage}}</span>/100</span>
    </div> -->
    <!-- 填写内容 -->
    <form action="">
      <div class="bg_fff">
        <div>
          <div class="f14 color_9">企业名称</div>
          <div class="f12 red">注：认证企业全称，需要与营业执照名称相符，否则将影响认证审核。</div>
          <div class="border">
            <input type="text" v-model="companyInfo.company_name" @blur="checkCompany" placeholder="请输入企业名称" />
          </div>
        </div>
        <div>
          <div class="f14 color_9">企业简称</div>
          <div class="f12 red">注：企业名称简称为大写英文字母且不超过4位；如：河北证优科技有限公司，简称为ZYKJ。</div>
          <div class="border">
            <input type="text" v-model="companyInfo.brief_name" @blur="checkShort" placeholder="请输入企业简称" />
          </div>
        </div>
        <div>
          <div class="f14 color_9">所属区域</div>
          <div class="border">
            <Linkage v-if="flag" 
              :first_id="province_id" :second_id="city_id" :third_id="country_id" 
              :length="3" @changeEvent="changeArea"></Linkage>
          </div>
        </div>
        <div>
          <div class="f14 color_9">所属行业</div>
          <div class="border">
            <Linkage v-if="flag" 
              :first_id="industrys_pid" :second_id="industrys_id"
              type="industrys" :length="2" @changeEvent="changeIndustrys"></Linkage>
          </div>
        </div>
        <div>
          <div class="f14 color_9">企业邮编</div>
          <div class="border">
            <input type="text" v-model="companyInfo.postcode" placeholder="企业邮编" />
          </div>
        </div>
        <div>
          <div class="f14 color_9">注册资本</div>
          <div class="border">
            <input type="text" v-model="companyInfo.registered_capital" placeholder="注册资本" />
          </div>
        </div>
        <div>
          <div class="f14 color_9">成立年限</div>
          <div class="border">
            <input type="text" v-model="companyInfo.establishment_period" placeholder="成立年限" />
          </div>
        </div>
      </div>
      <div class="bg_fff">
        <div>
          <div class="f14 color_9">产品名称</div>
          <div class="f12 red">注：填写产品名称，多产品中间用“、”分割。</div>
          <div class="border">
            <input type="text" v-model="companyInfo.product_name" placeholder="请输入产品名称" />
          </div>
        </div>
        <div>
          <div class="f14 color_9">企业简介</div>
          <div class="f12 red">注：您可以自行编写输入企业简介，也可以进行快捷选择简介模版。</div>
          <div>
            <select v-model="companyInfo.brief_id" @change="fun_show"> 
              <option value="0">请选择简介</option>
              <option value="99">自定义</option>
              <option v-for="it in briefList" :key="it.id" :id="it.id" :value="parseInt(it.id)">{{it.title}}</option>
            </select>
            <!-- <textarea v-if="show_flag" class="mt-10" v-model="companyInfo.brief" placeholder="请输入企业简介"></textarea> -->
            <template v-if="show_flag">
              <textarea v-if="companyInfo.brief_id==99" class="mt-10" v-model="companyInfo.brief" placeholder="请输入企业简介"></textarea>
              <textarea v-else class="mt-10" readonly v-model="companyInfo.brief"></textarea>
            </template>
          </div>
        </div>
        <div>
          <div class="f14 color_9">认证人数</div>
          <div class="border">
            <input type="text" v-model="companyInfo.cert_people_num" placeholder="请输入认证人数"/>
          </div>
        </div>
        <div>
          <div class="f14 color_9">认证范围</div>
          <div class="f12 red">注：填写此内容前请于机构或者咨询师确认范围后在进行填写。</div>
          <div>
            <textarea class="mt-10" v-model="companyInfo.scope" placeholder="请输入认证范围"></textarea>
          </div>
        </div>
        <div>
          <div class="f14 color_9">营业执照</div>
          <div class="">
            <div class="color_9">电子版营业执照，不能遮挡、涂改，不可加水印</div>
            <van-uploader v-model="fileList" :before-read="beforeRead" :after-read="afterRead" max-count="1">
              <template #preview-cover="{file}">
                重命名：<input class="preview-cover van-ellipsis" v-model="file.rename" @click.stop="reWriteName(file)"/>
              </template>
            </van-uploader>
            <span class="btn uploadImg" @click="submitImgs()">上传图片</span>
          </div>
          <img class="mt-10" :src="site+companyInfo.license" v-if="companyInfo.license" @click="previewImg" alt=""/>
        </div>
      </div>
      <div class="text_center fixed" @click="modifyCompany">提交信息</div>
    </form>
    <!-- 营业执照图片放大 -->
    <Popup :isShow="isShow" :src="src" @closePopup="close"></Popup>
  </div>
</template>

<script>
import {ref, reactive, toRefs, onMounted, getCurrentInstance} from 'vue'
import {useRouter} from 'vue-router'
import { Toast } from 'vant'
import axios from 'axios'
import Head from 'components/common/Head.vue'
import Linkage from 'components/common/Linkage.vue'
import Popup from 'components/common/Popup.vue'

export default {
  name: 'BaseInfo',
  components: {
    Head,
    Linkage,
    Popup,
  },
  props: {},
  setup(props, context){
    const router = useRouter()
    const {proxy} = getCurrentInstance()
    const Api = proxy.$commonApi
    
    // 公共数据
    var project_id = Api.sessionGet('project_id');
    let headers = Api.getHeader();
    let _this = reactive({
      action:Api.UPLOAD_URL,
      site:Api.WEB_SITE,
      companyInfo:[],
      project_id: project_id,
      company_id:0,
      headers:{
        USERKEY:headers.userKey,
        USERTOKEN:headers.token,
      },
      policyList:[],
      briefList:[],
      qualityList:[],
    })
    let allowSub = ref(true) //是否允许提交
    let show_flag = ref(false)
    let src = ref('')

    let flag = ref(false)
    // 地区
    // let address = ref('')
    let province_id = ref(0)
    let city_id = ref(0)
    let country_id = ref(0)
    // 行业
    // let industry = ref('')
    let industrys_pid = ref(0)
    let industrys_id = ref(0)
    // 放大图片的Popup是否展示
    let isShow = ref(false)
    const fileList = ref([]);
    
    onMounted(()=>{
      // getList()
      getCompanyInfo()
      setTimeout(()=>{
        fun_show()
      },1000)
    })
    // 获取企业信息
    function getCompanyInfo(){
      // var load = Api.loading()
      proxy.$post('/company/detail',{
        company_id:_this.company_id,
        project_id:_this.project_id,
      }).then(res=>{
        console.log(res)
        // 企业信息
        let companyInfo = res.info
        _this.companyInfo = companyInfo
        // 企业简介 模板
        _this.briefList = res.briefList
        _this.brief_id = res.info.brief_id
        // 政策方针
        _this.policyList = res.policyList
        // 质量目标
        // _this.qualityList = res.qualityList
        if (companyInfo.id > 0) {
          updateCompany(companyInfo);
        }
        if(companyInfo.length==0){
          flag.value = true
        }
        // fun_show(_this.companyInfo.brief_id)
        // Api.close(load)
      }).catch(err=>{
        console.log(err)
      })
    }
    // 获取模板目录
    // let defaultData = reactive({
    //   list:[],
    // })
    // function getList(){
    //   proxy.$post('template/getFormlist',{
    //     project_id:project_id
    //   }).then(res=>{
    //     console.log(res)
    //     defaultData.list = res.data
    //   })
    // }
    // 获取企业简介的模板id
    function brief(briefId){
      proxy.$post('/company/show_brief',{
        brief_id: briefId
      }).then(res=>{
        console.log(res)
        if(res.code){
          show_flag.value = false;
          Api.msg(res.msg)
        }else{
          show_flag.value = true;
          _this.companyInfo.brief = res.info
        }
      })
    }
    function updateCompany(companyInfo){
      console.log(companyInfo)
      _this.companyInfo = companyInfo
      province_id.value = companyInfo.province
      city_id.value = companyInfo.city
      country_id.value = companyInfo.country
      industrys_pid.value = companyInfo.industrys_pid
      industrys_id.value = companyInfo.industrys_id
      _this.company_id = companyInfo.id
      flag.value = true
    }
    //检查企业信息
    const checkCompany = () => {
      var companyInfo = _this.companyInfo;
      proxy.$post('company/checkCompany', {
        company_name: companyInfo.company_name,
      }).then(res => {
        console.log(res)
        var companyInfo = res.data;
        // console.log(companyInfo.length)
        if (companyInfo.id > 0) {
          updateCompany(companyInfo)
        }
      })
    }
    //检查公司简称
    const checkShort = (v) => {
      var value = v.target.value
      if (/^[A-Z]{1,4}$/.test(value) == false) {
        Api.msg('请输入1到4位大写英文字符', 'error', 25);
        let res = value.match(/([A-Z]){1,4}/g)
        let str = ''
        if (res != null) str = res[0]
        _this.companyInfo.brief_name = str || ''
      }
    }
    // 保存信息
    function modifyCompany(){
      var companyInfo = _this.companyInfo
      companyInfo.province = province_id.value
      companyInfo.city = city_id.value
      companyInfo.country = country_id.value
      companyInfo.industrys_pid = industrys_pid.value
      companyInfo.industrys_id = industrys_id.value
      
      companyInfo.project_id = _this.project_id
      companyInfo.company_id = _this.company_id
      // let companyInfo = _this.companyInfo

      let company_name = companyInfo.company_name;
      if(!company_name){
        Api.msg('请填写企业名称！');return false;
      }
      if(!Api.checkPreg(company_name,'company')){
        Api.msg('请填写正确的企业名称！');return false;
      }
      let brief_name = companyInfo.brief_name;
      if(!brief_name){
        Api.msg('请填写企业简称！');return false;
      }
      var preg = /[A-Z]{1,4}/
      if(brief_name.length>4||!preg.test(brief_name)){
        Api.msg('企业简称请填写4位以内的大写字母！');return false;
      }
      // if(!city_id.value){
      //   Api.msg('请选择城市信息！');return false;
      // }
      // if(!industrys_id.value){
      //   Api.msg('请选择行业信息！');return false;
      // }
      var postcode = companyInfo.postcode
      if(!postcode){
        Api.msg('请填写企业邮编！');return false;
      }
      // 如果是生产型企业
      var product_name = companyInfo.product_name
      if(!product_name){
        Api.msg('请填写产品名称！');return false;
      }
      
      // var policy = companyInfo.policy
      // if(!policy){
      //   Api.msg('请填写政策方针！');return false;
      // }
      // var brief = companyInfo.brief
      // if(!brief){
      //   Api.msg('请填写企业简介！');return false;
      // }
      var cert_people_num = companyInfo.cert_people_num;
      if (!cert_people_num) {
        Api.msg('请填写认证人数！');return false;
      }
      var scope = companyInfo.scope
      if(!scope){
        Api.msg('请填写认证范围！');return false;
      }
      // var quality_target = companyInfo.quality_target
      // if(!quality_target){
      //   Api.msg('请填写质量目标！');return false;
      // }
      let license = _this.companyInfo.license
      if(!license){
        Api.msg('请上传企业资质！');return false;
      }
      proxy.$post('/company/addCompany',companyInfo)
      .then((res)=>{
        console.log(res)
        if(res.code==0||res.code==200){
          // allowSub.value = false
          resetPercent();
        }
        Api.msg(res.msg);
      });
    }
    //更新进度
    const resetPercent = ()=>{
      let percent = 100
      let data = {}
      data.template_id = 0;
      data.percent = percent;
      context.emit("updatePercent", data)
    }
    // 返回上一级
    function go(n){
      router.go(n)
    }
    const changeArea = (val,flag)=>{
      console.log(flag)
      province_id.value = val[0]||0
      city_id.value = val[1]||0
      country_id.value = val[2]||0
    }
    const changeIndustrys = (val)=>{
      console.log(val)
      industrys_pid.value = val[0]||0
      industrys_id.value = val[1]||0
    }
    
    //切换企业方针
    const changePolicy = (e)=>{
      _this.companyInfo.policy = e;
    }
    // 是否显示 简介文本域
    const fun_show = ()=>{
      if(_this.companyInfo.brief_id==0){
        show_flag.value = false;
        // _this.companyInfo.brief = ''
      } else if(_this.companyInfo.brief_id==99){
        show_flag.value = true;
      } else {
        brief(_this.companyInfo.brief_id)
      }
    }
    //切换质量目标
    // const changeQuality = (e)=>{
    //   _this.companyInfo.quality_target = e
    // }
    // 图片上传（没有传到服务器）
    let file_var = ref(null)
    function beforeRead(file){
      const allowType = 'png,jpeg,jpg,gif'
      const type = file.type.split("/")[1]
      if(allowType.indexOf(type) == -1){
        Toast.fail('文件格式错误！');
        return false;
      }else if(file.size > 2 * 1024 * 1024){
        Toast.fail('上传文件大小不能超过 2MB!');
        return false;
      }else{
        return file;
      }
    }
    function afterRead(file){
      file.file.rename = file.file.name
      file_var = file
      // debugger
      // if(file instanceof Array){
      //   file.map(v=>{
      //     v.status = 'uploading'
      //     v.message = '上传中...'
      //     submitImgs(v)
      //   })
      // } else {
      //   file.status = 'uploading'
      //   file.message = '上传中...'
      //   submitImgs(file)
      // }
    }
    // 提交图片到服务器
    function submitImgs(){
      let file = file_var
      let formData = new FormData()
      formData.append('file', file.file)
      axios.post('/index/uploader', formData, _this.headers)
      .then(res=>{
        console.log(res)
        if(res.data.code==0){
          file.status = 'done'
          file.message = '上传成功'
          _this.companyInfo.license = res.data.data.src
        }
      })
      // console.log(fileList.value)
    }
    // 压缩图片 没有用着
    // function compress(img){
    //   let url = ''
    //   var w = Math.min(700, img.width);//当图片像素>700的时候，等比例压缩，这个数字可以调
    //   var h = img.height * (w / img.width);
    //   var canvas = document.createElement('canvas')
    //   var ctx = canvas.getContext('2d')
    //   canvas.width = w
    //   canvas.height = h
    //   ctx.drawImage(img, 0, 0, w, h)
    //   url = canvas.toDataURL('image/png',1)//1代表精细度，越高越好
    //   return url
    // }
    // 将base64转换为blob 没有用着
    // function dataURLtoBlob(data){
    //   var arr = data.split(',')
    //   var mine = arr[0].match(/:(.*?);/)[1]
    //   var bstr = atob(arr[1])
    //   var n = bstr.length
    //   var u8arr = new Uint8Array(n)
    //   while(n--){
    //     u8arr[n] = bstr.charCodeAt(n)
    //   }
    //   return new Blob([u8arr], {type: mine})
    // }
    // 查看图片放大,通过Popup组件
    function previewImg(e){
      isShow.value = true
      src.value = e.target.src
    }
    // 关闭图片放大,通过Popup组件
    function close(n){
      isShow.value = n
    }
    // 给图片重命名
    function reWriteName(file){
      return file
    }
      
    return {
      project_id, headers, 
      ...toRefs(_this),
      allowSub, 
      show_flag, 
      src,
      flag, 
      province_id,
      city_id, 
      country_id,
      industrys_pid, 
      industrys_id,  
      isShow, 
      fileList, 
      file_var, 

      checkCompany, 
      checkShort, 
      modifyCompany,
      go,
      changeArea,
      changeIndustrys, 
      changePolicy, 
      fun_show, 
      beforeRead, 
      afterRead, 
      submitImgs, 
      // compress,
      // dataURLtoBlob,
      previewImg, 
      close, 
      reWriteName, 
    }
  }
}
</script>

<style scoped>
  .bg_fff{
    margin: 12px;
    border-radius: 10px;
    padding: 12px;
    box-shadow: 0px 3px 4px 0px rgba(1,27,70,0.13);
  }
  .bg_fff>div+div{
    margin-top: 20px;
  }
  .border{
    border-bottom: 1px solid #EBEDF5;
  }
  input{
    border:none;
    /* border-bottom: 1px solid #EBEDF5; */
    width: 100%;
    font-size:16px;
    line-height: 2; 
    margin-top:10px;
  }
  select{
    height: 30px;
    line-height: 30px;
    border:1px solid #dcdfe6;
    border-radius:5px;
  }
  textarea{
    width:100%;
    padding:5px 10px;
    box-sizing:border-box;
    font-size: 16px;
    background: #F5F6FA;
    border:none;
    border-radius:5px;
    height:90px;
  }
  :deep(.van-uploader__upload) ,
  :deep(.van-uploader__preview-image){
    /* margin-right:12px;
    margin-bottom:12px; */
    width:90px !important;
    height:120px !important;
    overflow:visible;
  }
  :deep(.van-uploader__preview-cover){
    left:100%;
    font-size:14px;
    white-space:nowrap;
    line-height:30px;
  }
  .preview-cover {
    box-sizing: border-box;
    width:auto;
    padding: 4px;
    font-size: 14px;
    background: #f5f6fa;
    margin-top:0;
    height:30px;
    line-height:30px;
  }
  img{
    width:100%;
  }
</style>