<template>
  <div class="tabItem" :class="isActive?'on':''" @click="itemClick">
    <slot name="icon"></slot>
    <slot name="text"></slot>
  </div>
</template>

<script>
import { computed } from 'vue';
import { useRoute, useRouter } from 'vue-router';

export default {
  name: 'TabItem',
  props:{
    path:String,
  },
  setup (props) {
    const route = useRoute()
    const router = useRouter()

    const isActive = computed(() => {
      return route.path.indexOf(props.path) != -1
    })
    function itemClick(){
      router.push(props.path)
    }
    return {
      isActive, itemClick
    }
  }
}
</script>

<style scoped>
  .tabItem{ 
		text-align:center; 
		flex:1; 
		height:49px;
		font-size:14px;
	}
	
</style>
