<template>
  <div class="bg">
    <div class="flex-b-c">
      <div class="text">
        <div class="f28 blue">Hello,</div>
        <div class="f14 blue bold">欢迎使用咨询工具</div>
      </div>
      <!-- <div class="right">微信登录</div> -->
    </div>
    <div class="text_center f20">找回密码</div>
    <form action="">
      <div class="name">
        <input v-model="telephone" placeholder="请输入手机号"/>
      </div>
      <div class="yzm">
        <input v-model="verification" placeholder="请输入验证码"/>
        <div class="pab">发送验证码</div>
      </div>
      <div class="text_center">
        <router-link to="/login/resetpwd"><i class="icon-xiangyou1"></i></router-link>
      </div>
    </form>
  </div>
</template>

<script>
import { ref } from 'vue'

export default {
  name:'GetPwd',
  setup(){
    let telephone = ref('')
    let verification = ref('')
    
    return{
      telephone, verification, 
    }
  }
}
</script>

<style scoped>
  .bg{
    background:url(@/assets/img/login/login_bg.png) no-repeat left top/100% 100%;
    padding:6.5vw 3.33vw 10vw;
    min-height: 100vh;
    box-sizing: border-box;
  }
  .flex-b-c{
    margin-bottom:70px;
  }
  form{
    padding-bottom: 66px;
    min-height:auto;
    margin-bottom:0;
  }
  form>div{
    margin-top:40px;
    padding:0 36px;
    position:relative;
  }
  .name::before{
    content:'';
    position:absolute;
    top:0;
    background: url(@/assets/img/login/login.png) no-repeat left top/200%;
    background-position: 0 -35px;
    width:24px;
    height:28px;
  }
  .yzm::before{
    content:'';
    position:absolute;
    top:0;
    background: url(@/assets/img/login/login.png) no-repeat left top/200%;
    background-position: 0 -88px;
    width:24px;
    height:25px;
  }
  input{
    width:100%;
    border:none;
    border-bottom:2px solid #EBEDF5;
    padding-left:30px;
    height:36px;
    line-height:36px;
    font-size:14px;
    background:transparent;
    box-sizing:border-box;
  }
  form>div.text_center{ margin-top:100px; }
  .text_center i{
    font-size:28px;
    background: linear-gradient(55deg, #8E05FF 0%, #0F52FF 100%);
    padding:24px 24px;
    border:5px solid #fff;
    border-radius:50%;
    color:#fff;
  }
  .mt-30{
    margin-top:35px;
  }
  .pab{
    color: var(--color-text-blue);
    font-size:14px;
    right:36px;
    bottom: 11px;
    line-height:1em;
    padding-left:15px;
    border-left:1px solid #EBEDF5;
  }
</style>