<template>
  <div class="bg">
    <div class="flex-b-c">
      <div class="text">
        <div class="f28 blue">Hello,</div>
        <div class="f14 blue bold">欢迎使用咨询工具</div>
      </div>
      <div class="right">微信登录</div>
    </div>
    <div class="text_center f20">重设密码</div>
    <form action="">
      <div class="name">
        <input v-model="form.name" placeholder="请输入账号"/>
      </div>
      <div class="pwd">
        <input type="password" v-model="form.pwd" placeholder="请输入密码"/>
      </div>
      <div class="pwd">
        <input type="password" v-model="form.pwd2" placeholder="请输入密码"/>
      </div>
      <div class="text_center">
        <div><i class="icon-xiangyou1"></i></div>
      </div>
    </form>
  </div>
</template>

<script>
import { ref } from 'vue';
import { useRoute, useRouter } from 'vue-router'

export default {
  name:'ResetPwd',
  setup(){
    const route = useRoute()
    const router = useRouter()
    console.log(route.params.id)

    let form = ref({
      name: '',
      pwd: '',
      pwd2: ''
    })
    const isShow = ref(false)
    function toSubmit(){
      if(form.value.name === ''){
        alert('请输入登录名')
      } else if(form.value.pwd === ''){
        alert('请输入正确的密码')
      } else if(form.value.name === 'admin' && form.value.pwd === '123456'){
        router.push('/change')
      } else{
        alert('登录名或密码错误，请重新输入')
        form.value.name = ''
        form.value.pwd = ''
      }
    }

    
    // let login = () => {
    //     // 登录事件
    //   }
    //   document.onkeydown = e => {
    //     login()
    //   }
    return{
      isShow, form,
      toSubmit,
    }
  }
}
</script>

<style scoped>
.bg{
  background:url(@/assets/img/login/login_bg.png) no-repeat left top/100% 100%;
  padding:6.5vw 3.33vw 10vw;
  min-height: 100vh;
  box-sizing: border-box;
}
.flex-b-c{
  margin-bottom:70px;
}
.right{
  padding:9px 15px;
  background:rgba(255,255,255,.5);
  border-radius:20px;
  font-size:14px;
}
.right::before{
  content:'';
  background:url(@/assets/img/login/login.png) no-repeat left top/100%;
  width: 28px;
  height: 23px;
  display: inline-block;
  margin-right:8px;
  vertical-align:middle;
}
.text_center img{
  width:140px;
  height:85px;
}
form{
  margin-bottom:130px;
}
form>div{
  margin-top:40px;
  padding:0 36px;
  position:relative;
}
.name::before{
  content:'';
  position:absolute;
  top:0;
  background: url(@/assets/img/login/login.png) no-repeat left top/200%;
  background-position: 0 -35px;
  width:24px;
  height:28px;
}
.pwd::before{
  content:'';
  position:absolute;
  top:0;
  background: url(@/assets/img/login/login.png) no-repeat left top/200%;
  background-position: 0 -64px;
  width:24px;
  height:24px;
}
input{
  width:100%;
  border:none;
  border-bottom:2px solid #EBEDF5;
  padding-left:30px;
  height:36px;
  line-height:36px;
  font-size:14px;
  background:transparent;
  box-sizing:border-box;
}
form>div.text_center{ 
  margin-top:66px; 
}
.text_center i{
  font-size:28px;
  background: linear-gradient(55deg, #8E05FF 0%, #0F52FF 100%);
  padding:24px 24px;
  border:5px solid #fff;
  border-radius:50%;
  color:#fff;
}
.mt-30{
  margin-top:35px;
}
.pab{
  color: var(--color-text-blue);
  font-size:14px;
  right:36px;
  bottom: 11px;
  line-height:1em;
  padding-left:15px;
  border-left:1px solid #EBEDF5;
}
</style>