import { createRouter, createWebHistory } from 'vue-router'
import { Login, 
  // Register, 
  GetPwd, ResetPwd } from '@/views/login/index.js'
import Change from '@/views/change/Change'
import Add from '@/views/change/Add'
import Index from '@/views/Index'
import Home from '@/views/home/Home'
import {
  BaseInfo,
  Department,
  DepartmentAdd,
  DepartmentAddStaff,
  DepartmentEditStaff,
  ExternalList,
  ExternalPickedList,
  Address,
  Flow,
  Information,
  Qualification, 
  EquipmentSchedule, 
  Customer, 
  CustomerDetail, 
  Supplier, 
  SupplierDetail, 
  ViewProject, 

  InformationS,
  ExternalList1,
  demo,
} from '@/views/home/childComps/index.js'
import Message from '@/views/message/Message'
import Contact from '@/views/contact/Contact'
import User from '@/views/user/User'
import Api from '@/store/commonApi'
// import MyListing from '@/views/listing/MyListing'

const routerHistory = createWebHistory()

const routes = [
  {
    path:'/',
    redirect:'/home'
  },
  {
    path:'/login',
    component: Login,
    meta:{
      title:'咨询助手-登录',
      isLogin:false
    }
  },
  {
    path:'/login/getpwd',
    component: GetPwd,
    meta:{
      title:'咨询助手-找回密码',
      isLogin:false
    }
  },
  {
    path:'/login/resetpwd',
    component: ResetPwd,
    meta:{
     title:'咨询助手-重置密码',
     isLogin:false
    }
  },
  // {
  //   path:'/register',
  //   component: Register,
  //   meta:{
  //     title:'咨询助手-注册',
  //     isLogin:false
  //   }
  // },
  {
    path:'/change',
    component: Change
  },
  {
    path:'/change/add',
    component: Add
  },
  {
    path:'/index',
    component: Index,
    children:[
      {
        path:'',
        redirect:'/home/'
      },
      {
        path:'/home/',
        component: Home,
        meta:{
          title:'首页'
        }
      },
      {
        path:'/message',
        component: Message
      },
      {
        path:'/contact',
        component:Contact
      },
      {
        path:'/user',
        component:User
      }
    ]
  },
  {
    path:'/home/baseInfo',
    name:'基本信息',
    component:BaseInfo
  },
  {
    path:'/home/department',
    name:'部门人员',
    component:Department
  },
  {
    path:'/home/department/departmentAdd',
    name:'部门信息',
    component:DepartmentAdd,
  },
  {
    path:'/home/department/departmentEditStaff',
    name:'管理员工',
    component:DepartmentEditStaff
  },
  {
    path:'/home/department/addStaff',
    name:'员工信息',
    component:DepartmentAddStaff
  },
  {
    path:'/home/externalList',
    name:'执行标准',
    component: ExternalList
  },
  {
    path:'/home/address',
    name:'企业地址',
    component:Address
  },
  {
    path:'/home/flow',
    name:'流程管理',
    component:Flow
  },
  {
    path:'/home/qualification',
    name:'相关资质',
    component:Qualification
  },
  {
    path:'/home/equipmentSchedule',
    name:'设备清单',
    component:EquipmentSchedule
  },
  {
    path:'/home/customer',
    name:'顾客管理',
    component:Customer
  },
  {
    path:'/home/customer/detail',
    name:'顾客信息',
    component:CustomerDetail
  },
  {
    path:'/home/supplier',
    name:'供方管理',
    component:Supplier
  },
  {
    path:'/home/supplier/detail',
    name:'供方信息',
    component:SupplierDetail
  },
  {
    path:'/home/information/:template_id?',
    name:'企业信息',
    component:Information
  },
  {
    path: '/home/viewProject',
    name: '文件预览',
    component: ViewProject
  }, 
  {
    path:'/home/information-s',
    name:'原企业信息',
    component:InformationS
  },
  {
    path:'/home/externalList1',
    name:'手册编写',
    component: ExternalList1
  },
  {
    path:'/home/demo',
    component:demo,
  },
  {
    path:'/home/externalList/externalPickedList',
    component:ExternalPickedList
  },
]

const router = createRouter({
  history: routerHistory,
  mode: "hash",
  base: process.env.BASE_URL,
  routes
})
// 判断登录
router.beforeEach(function(to,from,next){
  document.title = to.meta.title?to.meta.title:'咨询助手'
  var uid = Api.checkLogin();
  var project_id = Api.sessionGet('project_id')
  if(to.meta.isLogin!==false){
    //判断登录
    if(uid>0){
      if(project_id>0){
        next()
      }else{
        if(to.path!='/change'){
          if(to.path=='/change/add'){
            next()
          }else{
            next('/change');
          }
        }else{
          next()
        }
      }
    }else{
      next('/login')
    }
  }else{
    if(to.path=='/login' && uid>0){
      next('/home');
    }else{
      next();
    }
  }
});
export default router
